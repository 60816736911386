import React from 'react';
import faqImg from '../../assets/images/faq.png';
import Faq from '../../components/faq/Faq';
import SubpageBanner from '../../components/subpage-banner/SubpageBanner';
import useFetch from '../../hooks/useFetch';

const FaqCarbarn = () => {

    const { data } = useFetch("https://script.google.com/macros/s/AKfycbxQOdiy0DPw51py3RhEaGjjZlLMcv8_FPDoqKJMaKoAeKP4KY5v3nc_Z3suN4LY0J-8/exec");

    const generalFaq = data.filter((item) => item.page === "FAQ");

    return (
        <div>
            <SubpageBanner title="Faq" subTitle="General Questions" bgImg={faqImg} />
            <Faq faqTitle="General <b>FAQ</b>" faqData={generalFaq} />
        </div>
    );
};

export default FaqCarbarn;