import React from 'react';
import FeaturedSlider from '../featuredSlider/FeaturedSlider';

const DeselCar = ({ data, loading }) => {

    const dieselCar = data?.filter((item) => item?.fuel?.toLowerCase() === "diesel");

    return (
        <div>
            {loading && <h5 className="fs-18 mt-50 text-center">Loading</h5>}

            {dieselCar?.length > 0 && !loading && <FeaturedSlider cars={dieselCar} featuredClass="car-slider" />}

            {dieselCar?.length === 0 && !loading && <h5 className="fs-18 mt-50 text-center">No Data Avilable</h5>}
        </div>
    );
};

export default DeselCar;