import React from "react";
import BodyExtoriorIcon from "../../assets/images/body-exterior-icon.svg";
import EngineIcon from "../../assets/images/engine-transmission-icon.svg";
import InteriorIcon from "../../assets/images/interior-icon.svg";
import ElectronicsIcon from "../../assets/images/electronic-icon.svg";
import SteeringIcon from "../../assets/images/steering-icon.svg";
import RightIcon from "../../assets/images/right-tick-icon.svg";
import { Tabs } from "antd";
import InspectionContent from "../inspectionContent/InspectionContent";

const InspectionReportTab = ({singleDetails, uniqueInspectionGroups, bodyExterior, engineAndTransmission, interior, Electronics, steeringAndSuspension}) => {


  const data = [
    {
      icon: BodyExtoriorIcon,
      title: "Body Exterior",
      // desc: "Online Inspection ",
      tabContent: <InspectionContent repairedItems={bodyExterior} singleDetails={singleDetails}/>,
    },
    {
      icon: EngineIcon,
      title: "Engine and <br/> Transmission",
      // desc: "Buy and Self",
      tabContent: <InspectionContent repairedItems={engineAndTransmission} singleDetails={singleDetails}/>,
    },

    {
      icon: InteriorIcon,
      title: "Interior",
      // desc: "Apply to buy with Finance",
      tabContent: <InspectionContent repairedItems={interior} singleDetails={singleDetails}/>,
    },

    {
      icon: ElectronicsIcon,
      title: "Electronics",
      // desc: "Vehicle condition Report",
      tabContent: <InspectionContent repairedItems={Electronics} singleDetails={singleDetails}/>,
    },

    {
      icon: SteeringIcon,
      title: "Steering, <br/> Suspension and <br/> Brakes",
      // desc: "Vehicle condition Report",
      tabContent: <InspectionContent repairedItems={steeringAndSuspension} singleDetails={singleDetails}/>,
    },
  ];

  return (
    <div>
      <Tabs
        defaultActiveKey="1"
        tabPosition="left"
        className="inspection-tab"
        items={data.map((item, i) => {
          const id = String(i + 1);
          return {
            label: (
              <span className="inspection-tab-btn">
                <span className="icon-with-text">
                  <img src={item.icon} alt="icon" className="img-fluid" />
                  <h6 dangerouslySetInnerHTML={{__html: item.title}}></h6>
                </span>
                <span>
                  <img src={RightIcon} alt="icon" className="img-fluid" />
                </span>
              </span>
            ),
            key: id,
            children: <div className="content-wrapper">{item.tabContent}</div>,
          };
        })}
      />
    </div>
  );
};

export default InspectionReportTab;
