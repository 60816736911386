import React from 'react';
import { createContext, useState } from "react";


const WishListContext = createContext();


export const WishListProvider = ({children}) => {

  const [wishlist, setWishlist] = useState([]);

  const [viewItems, setViewItems] = useState([]);

  console.log(viewItems);

  const recentViewedItems = viewItems?.filter((item, index) => {
    return (
      index ===
      viewItems.findIndex((obj) => {
        return obj?.trackId === item?.trackId;
      })
    );
  });


  return (
    <WishListContext.Provider
      value={{
        wishlist,
        setWishlist,
        viewItems,
        setViewItems,
        recentViewedItems,
      }}
    >
      {children}
    </WishListContext.Provider>
  );

}

export default WishListContext;