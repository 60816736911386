import { Col, Row } from "antd";
import React from "react";
import { Link } from "react-router-dom";
import HybridCarImg from "../../assets/images/Hybrid-illustration.svg";
import BenifitsIcon1 from "../../assets/images/accelerationIcon.png";
import BenifitsIcon2 from "../../assets/images/charges.png";
import BenifitsIcon4 from "../../assets/images/recycleIcon.png";
import BenifitsIcon3 from "../../assets/images/switchingIcon.png";
import "./whyHybrid.scss";

const WhyHybrid = () => {

  const featureData = [
      {
          img: BenifitsIcon1,
          title: `Effortless <br /> <span class='text-blue'>acceleration</span>`,
          desc: "Thanks to the instant torque.",
      },

      {
          img: BenifitsIcon2,
          title: `Charges <br/> <span class='text-blue'>while driving</span>`,
          desc: "When decelerating or braking.",
      },

      {
          img: BenifitsIcon3,
          title: "<span class='text-blue'>Intelligent</span> <br /> switching",
          desc: "Reduce CO2, Drive Clean",
      },

      {
          img: BenifitsIcon4,
          title: "<span class='text-blue'>Eco-Friendly </span> <br /> Driving",
          desc: "For a Lower Carbon Footprint",
      },
  ];
  

    return (
        <section className="hybrid-vehical pb-100">
            <div className="container">
                <Row gutter={[50, 30]} align="middle" justify="space-between">
                    <Col xs={{ span: 24 }} lg={{ span: 12 }}>
                        <div className="vehical-image">
                            <img src={HybridCarImg} className="img-fluid" alt="img" />
                        </div>
                    </Col>
                    <Col xs={{ span: 24 }} lg={{ span: 12 }}>
                        <div className="vehical-content">
                            <p className="vehical-hints fs-12 fw-800">Why choose Hybrid</p>
                            <div>
                                <h1 className="text-dark1 fs-48">
                                    Why Choose a<span className="fw-800"> Hybrid</span> Vehicle?
                                </h1>
                                <p className="my-20 text-dark3 fs-18 lh-32">
                                    Hybrid vehicles combine the power of a petrol engine with the efficiency of an electric motor, resulting in impressive performance and fuel efficiency. Enjoy a
                                    quiet, efficient ride in the city and get the power you need on the highway, all while reducing your carbon footprint. If you're looking for a vehicle that can
                                    deliver both power and efficiency, look no further than a hybrid
                                </p>
                            </div>
                            <div className="common-fill-btn">
                                <Link to="/stock" className="fs-16 bg-clr-blue fw-bold text-white rounded fill-btn">
                                    Browse all cars under $20K
                                </Link>
                            </div>
                        </div>
                    </Col>
                </Row>

                <div className="mt-80">
                    <Row gutter={[40, 30]} justify="space-between">
                        {featureData.map((feature, index) => {
                            return (
                                <Col xs={{ span: 24 }} md={{ span: 12 }} xl={{ span: 6 }} key={index}>
                                    <div className="why-carbarn-item h-100">
                                        <div className="img-box">
                                            <img src={feature.img} className="service img-fluid" alt="icon" />
                                        </div>
                                        <div>
                                            <h4 className=" text-dark1 fs-24 fw-semi-bold mb-10" dangerouslySetInnerHTML={{ __html: feature.title }}></h4>
                                            <p className="fs-14 text-dark3">{feature.desc}</p>
                                        </div>
                                    </div>
                                </Col>
                            );
                        })}
                    </Row>
                </div>
            </div>
        </section>
    );
};

export default WhyHybrid;
