import React from 'react';
import { Link, useNavigate } from 'react-router-dom';
import useFilter from '../hooks/useFilter';

const CustomNavLink = ({to, children}) => {

    const {clearFilter} = useFilter();

    const navigate = useNavigate();

    const handleLinkClick = () => {
        clearFilter(); // Clear the filter state
        window.scrollTo(0, 0);
        navigate(to);
      };

     return <Link to={to} className="nav-link fw-bold lh-24" onClick={handleLinkClick}>{children}</Link>;
};

export default CustomNavLink;