import { Button, Col, Radio, Row } from "antd";
import React, { useState } from "react";
import chooseCar from "../../assets/images/choose-car.png";
import card1 from "../../assets/images/pay1.png";
import card2 from "../../assets/images/pay2.png";
import card3 from "../../assets/images/pay3.png";
import "./CheckoutPayment.scss";

const CheckoutPayment = () => {
  const [payment, setPayment] = useState(1);

  const onChange = (e) => {
    setPayment(e.target.value);
    console.log(payment);
  };
  return (
    <div className="pt-50 pb-100 checkout">
      <div className="container">
        <div className="checkout-wrapper">
          <Row gutter={[36, 16]}>
            <Col xs={{ span: 24 }} lg={{ span: 12 }}>
              <div>
                <div className="">
                  <p className="fs-24 fw-medium">
                    <b className="fw-800">Welcome Shariful! </b>Please choose
                    your payment option for the above vehicle
                  </p>

                  <div>
                    {payment === 1 && (
                      <h5 className="fs-24 fw-800 mt-30">Pay with</h5>
                    )}

                    <div className="mb-20 ">
                      <form action="" className="card-form">
                        {payment === 1 && (
                          <Radio.Group
                            name="radiogroup"
                            className="pay-item-wrapper"
                            defaultValue={1}
                          >
                            <Radio className="pay-wrapper" value={1}>
                              {" "}
                              <div className="card-image">
                                <img
                                  src={card1}
                                  className="img-fluid"
                                  alt="card"
                                />
                              </div>
                            </Radio>
                            <Radio className="pay-wrapper" value={2}>
                              {" "}
                              <div className="card-image">
                                <img
                                  src={card2}
                                  className="img-fluid"
                                  alt="card"
                                />
                              </div>
                            </Radio>
                            <Radio className="pay-wrapper" value={3}>
                              {" "}
                              <div className="card-image">
                                <img
                                  src={card3}
                                  className="img-fluid"
                                  alt="card"
                                />
                              </div>
                            </Radio>
                          </Radio.Group>
                        )}
                        <div></div>
                        <div className="payment-info mt-40">
                          <Radio.Group onChange={onChange} defaultValue={1}>
                            <Radio value={1}>
                              {" "}
                              <p className="mb-0 fs-18 fw-medium text-dark3">
                                10% Payment to hold the vehicle for 72 business
                                hours <span className="fw-bold">$1,950</span>
                              </p>
                            </Radio>
                            <Radio value={2}>
                              <p className="mb-0 fs-18 fw-medium text-dark3">
                                Full Payment{" "}
                                <span className="fw-bold">$19,500 </span>(online
                                payment only)
                              </p>
                            </Radio>
                          </Radio.Group>
                        </div>
                        <div className="payment-btn-wrapper mt-30">
                          <Button type="button" className="change">
                            Change
                          </Button>
                          <Button type="button" className="continue">
                            Continue
                          </Button>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </Col>
            <Col xs={{ span: 24 }} lg={{ span: 12 }}>
              <div className="choosing-car">
                <div className="choosing-car-image">
                  <img src={chooseCar} className="img-fluid" alt="" /> 
                </div>
                <div className="choose-car-content mt-30">
                  <h3 className="fs-24 fw-800 text-dark1 mb-15">
                    1997 Toyota hiace diesel 4WD
                  </h3>
                  <ul>
                    <li>
                      <div className="circle"></div>
                      <span className="fs-16 fw-medium text-dark3">
                        179,129 KM
                      </span>
                    </li>
                    <li>
                      <div className="circle"></div>
                      <span className="fs-16 fw-medium text-dark3">
                        Automatic
                      </span>
                    </li>
                    <li>
                      <div className="circle"></div>
                      <span className="fs-16 fw-medium text-dark3">Van</span>
                    </li>
                    <li>
                      <div className="circle"></div>
                      <span className="fs-16 fw-medium text-dark3">
                        3 Years Warranty{" "}
                      </span>
                    </li>
                  </ul>
                  <div className="pricing-content ">
                    <div className="car-price">
                      <p className="fs-18 fw-medium text-dark3">
                        Vehicle Price:
                      </p>
                      <p className="fs-18 fw-800 text-dark3">$19,500</p>
                    </div>
                    <div className="car-price">
                      <p className="fs-18 fw-medium text-dark3">
                        Delivery Cost:
                      </p>
                      <p className="fs-18 fw-800 text-dark3">$0</p>
                    </div>
                  </div>
                  <div className="total-price">
                    <p className="fs-24 fw-800 text-dark1">Total:</p>
                    <p className="fs-24 fw-800 text-dark1">$19,500</p>
                  </div>
                </div>
              </div>
            </Col>
          </Row>
        </div>
      </div>
    </div>
  );
};

export default CheckoutPayment;
