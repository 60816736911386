import React from "react";
import WheelIcon from "../../assets/images/modal-4-wheel-icon-carbarn.svg";
import CarBodyIcon from "../../assets/images/modal-body-icon-carbarn.svg";
import CarDoorIcon from "../../assets/images/modal-car-door-icon-carbarn.svg";
import ColorFilterIcon from "../../assets/images/modal-color-icon-carbarn.svg";
import EngineIcon from "../../assets/images/modal-engine-icon-carbarn.svg";
import MilageIcon from "../../assets/images/modal-milage-icon-carbarn.svg";
import TorqueMeterIcon from "../../assets/images/modal-torque-meter-icon-carbarn.svg";
import "./specificationTable.scss";
import { Col, Row } from "antd";

const SpecificationTable = ({ singleDetails }) => {
  const engineCC =
    (Math.ceil(singleDetails?.engineCapacity / 100) * 100);

  return (
    <>
      {/* <div className="custom-table-wrapper">
        <table
          className="specification-table"
          data-aos="fade-up"
          data-aos-easing="ease-in-sine"
          data-aos-duration="1000"
        >
          <tbody className="desktop-table-body">
            <tr>
              <th>
                <span>
                  <img src={ColorFilterIcon} alt="icon" />
                </span>
                <span>Exterior/Interior Color</span>
              </th>
              <td>
                {singleDetails?.exteriorColor || "NA"}/
                {singleDetails?.interiorColor || "NA"}
              </td>
              <th>
                <span>
                  <img src={CarDoorIcon} alt="icon" />
                </span>
                <span>Roof Type</span>
              </th>
              <td>{singleDetails?.roof_type || "NA"}</td>
            </tr>
            <tr>
              <th>
                <span>
                  <img src={EngineIcon} alt="icon" />
                </span>
                <span>Engine</span>
              </th>
              <td>{singleDetails?.engineCode}</td>
              <th>
                <span>
                  <img src={WheelIcon} alt="icon" />
                </span>
                <span>Drive Type</span>
              </th>
              <td>{singleDetails?.driveTrain || "Front Wheel Drive"}</td>
            </tr>
            <tr>
              <th>
                <span>
                  <img src={MilageIcon} alt="icon" />
                </span>
                <span>Engine CC</span>
              </th>
              <td>{`${engineCC} L`}</td>
              <th>
                <span>
                  <img src={TorqueMeterIcon} alt="icon" />
                </span>
                <span>Engine Torque</span>
              </th>
              <td>
                {singleDetails?.engine_torque
                  ? singleDetails?.engine_torque
                  : "NA"}
              </td>
            </tr>
            <tr>
              <th>
                <span>
                  <img src={CarBodyIcon} alt="icon" />
                </span>
                <span>Type</span>
              </th>
              <td>
                {singleDetails?.bodyType
                  ? singleDetails?.bodyType
                  : "Data Not Available"}
              </td>
            </tr>
          </tbody>
          <tbody className="mobile-table-body">
            <tr>
              <th>
                <span>
                  <img src={ColorFilterIcon} alt="icon" />
                </span>
                <span>Exterior/Interior Color</span>
              </th>
              <td>
                {singleDetails?.exteriorColor || "NA"}/
                {singleDetails?.interiorColor || "NA"}
              </td>
            </tr>
            <tr>
              <th>
                <span>
                  <img src={CarDoorIcon} alt="icon" />
                </span>
                <span>Roof Type</span>
              </th>
              <td>{singleDetails?.roof_type || "NA"}</td>
            </tr>
            <tr>
              <th>
                <span>
                  <img src={EngineIcon} alt="icon" />
                </span>
                <span>Engine</span>
              </th>
              <td>{singleDetails?.engine || "NA"}</td>
            </tr>
            <tr>
              <th>
                <span>
                  <img src={WheelIcon} alt="icon" />
                </span>
                <span>Drive Type</span>
              </th>
              <td>{singleDetails?.driveTrain || "NA"}</td>
            </tr>
            <tr>
              <th>
                <span>
                  <img src={MilageIcon} alt="icon" />
                </span>
                <span>Engine CC</span>
              </th>
              <td>{`${engineCC} L` || "NA"}</td>
            </tr>
            <tr>
              <th>
                <span>
                  <img src={TorqueMeterIcon} alt="icon" />
                </span>
                <span>Engine Torque</span>
              </th>
              <td>{singleDetails?.engine_torque || "NA"}</td>
            </tr>
            <tr>
              <th>
                <span>
                  <img src={CarBodyIcon} alt="icon" />
                </span>
                <span>Type</span>
              </th>
              <td>{singleDetails?.weel_base_type || "NA"}</td>
            </tr>
          </tbody>
        </table>
      </div> */}

      <Row className="veichle-specification">
        {singleDetails?.exteriorColor && (
          <Col xs={24} lg={12} className="specification-item-wrapper">
            <div className="specification-item">
              <div className="icon-wrapper">
                <span className="img-wrapper">
                  <img src={ColorFilterIcon} alt="icon" />
                </span>
                <span>Exterior</span>
              </div>
              <p>{singleDetails?.exteriorColor}</p>
            </div>
          </Col>
        )}
        {singleDetails?.interiorColor && (
          <Col xs={24} lg={12} className="specification-item-wrapper">
            <div className="specification-item">
              <div className="icon-wrapper">
                <span className="img-wrapper">
                  <img src={ColorFilterIcon} alt="icon" />
                </span>
                <span>Interior Color</span>
              </div>
              <p>{singleDetails?.interiorColor}</p>
            </div>
          </Col>
        )}
        {singleDetails?.roof_type && (
          <Col xs={24} lg={12} className="specification-item-wrapper">
            <div className="specification-item">
              <div className="icon-wrapper">
                <span className="img-wrapper">
                  <img src={CarDoorIcon} alt="icon" />
                </span>
                <span>Roof Type</span>
              </div>
              <p>{singleDetails?.roof_type}</p>
            </div>
          </Col>
        )}
        {singleDetails?.engineCode && (
          <Col xs={24} lg={12} className="specification-item-wrapper">
            <div className="specification-item">
              <div className="icon-wrapper">
                <span className="img-wrapper">
                  <img src={EngineIcon} alt="icon" />
                </span>
                <span>Engine</span>
              </div>
              <p>{singleDetails?.engineCode}</p>
            </div>
          </Col>
        )}
        {singleDetails?.driveTrain && (
          <Col xs={24} lg={12} className="specification-item-wrapper">
            <div className="specification-item">
              <div className="icon-wrapper">
                <span className="img-wrapper">
                  <img src={WheelIcon} alt="icon" />
                </span>
                <span>Drive Type</span>
              </div>
              <p>{singleDetails?.driveTrain}</p>
            </div>
          </Col>
        )}
        {engineCC && (
          <Col xs={24} lg={12} className="specification-item-wrapper">
            <div className="specification-item">
              <div className="icon-wrapper">
                <span className="img-wrapper">
                  <img src={MilageIcon} alt="icon" />
                </span>
                <span>Engine CC</span>
              </div>
              <div>
                <p>{`${engineCC} CC`}</p>
              </div>
            </div>
          </Col>
        )}
        {singleDetails?.engine_torque && (
          <Col xs={24} lg={12} className="specification-item-wrapper">
            <div className="specification-item">
              <div className="icon-wrapper">
                <span className="img-wrapper">
                  <img src={TorqueMeterIcon} alt="icon" />
                </span>
                <span>Engine Torque</span>
              </div>
              <p>{singleDetails?.engine_torque}</p>
            </div>
          </Col>
        )}
        {singleDetails?.bodyType && (
          <Col xs={24} lg={12} className="specification-item-wrapper">
            <div className="specification-item">
              <div className="icon-wrapper">
                <span className="img-wrapper">
                  <img src={CarBodyIcon} alt="icon" />
                </span>
                <span>Type</span>
              </div>
              <p>{singleDetails?.bodyType}</p>
            </div>
          </Col>
        )}
      </Row>
    </>
  );
};

export default SpecificationTable;
