import { Modal, Steps } from "antd";
import axios from "axios";
import { useState } from "react";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import useAuth from "../../hooks/useAuth";
import EmploymentDetails from "../employmentDetails/EmploymentDetails";
import LoanInformation from "../loanInformation/LoanInformation";
import PersonalInformation from "../personalInformation/PersonalInformation";
import "./financeApplicationModal.scss";

const FinanceApplicationModal = () => {
  const { customer } = useAuth();

  const [current, setCurrent] = useState(0);
  const [commercialUse, setCommercialUse] = useState(false);

  const [showFinanceApplication, setShowFinanceApplication] = useState(false);

  const [applicationInfo, setApplicationInfo] = useState({
    deposit: "",
    businessNature: "",
    businessInitiationMonth: "",
    businessInitiationYear: "",
    businessABNRegisteredMonth: "",
    businessABNRegisteredYear: "",
    gst: false,
    gstMonth: "",
    gstYear: "",
    duration: "",
    businessType: "",
    employment: "",
    startJob: false,
    dob: "",
    dependents: "",
    residentalStatus: "",
    maritalStatus: "",
    residencyStatus: "",
    address: "",
    isMarried: false,
    isTempVisa: false,
    partnersIncome: "",
    incomeFrequency: "",
    visaClassNumber: "",
    financeStatus: "Pending",
    ownIncome: "",
    ownIncomeFrequency: "",
    ownJobStartMonth: "",
    ownJobStartYear: "",
    housingOrRentAmount: "",
    housingOrRentFrequency: "",
  });

  const handleChange = (e) => {
    const name = e.target.name;
    const value = e.target.value;

    setApplicationInfo((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleOk = () => {
    setShowFinanceApplication(false);
  };
  const handleCancel = () => {
    setShowFinanceApplication(false);
  };

  const open = () => {
    setShowFinanceApplication(true);
  };

  const next = () => {
    setCurrent(current + 1);
  };

  const prev = () => {
    setCurrent(current - 1);
  };

  const handleSubmit = async () => {
    const [businessYearId, businessYear] =
      applicationInfo?.businessInitiationYear?.split("-");

    const [abnRegYearId, abnRegYear] =
      applicationInfo?.businessABNRegisteredYear?.split("-");

    const [gstYearId, gstYear] = applicationInfo?.gstYear?.split("-");

    const [jobYearId, jobYear] = applicationInfo?.ownJobStartYear?.split("-");

    const financeInfo = {
      financeDataList: [
        {
          chassisId: "",
          amountOfMoneyToDeposit: applicationInfo?.deposit,
          desiredLoanDurationInYear: applicationInfo?.duration,
          numberOfDependents: applicationInfo?.dependents,
          presentAddress: applicationInfo?.address,
          user: {
            username: customer?.username || "",
            email: customer?.email || "",
            password: "1234",
            dateOfBirth: applicationInfo?.dob,
          },
          citizenshipStatus: {
            id: applicationInfo?.residencyStatus,
            status: "Updated",
          },
          employmentInfo: {
            id: applicationInfo?.employment || 1,
            employmentSituation: "Updated",
          },
          financeCompany: {
            id: "1",
            financeName: "Carbarn",
            companyUrl: "www.carbarn.com",
          },
          maritalStatus: {
            id: applicationInfo?.maritalStatus,
            name: "Updated",
          },
          residentialStatus: {
            id: applicationInfo?.residentalStatus,
            status: "Updated",
          },
          vehicleUsagePlan: {
            id: commercialUse ? 3 : 4,
            usagePlan: "Updated",
          },
          businessNature: {
            id: applicationInfo?.businessNature || 4,
            nature: "Company",
          },
          businessInitiationMonth: applicationInfo?.businessInitiationMonth,
          businessInitiationYear: {
            id: businessYearId,
            year: businessYear,
          },
          businessABNRegisteredMonth: applicationInfo?.businessInitiationMonth,
          businessABNRegisteredYear: {
            id: abnRegYearId,
            year: abnRegYear,
          },
          isGSTRegistered: applicationInfo?.gst,
          gstMonth: applicationInfo?.gstMonth,
          gstYear: {
            id: gstYearId,
            year: gstYear,
          },
          partnersIncome: applicationInfo?.partnersIncome,
          incomeFrequency: applicationInfo?.incomeFrequency,
          visaClassNumber: applicationInfo?.visaClassNumber,
          financeStatus: {
            id: 1,
            status: applicationInfo?.financeStatus,
          },
          ownIncome: applicationInfo?.ownIncome,
          ownIncomeFrequency: applicationInfo?.ownIncomeFrequency,
          ownJobStartMonth: applicationInfo?.ownJobStartMonth,
          ownJobStartYear: {
            id: jobYearId,
            year: jobYear,
          },
          housingOrRentAmount: applicationInfo?.housingOrRentAmount,
          housingOrRentFrequency: applicationInfo?.housingOrRentFrequency,
        },
      ],
    };

    try {
      await axios.post(
        `https://www.cbs.s1.importautos.com.au/finance/v1/api/create`,
        financeInfo
      );

      toast.success("Apply form successfully");
      setShowFinanceApplication(false);
      setCurrent(0);
      setApplicationInfo({});
    } catch (err) {
      toast.error("There was something wrong !");
    }
  };

  console.log(applicationInfo);

  const steps1 = [
    {
      key: 1,
      title: "Loan Information",
      content: (
        <LoanInformation
          commercialUse={commercialUse}
          setCommercialUse={setCommercialUse}
          setApplicationInfo={setApplicationInfo}
          applicationInfo={applicationInfo}
          handleChange={handleChange}
        />
      ),
    },
    {
      key: 2,
      title: "Employment Details",
      content: (
        <EmploymentDetails
          setApplicationInfo={setApplicationInfo}
          applicationInfo={applicationInfo}
          handleChange={handleChange}
        />
      ),
    },
    {
      key: 3,
      title: "Personal information",
      content: (
        <PersonalInformation
          setApplicationInfo={setApplicationInfo}
          applicationInfo={applicationInfo}
          handleChange={handleChange}
        />
      ),
    },
  ];

  const steps2 = [
    {
      key: 4,
      title: "Loan Information",
      content: (
        <LoanInformation
          commercialUse={commercialUse}
          setCommercialUse={setCommercialUse}
          setApplicationInfo={setApplicationInfo}
          applicationInfo={applicationInfo}
          handleChange={handleChange}
        />
      ),
    },
    {
      key: 5,
      title: "Personal information",
      content: (
        <PersonalInformation
          setApplicationInfo={setApplicationInfo}
          applicationInfo={applicationInfo}
          handleChange={handleChange}
        />
      ),
    },
  ];

  const steps = commercialUse ? steps2 : steps1;

  const items = steps.map((item) => ({
    key: item.key,
    title: item.title,
  }));

  return (
    <>
      {customer?.email ? (
        <Link
          onClick={open}
          className="banner-btn px-40 fs-16 text-white w-100"
        >
          <span>Get pre-approval</span>
        </Link>
      ) : (
        <Link to="/login" className="banner-btn px-40 fs-16 text-white w-100">
          <span>Get pre-approval</span>
        </Link>
      )}
      <Modal
        className="finance-modal"
        open={showFinanceApplication}
        onOk={handleOk}
        onCancel={handleCancel}
        width={820}
        footer={false}
      >
        <Steps
          className="finance-step"
          current={current}
          items={items}
          labelPlacement="vertical"
        />

        <div className=" mt-40">{steps[current].content}</div>

        <div className="progress-bar-wrapper">
          <span className={`progress-bar ${current >= 0 && "active"}`}></span>
          <span className={`progress-bar ${current >= 1 && "active"}`}></span>
          {!commercialUse && (
            <span className={`progress-bar ${current >= 2 && "active"}`}></span>
          )}
        </div>

        <div
          className="button-wrapper "
          style={{
            marginTop: 30,
            textAlign: "center",
          }}
        >
          {current > 0 && (
            <button
              className="yes-no-btn"
              style={{
                maxWidth: "180px",
              }}
              onClick={() => prev()}
            >
              Back
            </button>
          )}

          {current < steps.length - 1 && (
            <button
              className="yes-no-btn"
              onClick={() => next()}
              style={{
                maxWidth: "180px",
                background: "#0473ea",
                color: "#fff",
              }}
            >
              Next
            </button>
          )}
          {current === steps.length - 1 && (
            <button
              className="yes-no-btn"
              onClick={handleSubmit}
              style={{
                maxWidth: "180px",
                background: "#0473ea",
                color: "#fff",
              }}
            >
              Submit
            </button>
          )}
        </div>
      </Modal>
    </>
  );
};

export default FinanceApplicationModal;
