import React from 'react';
import OffersBannerBg from "../../assets/images/offersBannerBg.jpg";

const OffersBanner = () => {
    return (
        <div className="pt-100 pb-100" style={{ backgroundImage: `url(${OffersBannerBg})`, backgroundSize: "cover" }}>
            <div className="container">
                <div className="offerInfo px-40 text-center">
                    <h2 className="bannerCaption fs-48 text-white lh-58 fw-normal mb-20">
                        CARBARN offers <b>multiple payment options</b> , including online bank transfer and credit card on site. Additionally, you have the option to hold your selected vehicle by
                        paying a 15% deposit for 72 business hours.
                    </h2>
                </div>
            </div>
        </div>
    );
};

export default OffersBanner;