import React from "react";
import { Link } from "react-router-dom";
import useFilter from "../../hooks/useFilter";
import "./callToAction.scss";

const CallToAction = () => {


  const { newData } = useFilter();

    return (
      <section className="call-to-action-area">
        <div className="container text-center">
          <div className="text-center">
            <h1 className="text-dark1 fs-48">
              A better way to
              <span className="fw-800"> buy a pre-loved </span>{" "}
              <br className="d-none" /> car entirely online
            </h1>
          </div>
          <div className="common-fill-btn mt-50">
            <Link
              to="/stock"
              className="fs-16 bg-clr-blue fw-bold text-white rounded fill-btn m-fill-btn"
            >
              Browse {newData?.length} Cars
            </Link>
          </div>
        </div>
      </section>
    );
};

export default CallToAction;
