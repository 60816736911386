import React, { useEffect, useRef } from 'react';
import Filter from '../filter/Filter';
import './filterSidebar.scss';

const FilterSidebar = ({ filterOpen, setFilterOpen }) => {
    const filterRef = useRef(null);

    const handleClickOutside = (event) => {
        if (filterRef.current && !filterRef.current.contains(event.target)) {
            setFilterOpen(false);
        }
    };

    useEffect(() => {
        document.addEventListener("mousedown", handleClickOutside);

        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, []);

    return (
        <div className={`filter-m-sidebar ${filterOpen && "active"}`} ref={filterRef}>
            <Filter />
        </div>
    );
};

export default FilterSidebar;