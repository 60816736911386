import { Col, Row } from "antd";
import React from "react";
import "./workProcess.scss";
import ProcessImg1 from "../../assets/images/process-img1.png";
import ProcessImg2 from "../../assets/images/process-img2.png";
import ProcessImg3 from "../../assets/images/process-img3.png";

const WorkProcess = () => {
  return (
    <section className="workProcess pt-100">
      <div className="container">
        <Row
          align="middle"
          gutter={[24, 24]}
          className="item-center d-xs-block pb-100"
        >
          <Col lg={{ span: 12 }}>
            <div className="processImg  text-lg-start text-xs-center">
              <img src={ProcessImg1} alt="ProcessImg" className="img-fluid" />
            </div>
          </Col>
          <Col lg={{ span: 12 }}>
            <div className="processInfo  text-lg-start text-xs-center">
              <div className="numberBox">
                <span className="number">01</span>
              </div>
              <h2 className="processCaption fs-48 text-dark1 lh-58 fw-normal mb-20">
                Browse through <b>our stock</b> list to find your favorite
                vehicle
              </h2>
              <p className="fs-16 fw-medium text-dark3 lh-24">
                Discover our top-notch imported Japanese vehicles
              </p>
            </div>
          </Col>
        </Row>
        <Row
          align="middle"
          gutter={[24, 24]}
          className="item-center flex-row-reverse pb-100"
        >
          <Col className="" lg={{ span: 12 }}>
            <div className="processImg  text-lg-start text-xs-center">
              <img src={ProcessImg2} alt="ProcessImg" className="img-fluid" />
            </div>
          </Col>
          <Col className="" lg={{ span: 12 }}>
            <div className="processInfo  text-lg-start text-xs-center">
              <div className="numberBox">
                <span className="number">02</span>
              </div>
              <h2 className="processCaption fs-48 text-dark1 lh-58 fw-normal mb-20">
                Choose your preferred way to experience your next vehicle with{" "}
                <b>Virtual Inspection or On-Site Test Drive</b>
              </h2>
              <p className="fs-16 fw-medium text-dark3 lh-24">
                Experience a worry-free purchase with our free vehicle condition
                reports from top-rated companies such as Redbook and MTA
              </p>
            </div>
          </Col>
        </Row>
        <Row align="middle" gutter={[24, 24]} className="item-center pb-100">
          <Col className="" lg={{ span: 12 }}>
            <div className="processImg  text-lg-start text-xs-center">
              <img src={ProcessImg3} alt="ProcessImg" className="img-fluid" />
            </div>
          </Col>
          <Col className="" lg={{ span: 12 }}>
            <div className="processInfo  text-lg-start text-xs-center">
              <div className="numberBox">
                <span className="number">03</span>
              </div>
              <h2 className="processCaption fs-48 text-dark1 lh-58 fw-normal mb-20">
                <b>Pick up</b> your vehicle at our location in{" "}
                <b>Lidcombe 2141 NSW</b> or enjoy our nationwide{" "}
                <b>doorstep delivery</b>
              </h2>
              <p className="fs-16 fw-medium text-dark3 lh-24">
                Get your next vehicle the way you want it with our options of
                either picking up at our location or having it delivered right
                to your door.
              </p>
            </div>
          </Col>
        </Row>
      </div>
    </section>
  );
};

export default WorkProcess;
