import { Col, Row } from "antd";
import React from "react";
import "./featureItem.scss";

const FeatureItem = ({ singleDetails }) => {

  const singleDetailsFeature = "CD player|Alloy wheels|Power steering|ABS|Power window|A/C|Airbag|Radio|Power seat|Back tire|Rear spoiler|Central locking|Jack|Spare tire|Wheel spanner|Fog lights|Back camera|Push start|Keyless entry|Body kit|Side airbag|Power mirror|Navigation|Turbo";
  
  const features = singleDetailsFeature?.split("|");


  return (
    <div
      className="feature-item-wrapper"
    >
      <Row align="middle" gutter={[13, 13]} className="feature-item-box">
        {singleDetails?.feature?.map((feature, index) => {
          return (
            <Col xs={{ span: 24 }} md={{ span: 6 }} key={index}>
              <div
                className="featured-item"
              >
                <p>{feature}</p>
              </div>
            </Col>
          );
        })}
      </Row>
    </div>
  );
};

export default FeatureItem;
