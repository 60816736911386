import React from "react";
import FeaturedSlider from "../featuredSlider/FeaturedSlider";

const Car = ({ data, loading }) => {
  const car4wd = data?.filter(
    (item) => item?.driveTrain?.toLowerCase() === "4wd"
  );

  return (
    <div>
      {loading && <h5 className="fs-18 mt-50 text-center">Loading</h5>}

      {car4wd?.length > 0 && !loading && (
        <FeaturedSlider cars={car4wd} featuredClass="car-slider" />
      )}

      {car4wd?.length === 0 && !loading && (
        <h5 className="fs-18 mt-50 text-center">No Data Avilable</h5>
      )}
    </div>
  );
};

export default Car;
