import { CalendarOutlined, DollarCircleOutlined } from "@ant-design/icons";
import { Form, Input, Space, Tabs } from "antd";
import React from "react";
import { Link } from "react-router-dom";
import FinanceApplicationModal from '../financeApplicationModal/FinanceApplicationModal.jsx';

const FinancingCalculator = () => {
  return (
    <>
      <div className="financing-calculator-wrapper">
        <div className="desired-finance-wrapper">
          <Form.Item
            className="input-field"
            rules={[
              {
                required: true,
                message: "Enter desired amount to finance this car",
              },
            ]}
          >
            <Input
              prefix={
                <DollarCircleOutlined
                  style={{ fontSize: "18px", color: "#525355" }}
                />
              }
              placeholder="Enter desired amount to finance this car"
              type="text"
              name="desiredAmount"
              //   value={formData?.firstName}
              //   onChange={handleChange}
            />
          </Form.Item>
          <div className="mt-10">
            <p className="fs-12 text-dark3 mb-4">Set a quick percentage:</p>
            <div className="percentage-wrapper">
              <Link className="text-dark3">25%</Link>
              <Link className="text-dark3">50%</Link>
              <Link className="text-dark3">75%</Link>
            </div>
          </div>
        </div>
        <Tabs
          centered
          className="featured-tab mt-30"
          defaultActiveKey="monthly"
        >
          <Tabs.TabPane tab="Weekly" key="weekly">
            <h1>Weekly Calculation goes here</h1>
          </Tabs.TabPane>
          <Tabs.TabPane tab="Fortnightly" key="fortnightly">
            <h1>Calculation goes here</h1>
          </Tabs.TabPane>
          <Tabs.TabPane tab="Monthly" key="monthly">
            <div className="installment-wrapper mt-10 mb-20">
              <Form.Item
                className="input-box"
                rules={[
                  {
                    required: true,
                    message: "State Installment Count",
                  },
                ]}
              >
                <Input
                  prefix={
                    <CalendarOutlined
                      style={{ fontSize: "18px", color: "#525355" }}
                    />
                  }
                  placeholder="State Installment Count"
                  type="text"
                  name="installmentCount"
                  //   value={formData?.firstName}
                  //   onChange={handleChange}
                />
              </Form.Item>

              <h6>OR</h6>

              <Form.Item
                className="input-box"
                rules={[
                  {
                    required: true,
                    message: "Amount per installement",
                  },
                ]}
              >
                <Input
                  prefix={
                    <DollarCircleOutlined
                      style={{ fontSize: "18px", color: "#525355" }}
                    />
                  }
                  placeholder="Amount per installement"
                  type="text"
                  name="installementAmount"
                  //   value={formData?.firstName}
                  //   onChange={handleChange}
                />
              </Form.Item>
            </div>

            <h6 className="fs-16 text-dark1 text-center mb-10">
              You will pay <strong className="fs-24 fw-800">$1,000</strong>{" "}
              monthly, for 10 months
            </h6>

            <p className="fs-14 text-center" style={{ color: "#0c3a66" }}>
              Remaining price of the car to be made on order:{" "}
              <strong className="fw-800">$9,500</strong>
            </p>

            <Space.Compact align="center" block className="mt-30 mb-30">
              <FinanceApplicationModal />
            </Space.Compact>

            <div className="mt-15 text-center">
              <p className="fs-14 text-dark1">
                To get personalized finance rates, apply for pre-approval now-
                it’s quick and easy
              </p>
              <p className="fs-14 text-dark3">
                No impact on your credit score. Payment will be calculated upon
                official application.
              </p>
            </div>
          </Tabs.TabPane>
        </Tabs>
      </div>

      <div className="financing-calculator-wrapper text-center mt-20">
        <h4 className="fs-24 text-dark1 fw-800">Total Amount</h4>
        <h4 className="fs-24 text-dark1 fw-800">$24,000</h4>
      </div>

      <div className="price-summary cost-summary">
        <div className="price-summary-list">
          <p>Vehicle Base Price</p>
          <h6>$19,500</h6>
        </div>
        <div className="price-summary-list">
          <p>To be Financed :</p>
          <h6>$10,000</h6>
        </div>
        <div className="price-summary-list">
          <p>Remaining:</p>
          <h6>$9,500</h6>
        </div>
        <div className="price-summary-list">
          <p>Interest :</p>
          <h6>$400</h6>
        </div>
        <div className="price-summary-list">
          <p>Delivery Cost</p>
          <h6>$500</h6>
        </div>
        <div className="price-summary-list">
          <p>Payment on Order:</p>
          <h6>$14,000</h6>
        </div>
      </div>
    </>
  );
};

export default FinancingCalculator;
