import { Form, Input, Space } from "antd";
import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import Logo from "../../assets/images/carbarn-logo.svg";
import FeatureIcon1 from "../../assets/images/home-feature-1.svg";
import FeatureIcon2 from "../../assets/images/home-feature-2.svg";
import FeatureIcon3 from "../../assets/images/home-feature-3.svg";
import FeatureIcon4 from "../../assets/images/home-feature-4.svg";
import SignUpStep1 from "../../components/signUpStep/SignUpStep1";
import SignUpStep2 from "../../components/signUpStep/SignUpStep2";
import SignUpStep3 from "../../components/signUpStep/SignUpStep3";
import SignUpStep4 from "../../components/signUpStep/SignUpStep4";
import SignUpStep5 from "../../components/signUpStep/SignUpStep5";
import SignUpStep6 from "../../components/signUpStep/SignUpStep6";
import SuccessStep from "../../components/signUpStep/SuccessStep";
import { phoneNumberMask } from "../../utils/phoneNumberMask";
import { ReactComponent as DateExpireIcon } from "../../assets/images/date-expire-icon.svg";
import { ReactComponent as EmailIcon } from "../../assets/images/email-icon-carbarn.svg";
import { ReactComponent as NoteIcon } from "../../assets/images/note-icon.svg";
import { ReactComponent as PhoneIcon } from "../../assets/images/phone-icon-carbarn.svg";
import { ReactComponent as UserIcon } from "../../assets/images/user-icon-carbarn.svg";
import { ReactComponent as LocationIcon } from "../../assets/images/location.svg";
import { ReactComponent as Key } from "../../assets/images/password-key.svg";
import usePurchase from "../../hooks/usePurchase";
import axios from "axios";
import { toast } from "react-toastify";



const Registration = () => {

  const { formData, setFormData, handleChange } = usePurchase();

  const navigate = useNavigate();

  const [stepNumber, setStepNumber] = useState(1);

  const backBtn = () => {
    let step = stepNumber;
    setStepNumber(step - 1);
  };

  const nextBtn = () => {
    let step = stepNumber;
    setStepNumber(step + 1);
  };

  const handleRegistration = async (e) => {
    e.preventDefault();

    const customerInfo = {
      username: formData?.email,
      email: formData?.email,
      password: formData?.password,
	    dateOfBirth:formData?.dob,
	    firstName: formData?.firstName,
	    lastName: formData?.lastName,
	    mobileNumber: formData?.phoneNo,
	    drivingLicenseNumber:formData?.dlNo,
	    licenseExpiry:formData?.dlExpairDate,
	    address:formData?.address,
    };

    try {
      const result = await axios.post(
        " https://www.cbs.s1.importautos.com.au/carbarn/user/v1/api/customer/create",
        customerInfo
      );

      if (result.data.message === "Error: Username is already taken!") {
        toast.error("Email already exist !");
      } else {
        toast.success("Registration Successfull");
        setFormData({});
        navigate("/login");
      }
    } catch (error) {
      console.error("Error for customer creation:", error);
      toast.error("Password must consist uppercase, lowercase, special character and at least 8 characters.");
    }
  };

  return (
    <section className="appsAccessPanel">
      <div className="accessPanelWrapper">
        <div className="accessPanelLeftInfo">
          <div className="accessInfoAll">
            <div className="accessInfoWrap">
              <div className="logoWrap" style={{ width: "175px" }}>
                <Link to="/">
                  <img src={Logo} alt="logo" className="img-fluid" />
                </Link>
              </div>
              {/* <div className="img mb-30">
                                <img src={LoginImg} alt="logo" className="img-fluid" />
                            </div> */}
              <div>
                <h3 className="fs-48 text-dark1 fw-normal mb-50">
                  Carbarn <b>benefit</b>
                </h3>
                <ul className="feature-point-wrapper">
                  <li className="feature-point mb-20">
                    <img
                      src={FeatureIcon1}
                      className="img-fluid mt-1"
                      alt="icon"
                    />
                    <p className="fs-24 fw-normal text-dark1 lh-24">
                      5-Years <b>Warranty</b> with road side assistance
                    </p>
                  </li>
                  <li className="feature-point mb-20">
                    <img
                      src={FeatureIcon2}
                      className="img-fluid mt-1"
                      alt="icon"
                    />
                    <p className="fs-24 fw-normal text-dark1 lh-24">
                      Faster doorstep <b>delivery</b> all over Australia
                    </p>
                  </li>
                  <li className="feature-point mb-20">
                    <img
                      src={FeatureIcon3}
                      className="img-fluid mt-1"
                      alt="icon"
                    />
                    <p className="fs-24 fw-normal text-dark1 lh-24">
                      <b>Quality</b> Vehicle with best price
                    </p>
                  </li>
                  <li className="feature-point mb-20">
                    <img
                      src={FeatureIcon4}
                      className="img-fluid mt-1"
                      alt="icon"
                    />
                    <p className="fs-24 fw-normal text-dark1 lh-24">
                      Finance <b>Facility</b>
                    </p>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        <div className="accessPanelRightInfo py-50">
          {/* <form className="accessRightAllInfo">
                        {stepNumber > 1 && stepNumber < 7 && (
                            <Link type="button" onClick={backBtn} className="text-decoration-none">
                                <Space>
                                    <div className="mt-4">
                                        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path
                                                d="M9.57 5.92969L3.5 11.9997L9.57 18.0697"
                                                stroke="#525355"
                                                strokeWidth="1.5"
                                                stroke-miterlimit="10"
                                                strokeLinecap="round"
                                                strokeLinejoin="round"
                                            />
                                            <path d="M20.4999 12H3.66992" stroke="#525355" strokeWidth="1.5" stroke-miterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
                                        </svg>
                                    </div>
                                    <p className="fs-16 fw-800 text-dark3">Back</p>
                                </Space>
                            </Link>
                        )}
                        {stepNumber === 1 && <SignUpStep1 nextBtn={nextBtn} />}
                        {stepNumber === 2 && <SignUpStep2 nextBtn={nextBtn} />}
                        {stepNumber === 3 && <SignUpStep3 nextBtn={nextBtn} />}
                        {stepNumber === 4 && <SignUpStep4 nextBtn={nextBtn} />}
                        {stepNumber === 5 && <SignUpStep5 nextBtn={nextBtn} />}
                        {stepNumber === 6 && <SignUpStep6 nextBtn={nextBtn} />}
                        {stepNumber === 7 && <SuccessStep />}
                    </form> */}

          <form className="accessRightAllInfo" onSubmit={handleRegistration}>
            <div className="accessTopInfo">
              <div className="accessHeader mb-20">
                <h2 className="fs-48 text-dark1 lh-58 mb-20">
                  Create an <b>account</b>
                </h2>
                <p className="fs-18 text-dark3 lh-24">
                  Amet minim mollit non deserunt ullamco est sit aliqua dolor do
                  amet sint.
                </p>
              </div>
            </div>
            <>
              <Form.Item
                rules={[
                  {
                    required: true,
                    message: "Please input your First Name!",
                  },
                ]}
              >
                <Input
                  prefix={<UserIcon />}
                  placeholder="First Name"
                  type="text"
                  name="firstName"
                  value={formData?.firstName}
                  onChange={handleChange}
                  required
                />
              </Form.Item>
              <Form.Item
                rules={[
                  {
                    required: true,
                    message: "Please input your Last Name!",
                  },
                ]}
              >
                <Input
                  prefix={<UserIcon />}
                  type="text"
                  placeholder="Last Name"
                  name="lastName"
                  value={formData.lastName}
                  onChange={handleChange}
                  required
                />
              </Form.Item>
              <Form.Item
                rules={[
                  {
                    required: true,
                    message: "Please your username!",
                  },
                ]}
              >
                <Input
                  prefix={<UserIcon />}
                  type="text"
                  placeholder="Please your username"
                  name="username"
                  value={formData.username}
                  onChange={handleChange}
                  required
                />
              </Form.Item>
              <Form.Item
                rules={[
                  {
                    required: true,
                    message: "Please input your mobile number!",
                  },
                ]}
              >
                <Input
                  prefix={<PhoneIcon />}
                  type="telephone"
                  placeholder="(XXX) XXX-XXXX"
                  name="phoneNo"
                  value={formData.phoneNo}
                  onChange={(e) =>
                    setFormData((prevData) => ({
                      ...prevData,
                      phoneNo: phoneNumberMask(e.target.value),
                    }))
                  }
                  required
                />
              </Form.Item>
              <Form.Item
                rules={[
                  {
                    required: true,
                    message: "Please input your email!",
                  },
                ]}
              >
                <Input
                  prefix={<EmailIcon />}
                  type="email"
                  placeholder="Email Address"
                  name="email"
                  value={formData.email}
                  onChange={handleChange}
                  required
                />
              </Form.Item>
              <Form.Item
                rules={[
                  {
                    required: true,
                    message: "Please enter your date of birth",
                  },
                ]}
              >
                <Input
                  prefix={<DateExpireIcon />}
                  type="text"
                  placeholder="DD/MM/YYYYYY"
                  name="dob"
                  value={formData?.dob}
                  onChange={handleChange}
                  required
                />
              </Form.Item>
              <Form.Item
                rules={[
                  {
                    required: true,
                    message: "Please enter your password",
                  },
                ]}
              >
                <Input.Password
                  prefix={<Key />}
                  type="password"
                  placeholder="Password"
                  name="password"
                  value={formData?.password}
                  onChange={handleChange}
                  required
                />
              </Form.Item>
              <Form.Item
                rules={[
                  {
                    required: true,
                    message: "Please enter your Driver License No",
                  },
                ]}
              >
                <Input
                  prefix={<NoteIcon />}
                  placeholder="Driver License No"
                  type="text"
                  name="dlNo"
                  value={formData.dlNo}
                  onChange={handleChange}
                />
              </Form.Item>
              <Form.Item
                rules={[
                  {
                    required: true,
                    message: "Please enter your Driver License Expiration date",
                  },
                ]}
              >
                <Input
                  prefix={<DateExpireIcon />}
                  placeholder="DD/MM/YYYYYY"
                  type="text"
                  name="dlExpairDate"
                  value={formData?.dlExpairDate}
                  onChange={handleChange}
                />
              </Form.Item>
              <Form.Item
                rules={[
                  {
                    required: true,
                    message: "Please input your address!",
                  },
                ]}
              >
                <Input
                  name="address"
                  prefix={<LocationIcon />}
                  placeholder="Input address please"
                  type="text"
                  value={formData?.address}
                  onChange={handleChange}
                  allowClear
                />
              </Form.Item>
            </>

            <div className="my-20">
              <button
                type="submit"
                className="fs-16 bg-clr-blue border-0 w-100 item-center gap-10 fw-800 text-white rounded fill-btn px-30 py-15"
              >
                Registration
              </button>
            </div>

            <div className="accessBottom text-center">
              <p className="fs-18 fw-normal text-dark1">
                Already have an account?{" "}
                <Link to="/login" className="text-decorate-none fw-800">
                  {" "}
                  Login
                </Link>
              </p>
            </div>
          </form>
        </div>
      </div>
    </section>
  );
};

export default Registration;
