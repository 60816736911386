import { Col, Row } from 'antd';
import React from 'react';
import { Link } from 'react-router-dom';
import significantImg from "../../assets/images/significant-img.png";
import './significant.scss';

const Significant = () => {
    return (
        <section className="significant skyGradientBg pb-100 pt-100">
            <div className="container">
                <Row gutter={[30, 30]} className="item-center">
                    <Col className="" xl={{ span: 12 }}>
                        <div className="significantImg text-xs-center">
                            <img src={significantImg} alt="significant Img" className="img-fluid" />
                        </div>
                    </Col>
                    <Col className="" xl={{ span: 12 }}>
                        <div className="significantInfo text-lg-start text-xs-center">
                            <span className="secTag ls-2 fs-12 text-blue fw-800 text-uppercase">significant decision</span>
                            <h2 className="secureCaption fs-48 text-dark1 lh-58 fw-normal mb-20">
                                Buying a car is a <b>significant decision</b> and are here to answer any questions you may have.
                            </h2>
                            <p className="significantIntro fs-16 fw-medium text-dark3 lh-24 mb-50">
                                Our team is here to help you through the process and make it as smooth and stress-free as possible
                            </p>
                            <div className="common-fill-btn">
                                <Link to='/faq' className="fs-16 bg-clr-blue fw-bold text-white rounded fill-btn">Visit FAQs</Link>
                            </div>
                        </div>
                    </Col>
                </Row>
            </div>
        </section>
    );
};

export default Significant;