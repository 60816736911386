import { useJsApiLoader } from "@react-google-maps/api";
import React from "react";
import { createContext, useState } from "react";
import useAuth from "../hooks/useAuth";

const PurchaseContext = createContext();

export const PurchaseProvider = ({ children }) => {


  const { customer } = useAuth();

  const [formData, setFormData] = useState({
    username: customer?.username || "",
    firstName: customer?.firstName || "",
    lastName: customer?.lastName || "",
    phoneNo: customer?.mobileNumber || "",
    email: customer?.email || "",
    password: "",
    dob: customer?.dateOfBirth || "",
    dlNo: customer?.drivingLicenseNumber || "",
    dlExpairDate: customer?.licenseExpiry || "",
    deliveryOption: "",
    doorDeliveryAddress: "",
    destinationLat: null,
    destinationLong: null,
    address: customer?.address || "",
    date: "",
    time: "",
    deliveryCost: "",
  });

  const [editUser, setEditUser] = useState(false);
  const [editDeliveryOption, setEditDeliveryOption] = useState(false);
  const [financing, setFinancing] = useState(false);
  const [isAM, setIsAM] = useState(); // Track whether it's AM or PM
  const [distance, setDistance] = useState(null);
  const [directionsResponse, setDirectionsResponse] = useState(null);

  // const [stripe, setStripe] = useState(null);
  // const [clientSecret, setClientSecret] = useState(null);
  const [payment, setPayment] = useState(1);
  const [card, setCard] = useState("Bank Payment");
  const [customAmount, setCustomAmount] = useState();

  const [paymentIntent, setPaymentIntent] = useState(null);
  const [isPaymentElementEmpty, setIsPaymentElementEmpty] = useState(true);

  const center = { lat: -33.85151, lng: 151.05117 };

  const { isLoaded, loadError } = useJsApiLoader({
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAPS_API_KEY,
    libraries: ["places"],
  });

  const handleChange = (e) => {
    const type = e.target.type;

    const name = e.target.name;

    const value = type === "file" ? e.target.files[0] : e.target.value;

    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const value = {
    formData,
    setFormData,
    handleChange,
    editUser,
    setEditUser,
    editDeliveryOption,
    setEditDeliveryOption,
    setIsAM,
    isAM,
    setFinancing,
    financing,
    setDistance,
    distance,
    directionsResponse,
    setDirectionsResponse,
    center,
    isLoaded,
    loadError,
    paymentIntent,
    setPaymentIntent,
    card,
    setCard,
    payment,
    setPayment,
    setCustomAmount,
    customAmount,
    setIsPaymentElementEmpty,
    isPaymentElementEmpty
  };

  // console.log(isPaymentElementEmpty);

  return (
    <PurchaseContext.Provider value={value}>
      {children}
    </PurchaseContext.Provider>
  );
};

export default PurchaseContext;
