import { DownOutlined } from "@ant-design/icons";
import { Checkbox, Collapse, Form, Input, Select } from "antd";
import React from "react";
import { ReactComponent as BodyTypeIcon } from "../../assets/images/bodyTypeIcon.svg";
import { ReactComponent as CalendarIcon } from "../../assets/images/calendar-2.svg";
import { ReactComponent as CarModelIcon } from "../../assets/images/car-model.svg";
import { ReactComponent as ColorFilterIcon } from "../../assets/images/colorfilter.svg";
import { ReactComponent as FuelTypeIcon } from "../../assets/images/fuelTypeIcon.svg";
import { ReactComponent as MeterIcon } from "../../assets/images/meter.svg";
import { ReactComponent as MoneyIcon } from "../../assets/images/money-4.svg";
import { ReactComponent as SearchIcon } from "../../assets/images/search-normal.svg";
import { ReactComponent as SeatIcon } from "../../assets/images/seat.svg";
import { ReactComponent as SettingIcon } from "../../assets/images/setting-2.svg";



import useFilter from "../../hooks/useFilter";
import "./filter.scss";

const { Panel } = Collapse;

const CheckboxGroupWithCount = ({ checkboxOptions, setOptions }) => {

  const handleChange = (checkedValues) => {
      setOptions(checkedValues);
  };


  // const handleChange = (value) => {
  //     setOptions(value);
  // };

    const uniqueOptions = checkboxOptions?.filter((item, index) => {
        return (
            index ===
            checkboxOptions.findIndex((obj) => {
                return obj.value === item.value;
            })
        );
    });

    const optionCounts = checkboxOptions.reduce((acc, cur) => {
        acc[cur.value] = checkboxOptions.filter((item) => item.value === cur.value).length;
        return acc;
    }, {});

    return (
        <Checkbox.Group onChange={handleChange}>
            {uniqueOptions.map((option, index) => (
                <Checkbox key={index} value={option.value}>
                    {option.label} ({optionCounts[option.value]})
                </Checkbox>
            ))}
        </Checkbox.Group>
        
    );
};

const Filter = () => {

    const {
        modelOptions,
        makeOptions,
        colorOptions,
        fuelTypeOptions,
        bodyTypeOptions,
        doorOptions,
        yearOptions,
        seatOptions,
        setModel,
        setMake,
        setColour,
        setFuelType,
        setBodyType,
        setDoor,
        setSeats,
        setMinPrice,
        setMaxPrice,
        setMinKm,
        setFromYear,
        setToYear,
        setMaxKm,
    } = useFilter();


    const uniqueOptions = yearOptions
        ?.filter((item, index) => {
            return (
                index ===
                yearOptions.findIndex((obj) => {
                    return obj.value === item.value;
                })
            );
        })
        ?.sort((a, b) => a.value - b.value);

    const priceOptions = [
        {
            value: "10000",
            label: "$10,000",
        },
        {
            value: "15000",
            label: "$15,000",
        },
        {
            value: "20000",
            label: "$20,000",
        },
        {
            value: "25000",
            label: "$25,000",
        },
        {
            value: "30000",
            label: "$30,000",
        },
        {
            value: "35000",
            label: "$35,000",
        },
        {
            value: "40000",
            label: "$40,000",
        },
        {
            value: "45000",
            label: "$45,000",
        },
        {
            value: "50000",
            label: "$50,000",
        },
        {
            value: "60000",
            label: "$60,000",
        },
        {
            value: "70000",
            label: "$70,000",
        },
        {
            value: "80000",
            label: "$80,000",
        },
        {
            value: "90000",
            label: "$90,000",
        },
        {
            value: "100000",
            label: "$100,000",
        },
        {
            value: "150000",
            label: "$150,000",
        },
    ];

    const kmsOptions = [
        {
            value: "10000",
            label: "10,000 km",
        },
        {
            value: "20000",
            label: "20,000 km",
        },
        {
            value: "30000",
            label: "30,000 km",
        },
        {
            value: "40000",
            label: "40,000 km",
        },
        {
            value: "50000",
            label: "50,000 km",
        },
        {
            value: "60000",
            label: "60,000 km",
        },
        {
            value: "70000",
            label: "70,000 km",
        },
        {
            value: "80000",
            label: "80,000 km",
        },
        {
            value: "90000",
            label: "90,000 km",
        },
        {
            value: "100000",
            label: "100,000 km",
        },
        {
            value: "150000",
            label: "150,000 km",
        },
    ];

        // console.log(minKm, maxKm);
    


    return (
      <div className="filter">
        <Collapse
          className="fliter-accordion"
          accordion
          expandIconPosition="end"
          expandIcon={({ isActive }) => (
            <DownOutlined rotate={isActive ? 180 : 0} />
          )}
        >
          <Panel extra={<CarModelIcon />} header="Make" key="0">
            <Form.Item
              name="searchModel"
              rules={[
                {
                  required: true,
                  message: "Please input make",
                },
              ]}
            >
              <Input
                prefix={<SearchIcon />}
                placeholder="Search Make"
                type="text"
              />
            </Form.Item>

            <div>
              <CheckboxGroupWithCount
                checkboxOptions={makeOptions}
                setOptions={setMake}
              />
            </div>
          </Panel>
          <Panel extra={<CarModelIcon />} header="Model" key="1">
            <Form.Item
              name="searchModel"
              rules={[
                {
                  required: true,
                  message: "Please input model",
                },
              ]}
            >
              <Input
                prefix={<SearchIcon />}
                placeholder="Search model"
                type="text"
              />
            </Form.Item>

            <div>
              <CheckboxGroupWithCount
                checkboxOptions={modelOptions}
                setOptions={setModel}
              />
            </div>
          </Panel>
          <Panel
            extra={<CalendarIcon />}
            header="Year"
            key="9"
            className="range-panel"
          >
            <div className="range-wrapper">
              <Select
                defaultValue="From"
                onChange={(value) => setFromYear(value)}
                options={uniqueOptions}
              />
              <div className="text-blue">-</div>
              <Select
                defaultValue="To"
                onChange={(value) => setToYear(value)}
                options={uniqueOptions}
              />
            </div>
          </Panel>
          <Panel
            extra={<MoneyIcon />}
            header="Price"
            key="7"
            className="range-panel"
          >
            <div className="range-wrapper">
              <Select
                defaultValue="Min"
                // onChange={handleChange}
                options={priceOptions}
                onChange={(value) => setMinPrice(value)}
              />
              <div className="text-blue">-</div>
              <Select
                defaultValue="Max"
                // onChange={handleChange}
                options={priceOptions}
                onChange={(value) => setMaxPrice(value)}
              />
            </div>
          </Panel>
          <Panel extra={<ColorFilterIcon />} header="Color" key="2">
            <CheckboxGroupWithCount
              checkboxOptions={colorOptions}
              setOptions={setColour}
            />
          </Panel>
          <Panel extra={<FuelTypeIcon />} header="Fuel Type" key="3">
            <CheckboxGroupWithCount
              checkboxOptions={fuelTypeOptions}
              setOptions={setFuelType}
            />
          </Panel>
          <Panel extra={<BodyTypeIcon />} header="Body Type" key="4">
            <CheckboxGroupWithCount
              checkboxOptions={bodyTypeOptions}
              setOptions={setBodyType}
            />
          </Panel>
          <Panel
            extra={<MeterIcon />}
            header="KMs"
            key="8"
            className="range-panel"
          >
            <div className="range-wrapper">
              <Select
                defaultValue="Min"
                onChange={(value) => setMinKm(value)}
                options={kmsOptions}
              />
              <div className="text-blue">-</div>
              <Select
                defaultValue="Max"
                onChange={(value) => setMaxKm(value)}
                options={kmsOptions}
              />
            </div>
          </Panel>
          <Panel extra={<SettingIcon />} header="Doors" key="5">
            <CheckboxGroupWithCount
              checkboxOptions={doorOptions}
              setOptions={setDoor}
            />
          </Panel>
          <Panel extra={<SeatIcon />} header="Seats" key="6">
            <CheckboxGroupWithCount
              checkboxOptions={seatOptions}
              setOptions={setSeats}
            />
          </Panel>
        </Collapse>
      </div>
    );
};

export default Filter;
