import React from 'react'
import './subpageBanner.scss'

const SubpageBanner = ({ title, subTitle, bgImg }) => {

  return (

    <div className='subpage-banner' style={{
      backgroundImage: `url(${bgImg})`,
      backgroundRepeat: 'no-repeat',
      backgroundPosition: 'center center',
      backgroundSize: 'cover',

}}>
      <div className="container">
        <div className="subPageWrapper">
          <div className="subPageContent">
            <h1 className='fs-48 text-dark1 fw-800 mb-20'>{title}</h1>
            <p className='fs-20 text-dark1'>{subTitle}</p>
          </div>
        </div>
      </div>
    </div>

  )
}

export default SubpageBanner