import { InputNumber, Select } from "antd";
import { Option } from "antd/es/mentions";
import moment from "moment/moment";
import React, { useState } from "react";
import useFetch from "../../hooks/useFetch";

const LoanInformation = ({
  commercialUse,
  setCommercialUse,
  applicationInfo,
  setApplicationInfo,
  handleChange,
}) => {
  const { data: businessNature } = useFetch(
    "https://www.cbs.s1.importautos.com.au/business/nature/v1/api/"
  );

  const { data: years } = useFetch(
    "https://www.cbs.s1.importautos.com.au/years/"
  );

  // const [gst, setGst] = useState(false);

  const onDepositChange = (value) => {
    setApplicationInfo((prevData) => ({
      ...prevData,
      deposit: value,
    }));
  };

  const handleBusinessMonth = (value) => {
    setApplicationInfo((prevData) => ({
      ...prevData,
      businessInitiationMonth: value,
    }));
  };

  const handleBusinessYear = (value) => {
    setApplicationInfo((prevData) => ({
      ...prevData,
      businessInitiationYear: value,
    }));
  };

  // const handleDuration = (e) => {
  //     setApplicationInfo((prevData) => ({
  //       ...prevData,
  //       duration: e.target.value,
  //     }));
  // }

  // const handleBusinessType = (e) => {
  //   setApplicationInfo((prevData) => ({
  //     ...prevData,
  //     businessType: e.target.value,
  //   }));
  // }

  console.log(applicationInfo);

  return (
    <>
      <h3 className="fs-30 fw-800 mb-20">Loan Information</h3>
      <div className="yes-no-wrapper mb-40">
        <h4>Do you plan to use the car for commercial use?</h4>
        <p>
          Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
          eiusmod tempor incididunt ut labore{" "}
        </p>
        <div className="button-wrapper">
          <button
            className={`yes-no-btn ${!commercialUse && "active"}`}
            onClick={() => setCommercialUse(false)}
          >
            No
          </button>
          <button
            className={`yes-no-btn ${commercialUse && "active"}`}
            onClick={() => setCommercialUse(true)}
          >
            Yes
          </button>
        </div>
      </div>

      {!commercialUse && (
        <div>
          <div className="mb-40">
            <h5 className="question">
              01. What amount do you intend to put down as a deposit?
            </h5>
            <div className="button-wrapper">
              <InputNumber
                name="deposit"
                className=""
                addonBefore={
                  <svg
                    width="11"
                    height="17"
                    viewBox="0 0 11 17"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M4.653 16.791V14.658H6.822V16.791H4.653ZM4.653 2.391V0.257999H6.822V2.391H4.653ZM5.787 15.27C4.875 15.27 4.059 15.102 3.339 14.766C2.619 14.43 2.028 13.956 1.566 13.344C1.11 12.732 0.819 12.006 0.693 11.166L2.835 10.824C3.003 11.562 3.363 12.15 3.915 12.588C4.473 13.02 5.139 13.236 5.913 13.236C6.657 13.236 7.269 13.062 7.749 12.714C8.229 12.36 8.469 11.913 8.469 11.373C8.469 10.995 8.355 10.689 8.127 10.455C7.899 10.215 7.533 10.017 7.029 9.861L3.906 8.889C2.088 8.325 1.179 7.221 1.179 5.577C1.179 4.809 1.362 4.14 1.728 3.57C2.1 2.994 2.622 2.553 3.294 2.247C3.966 1.935 4.758 1.782 5.67 1.788C6.516 1.794 7.269 1.947 7.929 2.247C8.595 2.547 9.147 2.979 9.585 3.543C10.029 4.107 10.344 4.785 10.53 5.577L8.307 5.982C8.223 5.556 8.058 5.181 7.812 4.857C7.566 4.533 7.257 4.281 6.885 4.101C6.513 3.921 6.099 3.825 5.643 3.813C5.205 3.807 4.812 3.876 4.464 4.02C4.116 4.158 3.84 4.356 3.636 4.614C3.432 4.866 3.33 5.151 3.33 5.469C3.33 5.823 3.471 6.126 3.753 6.378C4.041 6.63 4.494 6.846 5.112 7.026L7.443 7.701C8.565 8.025 9.372 8.466 9.864 9.024C10.362 9.576 10.611 10.311 10.611 11.229C10.611 12.033 10.41 12.738 10.008 13.344C9.606 13.95 9.042 14.424 8.316 14.766C7.59 15.102 6.747 15.27 5.787 15.27Z"
                      fill="#0473EA"
                    />
                  </svg>
                }
                style={{
                  width: "100%",
                }}
                type="number"
                value={applicationInfo.deposit}
                onChange={onDepositChange}
              />
            </div>
          </div>
          <div className="mb-40">
            <h5 className="question">
              02. What is your desired duration for the loan (in years)?
            </h5>
            <div className="button-wrapper">
              {["3", "4", "5", "6"].map((item, index) => (
                <label
                  key={index}
                  htmlFor={`duration${index + 1}`}
                  className={`yes-no-btn ${
                    applicationInfo.duration == item && "active"
                  }`}
                >
                  {item}

                  <input
                    name="duration"
                    id={`duration${index + 1}`}
                    className={`yes-no-btn ${
                      applicationInfo.duration == item && "active"
                    }`}
                    type="radio"
                    value={item}
                    onClick={handleChange}
                    hidden
                  />
                </label>
              ))}
            </div>
          </div>
        </div>
      )}

      {commercialUse && (
        <div>
          <div className="mb-40">
            <h5 className="question">What nature of business do you run?</h5>
            <div className="button-wrapper">
              {businessNature.map((item, index) => (
                <label
                  key={index}
                  htmlFor={`businessNature${item.id}`}
                  className={`yes-no-btn ${
                    applicationInfo.businessNature == item?.id && "active"
                  }`}
                >
                  {item.nature}

                  <input
                    name="businessNature"
                    id={`businessNature${item.id}`}
                    className={`yes-no-btn ${
                      applicationInfo.businessNature == item.id && "active"
                    }`}
                    type="radio"
                    value={item?.id}
                    onClick={handleChange}
                    hidden
                  />
                </label>
              ))}
            </div>
          </div>

          <div className="mb-40">
            <h5 className="question">When was your business initiated?</h5>
            <div className="button-wrapper">
              <Select
                className="custom-month-year-select"
                defaultValue="Month"
                style={{
                  width: 342,
                }}
                onChange={handleBusinessMonth}
              >
                {moment.months().map((month, index) => (
                  <Option key={index} value={month}>
                    {month}
                  </Option>
                ))}
              </Select>

              <Select
                className="custom-month-year-select"
                defaultValue="Year"
                style={{
                  width: 342,
                }}
                onChange={handleBusinessYear}
              >
                {/* {Array.from(Array(50), (_, i) => {
                  const year = moment().year() - i;
                  return (
                    <Option key={year} value={year}>
                      {year}
                    </Option>
                  );
                })} */}

                {years.map((year) => (
                  <Option key={year.id} value={`${year.id}-${year.year}`}>
                    {year.year}
                  </Option>
                )).reverse()}
              </Select>
            </div>
          </div>

          <div className="mb-40">
            <h5 className="question">
              How long has your business been registered with ABN?
            </h5>
            <div className="button-wrapper">
              <Select
                className="custom-month-year-select"
                defaultValue="Month"
                style={{
                  width: 342,
                }}
                onChange={(value) =>
                  setApplicationInfo((prevData) => ({
                    ...prevData,
                    businessABNRegisteredMonth: value,
                  }))
                }
              >
                {moment.months().map((month, index) => (
                  <Option key={index} value={month}>
                    {month}
                  </Option>
                ))}
              </Select>

              <Select
                className="custom-month-year-select"
                defaultValue="Year"
                style={{
                  width: 342,
                }}
                onChange={(value) =>
                  setApplicationInfo((prevData) => ({
                    ...prevData,
                    businessABNRegisteredYear: value,
                  }))
                }
              >
                {/* {Array.from(Array(50), (_, i) => {
                  const year = moment().year() - i;
                  return (
                    <Option key={year} value={year}>
                      {year}
                    </Option>
                  );
                })} */}
                {years.map((year) => (
                  <Option key={year.id} value={`${year.id}-${year.year}`}>
                    {year.year}
                  </Option>
                )).reverse()}
              </Select>
            </div>
          </div>

          <div className="mb-40">
            <h5 className="question">Is your enterprise GST registered?</h5>
            <div className="button-wrapper">
              <button
                className={`yes-no-btn ${!applicationInfo?.gst && "active"}`}
                onClick={() =>
                  setApplicationInfo((prevData) => ({
                    ...prevData,
                    gst: false,
                  }))
                }
              >
                No
              </button>
              <button
                className={`yes-no-btn ${applicationInfo?.gst && "active"}`}
                onClick={() =>
                  setApplicationInfo((prevData) => ({
                    ...prevData,
                    gst: true,
                  }))
                }
              >
                Yes
              </button>
            </div>
          </div>

          {applicationInfo?.gst && (
            <div>
              <h4
                style={{
                  fontSize: "24px",
                  fontWeight: "700",
                  marginBottom: "20px",
                }}
              >
                GST Information
              </h4>
              <div className="mb-40">
                <h5 className="question">
                  Could you specify the date your business was registered for
                  GST?
                </h5>
                <div className="button-wrapper">
                  <Select
                    className="custom-month-year-select"
                    defaultValue="Month"
                    style={{
                      width: 342,
                    }}
                    onChange={(value) =>
                      setApplicationInfo((prevData) => ({
                        ...prevData,
                        gstMonth: value,
                      }))
                    }
                  >
                    {moment.months().map((month, index) => (
                      <Option key={index} value={index + 1}>
                        {month}
                      </Option>
                    ))}
                  </Select>

                  <Select
                    className="custom-month-year-select"
                    defaultValue="Year"
                    style={{
                      width: 342,
                    }}
                    onChange={(value) =>
                      setApplicationInfo((prevData) => ({
                        ...prevData,
                        gstYear: value,
                      }))
                    }
                  >
                    {/* {Array.from(Array(50), (_, i) => {
                      const year = moment().year() - i;
                      return (
                        <Option key={year} value={year}>
                          {year}
                        </Option>
                      );
                    })} */}

                    {years.map((year) => (
                  <Option key={year.id} value={`${year.id}-${year.year}`}>
                    {year.year}
                  </Option>
                )).reverse()}
                  </Select>
                </div>
              </div>

              <div className="mb-40">
                <h5 className="question">
                  What amount do you intend to put down as a deposit?
                </h5>
                <div className="button-wrapper">
                  <InputNumber
                    className=""
                    addonBefore={
                      <svg
                        width="11"
                        height="17"
                        viewBox="0 0 11 17"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M4.653 16.791V14.658H6.822V16.791H4.653ZM4.653 2.391V0.257999H6.822V2.391H4.653ZM5.787 15.27C4.875 15.27 4.059 15.102 3.339 14.766C2.619 14.43 2.028 13.956 1.566 13.344C1.11 12.732 0.819 12.006 0.693 11.166L2.835 10.824C3.003 11.562 3.363 12.15 3.915 12.588C4.473 13.02 5.139 13.236 5.913 13.236C6.657 13.236 7.269 13.062 7.749 12.714C8.229 12.36 8.469 11.913 8.469 11.373C8.469 10.995 8.355 10.689 8.127 10.455C7.899 10.215 7.533 10.017 7.029 9.861L3.906 8.889C2.088 8.325 1.179 7.221 1.179 5.577C1.179 4.809 1.362 4.14 1.728 3.57C2.1 2.994 2.622 2.553 3.294 2.247C3.966 1.935 4.758 1.782 5.67 1.788C6.516 1.794 7.269 1.947 7.929 2.247C8.595 2.547 9.147 2.979 9.585 3.543C10.029 4.107 10.344 4.785 10.53 5.577L8.307 5.982C8.223 5.556 8.058 5.181 7.812 4.857C7.566 4.533 7.257 4.281 6.885 4.101C6.513 3.921 6.099 3.825 5.643 3.813C5.205 3.807 4.812 3.876 4.464 4.02C4.116 4.158 3.84 4.356 3.636 4.614C3.432 4.866 3.33 5.151 3.33 5.469C3.33 5.823 3.471 6.126 3.753 6.378C4.041 6.63 4.494 6.846 5.112 7.026L7.443 7.701C8.565 8.025 9.372 8.466 9.864 9.024C10.362 9.576 10.611 10.311 10.611 11.229C10.611 12.033 10.41 12.738 10.008 13.344C9.606 13.95 9.042 14.424 8.316 14.766C7.59 15.102 6.747 15.27 5.787 15.27Z"
                          fill="#0473EA"
                        />
                      </svg>
                    }
                    style={{
                      width: "100%",
                    }}
                    type="number"
                    value={applicationInfo.deposit}
                    onChange={onDepositChange}
                  />
                </div>
              </div>

              <div className="mb-40">
                <h5 className="question">
                  What is your desired duration for the loan (in years)?
                </h5>
                <div className="button-wrapper">
                  {["3", "4", "5", "6"].map((item, index) => (
                    <label
                      key={index}
                      htmlFor={`duration${index + 1}`}
                      className={`yes-no-btn ${
                        applicationInfo.duration == item && "active"
                      }`}
                    >
                      {item}

                      <input
                        name="duration"
                        id={`duration${index + 1}`}
                        className={`yes-no-btn ${
                          applicationInfo.duration == item && "active"
                        }`}
                        type="radio"
                        value={item}
                        onChange={handleChange}
                        hidden
                      />
                    </label>
                  ))}
                </div>
              </div>
            </div>
          )}
        </div>
      )}
    </>
  );
};

export default LoanInformation;
