import { Button, Col, Form, Input, Row } from 'antd';
import React from 'react';
import './contactInfo.scss';
const { TextArea } = Input;

const onFinish = (values) => {
    console.log('Success:', values);
};

const onFinishFailed = (errorInfo) => {
    console.log('Failed:', errorInfo);
};


const ContactInfo = ({ title, hints, bgImg }) => {
    return (
        <>
         
                <div className='contactbanner' style={{
                    backgroundImage: `url(${bgImg})`,
                    backgroundRepeat: 'no-repeat',
                    backgroundPosition: 'center center',
                    backgroundSize: 'cover',
                }}>
                    <div className="container">
                        <div className="contact-title">
                            <h1 className='fs-48 text-dark1 fw-800 mb-20'>{title}</h1>
                            <p className='fs-20 text-dark1'>{hints}</p>
                        </div>
                    </div>
                </div>
            <div className='container'>
                <Row className='pt-100' justify='center' align='middle' gutter={[24, 24]}>
                    {/* <Col xs={{span:24}} lg={{span:12}}>
                        <div className='text-center'>
                            <img src={contactImg} className='img-fluid' alt='contact-img' style={{width: "88%"}}/>
                        </div>
                    </Col> */}
                    <Col xs={{ span: 24 }} className='contact-info'>
                            <div className="contact-info-wrapper">
                                <Form
                                    name="basic"
                                    initialValues={{ remember: true }}
                                    onFinish={onFinish}
                                    onFinishFailed={onFinishFailed}
                                    autoComplete="off">
                                    <Form.Item
                                        name="firstname"
                                        rules={[{ required: true, message: 'Please input your first name!' }]}>
                                        <Input placeholder='First name' className='form-control first-name' />
                                    </Form.Item>
                                    <Form.Item
                                        name="lastname"
                                        rules={[{ required: true, message: 'Please input your last name!' }]}>
                                        <Input placeholder='Last name' className='form-control first-name' />
                                    </Form.Item>
                                    <Form.Item
                                        name="mobileNumber"
                                        rules={[{ required: true, message: 'Please input your Mobile Number!' }]}>
                                        <Input placeholder='Mobile number' className='form-control mobileNumber' />
                                    </Form.Item>
                                    <Form.Item
                                        name="email"
                                        rules={[{ required: true, message: 'Please input your email!' }]}>
                                        <Input placeholder='Email address' className='form-control email' />
                                    </Form.Item>
                                    <Form.Item
                                        name="query"
                                        rules={[{ required: true, message: 'Please input your reason' }]}>
                                        <Input placeholder='Reason for  query' className='form-control reason' />
                                    </Form.Item>
                                    <Form.Item >
                                        <TextArea rows={8} placeholder='Additional Details' className='textarea' />
                                    </Form.Item>


                                    <Form.Item>
                                        <Button type="primary" htmlType="submit" className='fs-16 submitBtn fw-bold'>
                                            Submit
                                        </Button>
                                    </Form.Item>



                                </Form>
                            </div>
                    </Col>

                </Row>
         </div>
        </>
    )
}

export default ContactInfo