import React, { useState } from "react";
import ChatPhone from "../../assets/images/chat-phone.svg";
import ChatCallOutgoing from "../../assets/images/chat-call-outgoing.svg";
import WhatsAppIcon from "../../assets/images/whats-app-icon.svg";
import ChatMsgIcon from "../../assets/images/chat-msg-icon.svg";

import "./chatBox.scss";
import { Link } from "react-router-dom";

const ChatBox = () => {
  const [chatOpen, setChatOpen] = useState(false);

  return (
    <div
      className={`${chatOpen && "active"} chat-box`}
    >
      <div className={`${chatOpen && "active"} chatbox-open-state`}>
        <Link className="chatbox-open-state-item">
          <img src={ChatCallOutgoing} alt="icon" />
          <span>Call us at +61 423 840 130</span>
        </Link>

        <div className="item-divider" />

        <Link className="chatbox-open-state-item">
          <img src={WhatsAppIcon} alt="icon" />
          <span>Live chat via Whatsapp</span>
        </Link>

        <div className="item-divider" />

        <Link className="chatbox-open-state-item">
          <img src={ChatMsgIcon} alt="icon" />
          <span>Live chat via SMS</span>
        </Link>
      </div>
      <div className="chat-box-initial-state-wrapper">
        <div className="chat-box-initial-state"  onClick={() => setChatOpen(true)}>
          <div>
            <img src={ChatPhone} alt="img" />
          </div>
          <p className="fs-18 text-white">Contact Us</p>
        </div>

        {chatOpen && <Link onClick={()=> setChatOpen(false)}>
            <svg
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M6 18L18 6"
            stroke="white"
            stroke-width="1.5"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <path
            d="M18 18L6 6"
            stroke="white"
            stroke-width="1.5"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
        </svg>
        </Link>}
      </div>
    </div>
  );
};

export default ChatBox;
