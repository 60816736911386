import { RightOutlined } from "@ant-design/icons";
import { Badge, Col, Row, Spin, Tabs } from "antd";
import React from "react";
import { renderToString } from "react-dom/server";
import { useLocation, useParams } from "react-router-dom";
import RightTickImg from "../../assets/images/green-circle-right.svg";
import History from "../../components/History/History";
import CardetailsSlider from "../../components/cardetailsSlider/CardetailsSlider";
import CardetailsTopFeature from "../../components/cardetailsTopFeature/CardetailsTopFeature";
import DynamicBreadcrumb from "../../components/dynamicBreadcrumb/DynamicBreadcrumb";
import FeatureItem from "../../components/featureItem/FeatureItem";
import FeaturedListing from "../../components/featuredListing/FeaturedListing";
import InspectionReport from "../../components/inspectionReport/InspectionReport";
import Measurement from "../../components/measurement/Measurement";
import ProductDetailsFeature from "../../components/productDetailsFeature/ProductDetailsFeature";
import PurchaseOptions from "../../components/purchaseOptions/PurchaseOptions";
import SpecificationTable from "../../components/specificationTable/SpecificationTable";
import useFetch from "../../hooks/useFetch";
import useFilter from "../../hooks/useFilter";
import "./carDetails.scss";

const CarDetails = () => {
  const { carId } = useParams();

  const { newData, loading: featuredLoading } = useFilter();

  const { data: singleDetails, loading } = useFetch(
    `https://www.cbs.s1.importautos.com.au/product-index/v1/api/vehicle/stock/${carId}`
  );

  const outlines = singleDetails?.outline?.filter((item) => item !== "");

  const location = useLocation();

  const [first, ...rest] = location.pathname.split("/");

  const iconString = renderToString(<RightOutlined />);

  const remainder = rest?.map((element, index) => (
    <React.Fragment key={index}>
      {index > 0 && (
        <span
          className="mx-10"
          dangerouslySetInnerHTML={{ __html: iconString }}
        />
      )}
      {element}
    </React.Fragment>
  ));

  const tabData = [
    {
      title: "Specification",
      tabContent: <SpecificationTable singleDetails={singleDetails} />,
    },
    {
      title: "Measurements",
      tabContent: <Measurement singleDetails={singleDetails} />,
    },
    {
      title: "Features",
      tabContent: <FeatureItem singleDetails={singleDetails} />,
    },
  ];

  const featuredListingTitle = `Explore our inventory for your <br/> <b>perfect</b> match`;

  return (
    <>
      {loading ? (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "100vh",
          }}
        >
          <Spin size="large" />
        </div>
      ) : (
        <>
          <section>
            <div className="container py-50">
              <div>
                <DynamicBreadcrumb
                  dynamicRoute={remainder}
                  singleDetails={singleDetails}
                />
              </div>
            </div>
            <div className="container mobile-details-title">
              <p className="text-dark3 fw-semi-bold mb-4">
                Stock No: #{singleDetails?.stockNo}
              </p>
              <h1 className="fs-30 mb-15">{singleDetails?.title}</h1>
            </div>
            <Row className="mobile-delails-slider">
              <Col xs={{ span: 24 }}>
                <CardetailsSlider singleDetails={singleDetails} />
              </Col>
            </Row>
            <div className="container">
              <Row justify="space-between" gutter={[30, 30]} className="mb-50">
                <Col
                  xs={{ span: 24 }}
                  lg={{ span: 14 }}
                  className="desktop-details-slider"
                >
                  { (singleDetails?.isOnOffer || singleDetails?.soldStatus === "Sold") ? 
                    <Badge.Ribbon text={`${singleDetails?.isOnOffer ? "ON-OFFER" : "SOLD OUT"} `} color="gold" style={{zIndex:"2"}}>
                    <CardetailsSlider singleDetails={singleDetails} />
                  </Badge.Ribbon>
                  :
                  <CardetailsSlider singleDetails={singleDetails} />
                  }
                </Col>
                <Col xs={{ span: 24 }} lg={{ span: 10 }}>
                  <CardetailsTopFeature singleDetails={singleDetails} />
                  <div className="desktop-purchase-option">
                    <PurchaseOptions singleDetails={singleDetails} />
                  </div>
                  {/* <NextAction singleDetails={singleDetails} /> */}
                </Col>
              </Row>

              <Row justify="space-between" gutter={[30, 30]}>
                <Col xs={{ span: 24 }} lg={{ span: 24 }}>
                  <div
                    data-aos="fade-up"
                    data-aos-easing="ease-in-sine"
                    data-aos-duration="1000"
                  >
                    <Tabs
                      defaultActiveKey="1"
                      className="full-width-tab"
                      items={tabData?.map((item, i) => {
                        const id = String(i + 1);
                        return {
                          label: (
                            <span className="text-center">
                              <h6 className="fw-800 fs-14">{item?.title}</h6>
                            </span>
                          ),
                          key: id,
                          children: <div>{item?.tabContent}</div>,
                        };
                      })}
                    />
                  </div>
                </Col>
              </Row>

              <div>
                <History singleDetails={singleDetails} />
              </div>

              <InspectionReport singleDetails={singleDetails} />

              <ProductDetailsFeature singleDetails={singleDetails} />

              {outlines?.length > 0 && (
                <>
                  <div
                    data-aos="fade-up"
                    data-aos-easing="ease-in-sine"
                    data-aos-duration="1000"
                  >
                    <h4 className="fs-30 fw-800 text-dark1 pb-15 pt-100">
                      {singleDetails?.title}
                    </h4>
                    <div className="gradiant-border"></div>
                  </div>

                  <Row
                    justify="space-between"
                    gutter={[30, 0]}
                    className="feature-out-line-wrapper"
                  >
                    {outlines?.map((outline, index) => {
                      return (
                        <Col xs={{ span: 24 }} lg={{ span: 12 }} key={index}>
                          {outline && (
                            <div
                              className="feature-outline-list"
                              data-aos="fade-up"
                              data-aos-easing="ease-in-sine"
                              data-aos-duration="1000"
                            >
                              <span>
                                <img
                                  src={RightTickImg}
                                  alt="icon"
                                  className="mt-4"
                                />
                              </span>
                              <p>{outline.trim()}</p>
                            </div>
                          )}
                        </Col>
                      );
                    })}
                  </Row>
                </>
              )}
            </div>
          </section>

          <FeaturedListing
            data={newData}
            loading={featuredLoading}
            featuredListingTitle={featuredListingTitle}
          />
        </>
      )}
    </>
  );
};

export default CarDetails;
