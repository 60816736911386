import { Col, Row } from 'antd';
import React from 'react';
import FeatureIcon1 from "../assets/images/landing-feature-icon-1.svg";
import FeatureIcon2 from "../assets/images/landing-feature-icon-2.svg";
import FeatureIcon3 from "../assets/images/landing-feature-icon-3.svg";
import FeatureIcon4 from "../assets/images/landing-feature-icon-4.svg";
import "../components/whyCarbarn/whyCarbarn.scss";
import '../components/whyHybrid/whyHybrid.scss';

const LandingFeature = () => {

  const featureData = [
      {
          img: FeatureIcon1,
          title: `5-Years <br /> <span class='text-blue'>Warranty</span>`,
          desc: "$3000 claim value",
      },

      {
          img: FeatureIcon2,
          title: `24/7 Roadside <br /> <span class='text-blue'>Assistance</span>`,
          desc: "For all vehicles",
      },

      {
          img: FeatureIcon3,
          title: `Fast Door <br /> <span class='text-blue'>Delivery</span>`,
          desc: "Get it anywhere!",
      },

      {
          img: FeatureIcon4,
          title: `Easy <br /> <span class='text-blue'>Pre-Approval</span>`,
          desc: "With Lender",
      },
  ];

  return (
      <section className="hybrid-vehical banner-bottom py-4">
          <div className="container">
              {/* <Row gutter={[24, 24]} justify="space-between">
                  {featureData.map((feature, index) => {
                      return (
                          <Col xs={{ span: 24 }} sm={{ span: 12 }} lg={{ span: 6 }} key={index}>
                              <div className="banner-bottom-content" data-aos="fade-up" data-aos-easing="ease-in-sine" data-aos-duration="1000">
                                  <div className="imh-box">
                                      <img src={feature.img} className="img-fluid mb-10" alt="img" />
                                  </div>
                                  <h4 className="fs-18 fw-800 text-dark1 mb-10">{feature.title}</h4>
                              </div>
                          </Col>
                      );
                  })}
              </Row> */}

              {/* <div className="mt-80"> */}
              <Row gutter={[40, 30]} justify="space-between">
                  {featureData.map((feature, index) => {
                      return (
                          <Col xs={{ span: 24 }} md={{ span: 12 }} xl={{ span: 6 }} key={index}>
                              <div className="why-carbarn-item h-100" data-aos="flip-left" data-aos-easing="ease-out-cubic" data-aos-duration="1000">
                                  <div className="img-box">
                                      <img src={feature.img} className="service img-fluid" alt="icon" />
                                  </div>
                                  <div>
                                      <h4 className=" text-dark1 fs-24 fw-semi-bold mb-10" dangerouslySetInnerHTML={{ __html: feature.title }}></h4>
                                      <p className="fs-14 text-dark3">{feature.desc}</p>
                                  </div>
                              </div>
                          </Col>
                      );
                  })}
              </Row>
              {/* </div> */}
          </div>
      </section>
  );
};

export default LandingFeature;