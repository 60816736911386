import { Collapse } from "antd";
import React from "react";
import { ReactComponent as BodyComponent } from "../../assets/images/body-component.svg";
import { ReactComponent as ComfortIcon } from "../../assets/images/comfort-component.svg";
import { ReactComponent as ArrowIcon } from "../../assets/images/component-accordion-arrow.svg";
import { ReactComponent as ElectricalIcon } from "../../assets/images/electrical-component.svg";
import { ReactComponent as MechenicalIcon } from "../../assets/images/mechanical-component.svg";
import { ReactComponent as RoadsideIcon } from "../../assets/images/roadside-component.svg";

import {
    bodyWarranty,
    comfortWarranty,
    electricalWarranty,
    mechanicalWarranty,
    roadsideWarranty,
} from "../../data";
import "./warrantyComponent.scss";

const { Panel } = Collapse;

const Parts = ({ part }) => {
  return (
    <>
      <div className="component-covered-item">
        <div className="item-img">
          <img src={part.partsImg} alt="img" className="img-fluid" />
        </div>
        <p className="fs-18 fw-800 text-dark3">{part.name}</p>
      </div>
    </>
  );
};

const WarrantyComponent = () => {
  return (
    <div className="component-covered py-80">
      <div className="container">
        <div
          className="text-center mb-50"
        >
          <h1 className="text-dark1 fs-48 mb-15">
            Covered components under
            <span className="fw-800 mt-0"> warranty</span>
          </h1>
          <div className="common-title-pra text-center">
            <p className="text-dark3 fs-18 fw-medium lh-24">
              Nationwide Coverage: You're Protected Anywhere in Australia
            </p>
          </div>
        </div>

        <div
          className="accordionWrapper"
        >
          <Collapse
            className="components-accordion"
            accordion
            expandIconPosition="end"
            bordered={false}
            expandIcon={({ isActive }) => (
              <ArrowIcon rotate={isActive ? 180 : 0} />
            )}
          >
            <Panel extra={<MechenicalIcon />} header="Mechanical" key="1">
              <div
                className="component-covered-wrapper mt-30"
              >
                {mechanicalWarranty.map((part, index) => (
                  <Parts key={index} part={part} />
                ))}
              </div>
            </Panel>
            <Panel
              extra={<ElectricalIcon />}
              header="Electrical"
              key="2"
              className="range-panel"
            >
              <div
                className="component-covered-wrapper mt-30"
              >
                {electricalWarranty.map((part, index) => (
                  <Parts key={index} part={part} />
                ))}
              </div>
            </Panel>
            <Panel extra={<BodyComponent />} header="Body" key="3">
              <div
                className="component-covered-wrapper mt-30"
              >
                {bodyWarranty.map((part, index) => (
                  <Parts key={index} part={part} />
                ))}
              </div>
            </Panel>
            <Panel extra={<ComfortIcon />} header="Comfort" key="4">
              <div
                className="component-covered-wrapper mt-30"
              >
                {comfortWarranty.map((part, index) => (
                  <Parts key={index} part={part} />
                ))}
              </div>
            </Panel>
            <Panel
              extra={<RoadsideIcon />}
              header="Roadside Assistance"
              key="5"
            >
              <div
                className="component-covered-wrapper mt-30"
              >
                {roadsideWarranty.map((part, index) => (
                  <Parts key={index} part={part} />
                ))}
              </div>
            </Panel>
          </Collapse>
        </div>
      </div>
    </div>
  );
};

export default WarrantyComponent;
