import { Modal } from 'antd';
import React from 'react';
import ConfirmationTick from '../../assets/images/confirmation-tick.svg';
import './confirmationModal.scss';

const ConfirmationModal = ({isOpen, setIsOpen}) => {

    
    const handleOk = () => {
        setIsOpen(false);
    };
    const handleCancel = () => {
        setIsOpen(false);
    };

  return (
      <>
          <Modal
              centered
              open={isOpen}
              onOk={handleOk}
              onCancel={handleCancel}
              footer={[
                      <button className="full-width-btn fs-16 bg-clr-blue fw-bold text-white rounded w-100" onClick={handleCancel}>
                          Close
                      </button>
              ]}
              width={720}
              className="confirm-modal"
          >
              <div className="content">
                  <div>
                      <img src={ConfirmationTick} alt="img" className="img-fluid" />
                  </div>
                  <h3 className="fs-24 text-dark1 fw-800">Thank you for choosing a vehicle from CARBARN. </h3>
              </div>
          </Modal>
      </>
  );
};

export default ConfirmationModal;