import { Col } from 'antd';
import React from 'react';
import './blogItem.scss';
import { Link } from 'react-router-dom';

const BlogItem = ({blog }) => {

     const handleLinkClick = () => {
         window.scrollTo(0, 0);
     };


    return (
        <Col xs={{ span: 24 }} sm={{ span: 12 }} lg={{ span: 8 }}>
            <div className="blog-item">
                <div className="img-box">
                    <img src={blog?.blogImg} alt="img" className="img-fluid" />
                </div>
                <div className="content-box">
                    <span className="category fs-14">{blog?.category}</span>
                    <Link to={`/blog/${blog?.id}`} className="fs-30 lh-32 text-dark1 fw-800 mb-20 ellipsis-text" onClick={handleLinkClick}>
                        {blog?.title}
                    </Link>
                    <p className="fs-16 text-dark1 lh-24 fw-medium">{blog?.desc}</p>
                </div>
            </div>
        </Col>
    );
};

export default BlogItem;