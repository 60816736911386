import { Col, Row } from "antd";
import React from "react";
import MechenicalBreakdownImg from "../../assets/images/mechanical-breakdown.png";
import "./mechanicalBreakdown.scss";

const MechanicalBreakdown = () => {
  return (
    <section className="mechanical-breakdown pt-100 pb-100">
      <div className="container">
        <Row align="middle" gutter={[50, 30]}>
          <Col xs={{ span: 24 }} lg={{ span: 12 }}>
            <div className="additional-feature-content">
              <div className="waranty-left hybrid-vehical">
                <div className="vehical-content">
                  <p className="vehical-hints fs-12 fw-800 mb-15">
                    Our mechanical breakdown
                  </p>

                  <h1 className="text-dark1 fs-48 mb-15 lh-60">
                    Warranty will <span className="fw-800"> shield </span> your
                    next vehicle from{" "}
                    <span className="fw-800">unforeseen expenses. </span>
                  </h1>

                  <p className="text-dark3 fs-18 fw-medium">
                    Practice mindfulness while behind the wheel, meditative
                    driving
                  </p>
                </div>
              </div>
            </div>
          </Col>
          <Col xs={{ span: 24 }} lg={{ span: 12 }}>
            <div className="additional-feature-image">
              <img
                src={MechenicalBreakdownImg}
                className="img-fluid"
                alt="additional"
              />
            </div>
          </Col>
        </Row>
      </div>
    </section>
  );
};

export default MechanicalBreakdown;
