import React from 'react';
import FeaturedSlider from '../featuredSlider/FeaturedSlider';

const HybridCar = ({ data, loading }) => {

    const hybridCar = data?.filter((item) => item?.fuel?.toLowerCase() === "hybrid");

    return (
        <div>
            {loading && <h5 className="fs-18 mt-50 text-center">Loading</h5>}

            {hybridCar?.length > 0 && !loading && <FeaturedSlider cars={hybridCar} featuredClass="car-slider" />}

            {hybridCar?.length === 0 && !loading && <h5 className="fs-18 mt-50 text-center">No Data Avilable</h5>}
        </div>
    );
};

export default HybridCar;