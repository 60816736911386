import { PaymentElement } from "@stripe/react-stripe-js";
import { Form, Input, Radio, Tooltip } from "antd";
import React, { useState } from "react";
import BankPaymentImg from "../../assets/images/bank-transfer-img.svg";
import GpayImg from "../../assets/images/g-pay-img.svg";
import PaypalImg from "../../assets/images/paypal-img.svg";
import VisaCardImg from "../../assets/images/visa-card-image.svg";
import ToolTipIcon from "../../assets/images/what-icon.svg";
import usePurchase from "../../hooks/usePurchase";
import FinancingCalculator from "../purchaseJourneyItems/FinancingCalculator";
import { DollarCircleOutlined } from "@ant-design/icons";
import { useEffect } from "react";

const PurchaseStep3 = ({ singleDetails, clientSecret, stripe }) => {
  const {
    setFinancing,
    financing,
    distance,
    payment,
    setPayment,
    card,
    setCard,
    setCustomAmount,
    customAmount,
    setIsPaymentElementEmpty
  } = usePurchase();

  const totalPrice =
    parseInt(singleDetails?.salePrice) + Math.ceil(distance / 1000) * 2;

  const fivePercentOfTotal = Math.ceil(0.05 * totalPrice);

  // const [payment, setPayment] = useState(1);
  // const [card, setCard] = useState("Bank Payment");

  // console.log(clientSecret);
  // console.log(stripe);

  
  // useEffect(() => {
  //   const paymentElementCard = document.querySelector('#payment-element');

  //   paymentElementCard.addEventListener('change', (event) => {
  //     setIsPaymentElementEmpty(!event.completed);
  //     console.log(event);
  //   });
  // }, []);

 

  const onChange = (e) => {
    setPayment(e.target.value);
  };

  const handleCardChange = (e) => {
    setCard(e.target.value);
  };

  const handleCustomAmount = (e) => {
    setCustomAmount(e.target.value);
  };

  return (
    <>
      <div className="step-wrapper mb-20">
        {financing && (
          <h5 className="fs-16 text-dark1 fw-800 mb-20">Finance calculator</h5>
        )}
        {!financing && (
          <>
            <div>
              <h5 className="fs-16 text-dark1 fw-800 mb-20">
                Payment Summary{" "}
              </h5>

              <div className="border-gray mb-15"></div>

              <div className="price-summary">
                <div className="price-summary-list">
                  <p>Vehicle Base Price</p>
                  <h6>
                    $
                    {parseInt(singleDetails?.salePrice)?.toLocaleString(
                      "en-AU"
                    )}
                  </h6>
                </div>
                <div className="price-summary-list">
                  <p>Delivery Cost</p>
                  <h6>
                    ${(Math.ceil(distance / 1000) * 2)?.toLocaleString("en-AU")}
                  </h6>
                </div>
              </div>

              <div className="border-gray my-20 "></div>

              <p className="fs-16 mb-30">
                Payment needed on order :{" "}
                <strong className="fs-24">
                  ${totalPrice?.toLocaleString("en-AU")}
                </strong>
              </p>
            </div>

            <div className="payment-card">
              <h5 className="fs-16 text-dark1 fw-800 mb-20">Payment Methods</h5>

              <Radio.Group
                name="radiogroup"
                className="pay-item-wrapper"
                defaultValue="Bank Payment"
                onChange={handleCardChange}
              >
                <>
                  <Radio className="pay-wrapper" value="Bank Payment">
                    <img
                      src={BankPaymentImg}
                      className="img-fluid"
                      alt="card"
                    />
                  </Radio>
                  <Radio className="pay-wrapper" value="Visa Card Payment">
                    <img src={VisaCardImg} className="img-fluid" alt="card" />
                  </Radio>
                </>
                {payment === 2 && (
                  <>
                    <Radio className="pay-wrapper" value="Gpay Payment">
                      <img src={GpayImg} className="img-fluid" alt="card" />
                    </Radio>
                    <Radio className="pay-wrapper" value="Paypal Paymrnt">
                      <img src={PaypalImg} className="img-fluid" alt="card" />
                    </Radio>
                  </>
                )}
              </Radio.Group>

              <div className="payment-info mt-40">
                <Radio.Group onChange={onChange} defaultValue={1}>
                  <Radio value={1}>
                    <p className="mb-0 fs-18 fw-medium text-dark3">
                      Full Payment
                      <span className="fw-bold">
                        {" "}
                        ${totalPrice?.toLocaleString("en-AU")}
                      </span>
                      <span className="mx-10">
                        <Tooltip
                          className="payment-tooltip"
                          color="#000"
                          placement="right"
                          title="Your order will be processed once the amount is arrived at our bank."
                        >
                          <img
                            src={ToolTipIcon}
                            alt="icon"
                            className="img-fluid"
                          />
                        </Tooltip>
                      </span>
                    </p>
                  </Radio>
                  <Radio value={2}>
                    {" "}
                    <p className="mb-0 fs-18 fw-medium text-dark3">
                      5% Payment to hold the vehicle for 72 business hours{" "}
                      <span className="fw-bold">
                        ${fivePercentOfTotal?.toLocaleString("en-AU")}
                      </span>
                    </p>
                  </Radio>
                  <Radio value={3}>
                    {" "}
                    <p className="mb-0 fs-18 fw-medium text-dark3">
                      Custom Payment{" "}
                      {/* <span className="fw-bold">
                        ${fivePercentOfTotal?.toLocaleString("en-AU")}
                      </span> */}
                      <span className="payment-tooltip mx-10">
                        <Tooltip
                          color="#000"
                          placement="right"
                          title="Payment amount must be equal or greater than 5% of total price."
                        >
                          <img
                            src={ToolTipIcon}
                            alt="icon"
                            className="img-fluid"
                          />
                        </Tooltip>
                      </span>
                    </p>
                  </Radio>
                </Radio.Group>

                <>
                  {payment === 3 && (
                    <Form.Item
                      rules={[
                        {
                          required: true,
                          message: "Please input your Last Name!",
                        },
                      ]}
                      style={{ maxWidth: "400px" }}
                    >
                      <Input
                        prefix={<DollarCircleOutlined />}
                        placeholder="Plase enter your preferred payment amount"
                        type="number"
                        name="cumtomAmount"
                        value={customAmount}
                        onChange={handleCustomAmount}
                        required
                      />
                    </Form.Item>
                  )}

                  {payment === 3 && customAmount !== "" && <p className={`${customAmount < fivePercentOfTotal ? "text-red" : "text-green"} `}>Payment amount must be equal or greater than 5% of total price.</p>}
                </>
              </div>

              <div className="my-20">
                {stripe && clientSecret && card === "Visa Card Payment" && (
                  <PaymentElement id="payment-element" onChange={(event) => setIsPaymentElementEmpty(event.empty)} />
                )}
              </div>

              {card === "Bank Payment" && (
                <div className="my-20">
                  <h6 className="fs-16 text-dark1 fw-semi-bold mb-4">
                    Bank Info
                  </h6>
                  <p className="fs-16 text-dark3">
                    Bank of Australia <br /> IBAN 0232 1212 1212 1212 1212 -
                    Carbarn Inc.
                  </p>
                </div>
              )}

              {/* <div className="payment-btn-wrapper mt-30">
                <Button type="button" className="change">
                  Change
                </Button>
                <Button type="button" className="continue">
                  Continue
                </Button>
              </div> */}
            </div>
          </>
        )}

        {financing && <FinancingCalculator />}
      </div>
    </>
  );
};

export default PurchaseStep3;
