
export const phoneNumberMask = (value) => {
      // Remove all non-numeric characters
      const cleanedValue = value.replace(/[^\d]/g, '');

      // Format the phone number
      if (cleanedValue.length <= 3) {
        return cleanedValue;
      }
      if (cleanedValue.length <= 6) {
        return `(${cleanedValue.slice(0, 3)}) ${cleanedValue.slice(3)}`;
      }
      return `(${cleanedValue.slice(0, 3)}) ${cleanedValue.slice(3, 6)}-${cleanedValue.slice(6, 9)}`;
}