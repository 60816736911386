import { Spin } from "antd";
import React from "react";
import FeaturedSlider from "../featuredSlider/FeaturedSlider";

const AllCar = ({ data, loading }) => {
  return (
    <div>
      {loading && (
        <div className="text-center mt-30">
          <Spin />
        </div>
      )}
      {data?.length > 0 && !loading && (
        <FeaturedSlider cars={data} featuredClass="car-slider" />
      )}
      {data?.length === 0 && !loading && (
        <h5 className="fs-18 mt-50 text-center">No Data Avilable</h5>
      )}
    </div>
  );
};

export default AllCar;
