import { Button } from 'antd';
import { Link } from 'react-router-dom';
import './distanceRate.scss';

const DistanceRateCard = () => {
  return (
      <div className="distance-card mb-15">
          <div className="duration">
              <p>
                  <strong className="duration-title mb-15">Kilometer :</strong>
                  0KM - 50KM
              </p>
              <p>
                  <strong className="duration-title">Days :</strong>
                  1-4 Days
              </p>
          </div>
          <div className="rate">
              <p>Rate AUD</p>
              <h4>$80</h4>
          </div>
          <div className="action-btn">
              <Button shape="round" className="upload-btn">
                  Edit
              </Button>
          </div>
      </div>
  )
}

const DistanceRate = () => {
  return (
      <div className="distance-rate tex-center">
          <p className="text-center mb-15 mt-30">Active Shipping rates based on distance (Kilometer)</p>
          <DistanceRateCard />
          <DistanceRateCard />
          <DistanceRateCard />
          <DistanceRateCard />
          <DistanceRateCard />

          
          <div className='mt-20'>
          <Link className="yes-no-btn" style={{maxWidth:"150px", margin:"0 auto"}} >Add</Link>
          </div>
          
      </div>
  )
};

export default DistanceRate;