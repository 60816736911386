import React, { useState } from "react";
import "./purchaseOptions.scss";
import { Radio } from "antd";
import { Link, useNavigate } from "react-router-dom";
import usePurchase from "../../hooks/usePurchase";
import useWishList from "../../hooks/useWishList";
import PurchaseOptionModal from "../purchaseJourneyItems/PurchaseOptionModal";

const PurchaseOptions = ({ singleDetails }) => {
  
  const { formData, setFormData } = usePurchase();
  const { wishlist, setWishlist } = useWishList();
  const navigate = useNavigate();

  const isWished = wishlist?.find((item) => item?.id === singleDetails?.id);

  const handleAddToWishlist = () => {
    setWishlist([...wishlist, singleDetails]);
  };

  const handleRemoveFromWishlist = () => {
    const updatedWishlist = wishlist?.filter(
      (item) => item.id !== singleDetails?.id
    );
    setWishlist(updatedWishlist);
  };

  const onChange = (e) => {
    console.log("radio checked", e.target.value);
    setFormData({
      deliveryOption: e.target.value,
    });
  };

  const handlePurchase = () => {
    navigate(
      `/${singleDetails?.make}/${singleDetails?.trackId}/purchase-journey`
    );
  }

  return (
    <div className="purchase-options-wrapper">
      <div className="btn-wrapper">
        <button
          className={`purchanse-btn ${
            (singleDetails?.isOnOffer || singleDetails?.soldStatus === "Sold") && "disabled-btn"
          }`}
          onClick={handlePurchase}
          disabled={singleDetails?.isOnOffer || singleDetails?.soldStatus === "Sold"}
        >
          <span>Begin Purchase</span>
          <span>
            <svg
              width="40"
              height="40"
              viewBox="0 0 40 40"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M22.5837 28.375C22.3337 28.125 22.2087 27.8406 22.2087 27.5217C22.2087 27.2017 22.3337 26.9306 22.5837 26.7083L28.1253 21.1667H8.58366C8.25033 21.1667 7.97255 21.0556 7.75033 20.8333C7.5281 20.6111 7.41699 20.3333 7.41699 20C7.41699 19.6667 7.5281 19.3889 7.75033 19.1667C7.97255 18.9444 8.25033 18.8333 8.58366 18.8333H28.1253L22.542 13.25C22.3198 13.0278 22.2087 12.7639 22.2087 12.4583C22.2087 12.1528 22.3337 11.875 22.5837 11.625C22.8059 11.375 23.0698 11.25 23.3753 11.25C23.6809 11.25 23.9587 11.375 24.2087 11.625L31.6253 19C31.7642 19.1389 31.8687 19.2917 31.9387 19.4583C32.0075 19.625 32.042 19.8056 32.042 20C32.042 20.1944 32.0075 20.375 31.9387 20.5417C31.8687 20.7083 31.7642 20.8611 31.6253 21L24.167 28.4167C23.9725 28.6389 23.7159 28.75 23.397 28.75C23.077 28.75 22.8059 28.625 22.5837 28.375Z"
                fill="white"
              />
            </svg>
          </span>
        </button>
        <button
          className="wish-list-btn"
          onClick={isWished ? handleRemoveFromWishlist : handleAddToWishlist}
        >
          <svg
            width="44"
            height="44"
            viewBox="0 0 44 44"
            fill={isWished ? "#3078FF" : "none"}
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M30.3951 7C32.8062 7 34.8005 7.80767 36.4127 9.41978C38.0237 11.0315 38.8326 13.0407 38.8326 15.4837C38.8326 18.6032 37.5535 21.6368 34.9263 24.5929C32.2503 27.6031 29.2072 30.6768 25.7961 33.8139L25.7941 33.8157L23.5067 35.8745L23.4981 35.8826C23.0377 36.3173 22.5176 36.525 21.9163 36.525C21.3073 36.525 20.7847 36.3266 20.3266 35.9203L18.0385 33.8157L18.0373 33.8146C14.5033 30.5854 11.4304 27.4819 8.81715 24.504C6.25045 21.5784 5 18.5745 5 15.4837C5 13.0407 5.8089 11.0315 7.41993 9.41978C9.03208 7.80767 11.0264 7 13.4375 7C14.7877 7 16.1808 7.33711 17.6213 8.02772C19.0195 8.69839 20.3174 9.87485 21.5047 11.5964L21.9016 12.1719L22.318 11.6103C23.6019 9.87856 24.9206 8.69629 26.2649 8.02412C27.638 7.33756 29.0132 7 30.3951 7Z"
              stroke={isWished ? "#3078FF" : "#ddd"}
              stroke-width="1.5"
            />
          </svg>
        </button>
      </div>
      {/* <div className="option-wrapper mt-30" style={{ textAlign: "left" }}>
        <Radio.Group
          onChange={onChange}
          value={formData.deliveryOption}
          size="large"
        >
          <Radio
            className="text-dark3 fs-12 fw-semi-bold"
            value="door delivery"
            checked
          >
            <p className="flex-between-center" style={{ gap: "5px" }}>
              I want door delivery.
              <PurchaseOptionModal
                btnText="See pricing"
                title="Distance wise delivery charges"
                priceing={true}
              />
            </p>
          </Radio>
          <Radio className="text-dark3 fs-12 fw-semi-bold" value="self pickup">
            <p className="flex-between-center" style={{ gap: "5px" }}>
              I will pick up myself.
              <PurchaseOptionModal
                btnText="See Details"
                title="Shop Location"
                details={true}
              />
            </p>
          </Radio>
        </Radio.Group>
      </div> */}
    </div>
  );
};

export default PurchaseOptions;
