import { Tabs } from "antd";
import React from "react";
import useFetch from "../../hooks/useFetch";
import AllCar from "../featuredCarList/AllCar";
import Car from "../featuredCarList/Car";
import DeselCar from "../featuredCarList/DeselCar";
import HybridCar from "../featuredCarList/HybridCar";
import VanCar from "../featuredCarList/VanCar";
import "./featuredListing.scss";
import useFilter from "../../hooks/useFilter";

const FeaturedListing = ({ featuredListingTitle, featuredListingSubTitle }) => {

    // const { data, loading } = useFetch(
    //   "https://script.googleusercontent.com/macros/echo?user_content_key=G81yzipWxD20wKM09WQj7wT189l7wdDalWZIhmnhsXnXclxKqX-eC37ibJJ0rFRAtGX8rYMPqiYBmNUQHOkCtQeBxkkb1oJmm5_BxDlH2jW0nuo2oDemN9CCS2h10ox_1xSncGQajx_ryfhECjZEnJ20icJtulfQ-Xh9ZIXrtsHgKA74npwlKWkRu1WQztiile3GZlfodcYf53NCPWPsiUWwn2VZmBBbUqviuy2pxh7fvzGTtB5mrQ&lib=Mzz5z-UBdLForWa5xcURfCU8jPqgvzQIJ"
    // );

    // const newData = data?.map(item=>item)

    const {newData} = useFilter();

    // console.log(newData);

    return (
      <section className="feature-listing py-80 pb-40">
        <div className="container">
          <div className="text-center">
            <h1
              className="text-dark1 fs-48 mb-15"
              dangerouslySetInnerHTML={{ __html: featuredListingTitle }}
            ></h1>
            <div className="common-title-pra">
              <p
                className="text-dark3 fs-18 fw-medium"
                dangerouslySetInnerHTML={{ __html: featuredListingSubTitle }}
              ></p>
            </div>
          </div>

          <Tabs centered className="featured-tab mt-50">
            <Tabs.TabPane tab="All" key="all">
              <AllCar data={newData}  />
            </Tabs.TabPane>
            <Tabs.TabPane tab="4WD" key="4wd">
              <Car data={newData}  />
            </Tabs.TabPane>
            <Tabs.TabPane tab="Diesel" key="diesel">
              <DeselCar data={newData}  />
            </Tabs.TabPane>
            <Tabs.TabPane tab="Hybrid" key="hybrid">
              <HybridCar data={newData}  />
            </Tabs.TabPane>
            <Tabs.TabPane tab="Van" key="van">
              <VanCar data={newData} />
            </Tabs.TabPane>
            {/* <Tabs.TabPane tab="Motorhome" key="motorhome">
              <MotorHomeCar data={data} loading={loading} />
            </Tabs.TabPane> */}
          </Tabs>
        </div>
      </section>
    );
};

export default FeaturedListing;
