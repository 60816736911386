import { RightOutlined } from "@ant-design/icons";
import { Breadcrumb } from "antd";
import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import useFilter from "../../hooks/useFilter";

const DynamicBreadcrumb = ({ dynamicRoute, singleDetails }) => {
  const [breadcrumbPath, setBreadcrumbPath] = useState([]);

  useEffect(() => {
    setBreadcrumbPath(["Stock list", dynamicRoute]);
  }, [dynamicRoute]);


  const { setMake, setModel, filteredData, makeOptions, modelOptions } = useFilter();

  const handleFilterMake = () => {
    setMake(singleDetails?.make);
    setModel('');
  }

  const handleFilterModel = () => {
    setMake('');
    setModel(singleDetails?.model);
  }


  return (
    // <Breadcrumb
    //   data-aos="fade-up"
    //   data-aos-offset="300"
    //   data-aos-easing="ease-in-sine"
    //   data-aos-duration="1000"

    //   separator=<RightOutlined />
    //   itemRender={(route, params, routes, paths) => {
    //     const last = routes.indexOf(route) === routes.length - 1;
    //     return last ? (
    //       <span>{route.breadcrumbName}</span>
    //     ) : (
    //       <a href={route.path}>{route.breadcrumbName}</a>
    //     );
    //   }}
    // >
    //   {breadcrumbPath.map((route, index) => (
    //     <Breadcrumb.Item key={index}>{route} </Breadcrumb.Item>
    //   ))}
    // </Breadcrumb>


    <>
      <div className="car-details-bredcrumb">
        <p className="text-dark3 fw-bold">Used Car</p>
        <svg
          width="16"
          height="16"
          viewBox="0 0 16 16"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M5.94043 13.28L10.2871 8.9333C10.8004 8.41997 10.8004 7.57997 10.2871 7.06664L5.94043 2.71997"
            stroke="#525355"
            stroke-width="1.5"
            stroke-miterlimit="10"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
        </svg>
        <Link to="/view-cars" className="text-decorate-none text-dark3 fw-bold text-capitalize" onClick={handleFilterMake}> {singleDetails?.make}</Link>
        <svg
          width="16"
          height="16"
          viewBox="0 0 16 16"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M5.94043 13.28L10.2871 8.9333C10.8004 8.41997 10.8004 7.57997 10.2871 7.06664L5.94043 2.71997"
            stroke="#525355"
            stroke-width="1.5"
            stroke-miterlimit="10"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
        </svg>
        <Link to="/view-cars" className="text-decorate-none text-blue fw-bold" onClick={handleFilterModel}>{singleDetails?.model}</Link>
        {/* {singleDetails?.stockNo && (
          <>
            <svg
              width="16"
              height="16"
              viewBox="0 0 16 16"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M5.94043 13.28L10.2871 8.9333C10.8004 8.41997 10.8004 7.57997 10.2871 7.06664L5.94043 2.71997"
                stroke="#525355"
                stroke-width="1.5"
                stroke-miterlimit="10"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>
            <sapn>{singleDetails?.stockNo}</sapn>
          </>
        )} */}
      </div>
    </>
  );
};

export default DynamicBreadcrumb;
