import React from "react";
import { Link } from "react-router-dom";
import GearIcon from "../../assets/images/gear-stick.svg";
import MeterIcon from "../../assets/images/km.svg";
import FireIcon from "../../assets/images/local_fire.svg";

import { Badge, Space } from "antd";
import Slider from "react-slick";
import SwiperCore, { Autoplay, EffectCoverflow, FreeMode, Mousewheel, Navigation, Pagination } from "swiper";
import "swiper/swiper-bundle.min.css";
import useWishList from "../../hooks/useWishList";
import "./featuredSlider.scss";
import StockListItem from "../stockListItem/StockListItem";

SwiperCore.use([Navigation, Pagination, Mousewheel, Autoplay, EffectCoverflow, FreeMode]);

const FeaturedSlider = ({ cars, filteredData, featuredClass }) => {

    const { wishlist, setWishlist, viewItems, setViewItems } = useWishList();

  const data = filteredData?.length > 0 ? filteredData : cars;


    const slideItem = data?.map((car, index) => {

        // const images = car?.vehiclePhotos?.split("|");

        //   const isWished = wishlist?.find((item) => item?.trackId === car?.trackId);

        //   const handleAddToWishlist = () => {
        //       setWishlist([...wishlist, car]);
        //   };

        //   const handleRemoveFromWishlist = () => {
        //       const updatedWishlist = wishlist.filter((item) => item.trackId !== car?.trackId);
        //       setWishlist(updatedWishlist);
        //   };

        //   const handleLinkClick = () => {
        //       window.scrollTo(0, 0);
        //       setViewItems([...viewItems, car]);
        //   };


        return (
          <div key={index}>
            <StockListItem car={car}/>
          </div>
        );
    });

    const settings = {
        className: "center",
        centerMode: true,
        infinite: slideItem?.length > 4 ? true : false,
        // centerPadding: "60px",
        margin:30,
        slidesToShow: 4,
        speed: 500,

        responsive: [
            {
                breakpoint: 1230,
                settings: {
                    slidesToShow: 3,
                },
            },
            {
                breakpoint: 960,
                settings: {
                    slidesToShow: 2,
                },
            },
            {
                breakpoint: 768,
                settings: {
                    centerMode: false,
                    slidesToShow: 2,
                },
            },
            {
                breakpoint: 576,
                settings: {
                    centerMode: false,
                    slidesToShow: 1,
                },
            },
        ],

        prevArrow: (
            <button className="slick-prev">
                <svg width="19" height="10" viewBox="0 0 19 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M4.925 9.7999L6.125 8.6499L3.275 5.7999H19V4.1499H3.275L6.125 1.2999L4.95 0.149902L0.1 4.9749L4.925 9.7999Z" fill="#0473EA" />
                </svg>
            </button>
        ),
        nextArrow: (
            <button className="slick-next">
                <svg width="19" height="10" viewBox="0 0 19 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M14.075 9.7999L12.875 8.6499L15.725 5.7999H0V4.1499H15.725L12.875 1.2999L14.05 0.149902L18.9 4.9749L14.075 9.7999Z" fill="#0473EA" />
                </svg>
            </button>
        ),
    };

    return (
        <div>
            <Slider {...settings} className={`featured-slider ${featuredClass}`}>
                {slideItem}
            </Slider>
        </div>
    );
};

export default FeaturedSlider;
