import { Table } from "antd";
import React, { useState } from "react";

import { Link } from "react-router-dom";
import carImage from "../../assets/images/carImage.jpg";
import useFilter from "../../hooks/useFilter";

const PurchaseHistoryTable = ({ userSalesData }) => {

  const { newData } = useFilter();

  const columns = [
    {
      title: "Name",
      dataIndex: "name",
    },
    {
      title: "Payment",
      dataIndex: "PaymentMethod",
    },
    {
      title: "Due Amount",
      dataIndex: "dueAmount",
    },
    {
      title: "Download documents ",
      dataIndex: "DownloadDocuments",
    },
  ];

  const data = userSalesData?.map((item) => {
    const matchedVehicleData = newData?.find(
      (vd) => item?.chassisId === vd?.trackId
    );

    return {
      key: item?.id,
      name: (
        <>
          <div className="products">
            <img
              src={matchedVehicleData?.exteriorPhoto?.[0] || carImage}
              alt="img"
              className="img-fluid radius-12"
              width="100px"
            />
            <div className="product-info">
              <h4 className="fs-16 text-dark1 fw-800 mb-10">
                {matchedVehicleData?.title || "N/A"}
              </h4>
             { matchedVehicleData?.salePrice && <h4 className="fs-16 text-dark1 fw-800 mb-10">
                ${matchedVehicleData?.salePrice || "N/A"}
              </h4>}
              <p className="fs-12 text-dark3 fw-medium">
                {matchedVehicleData?.variant || "N/A"}
              </p>
            </div>
          </div>
        </>
      ),
      PaymentMethod: (
        <>
          {item?.payments?.map((payment, index) => (
            <div
              className=""
              style={{
                border: "1px solid #ddd",
                borderRadius: "12px",
                padding: "10px",
                marginBottom: "10px",
              }}
            >
              <p className="fs-12">
                <strong>Payment NO :</strong> {index + 1}
              </p>
              <p className="fs-12">
                <strong>Payment Method :</strong> Online
              </p>
              <p className="fs-12">
                <strong>Reason :</strong> {payment?.reason?.reason}
              </p>
              <p className="fs-12">
                <strong>Amount :</strong> ${payment?.amount}
              </p>
              {/* <p className="fs-12">
                <strong>Reference Number :</strong> {payment?.referenceNumber}
              </p> */}
              <p className="fs-12">
                <strong>Date :</strong> {payment?.paymentDateTime || "N/A"}
              </p>
            </div>
          ))}
        </>
      ),
      dueAmount: `$${item?.dueAmount}`,
      DownloadDocuments: (
        <>
          <div className="download-link">
            <Link
              className="upload-btn fs-14 fw-bold text-blue d-em-block mb-10"
              to={`https://www.cbs.s1.importautos.com.au/carbarn/v1/api/sales/report/taxinvoice/${item?.id}`}
              download="Download Invoice"
              target="_blank"
              rel="noreferrer"
              // style={{ borderRadius: "100px" }}
            >
              Tax Invoice
            </Link>
            <Link
              className="fs-14 fw-bold text-blue d-em-block"
              to={`https://www.cbs.s1.importautos.com.au/carbarn/v1/api/sales/report/signedform5/${item?.id}`}
              download="Download Form Five"
              target="_blank"
              rel="noreferrer"
              // style={{ borderRadius: "100px" }}
            >
              Form 5
            </Link>
          </div>
        </>
      ),
    };
  });

  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const onSelectChange = (newSelectedRowKeys) => {
    console.log("selectedRowKeys changed: ", newSelectedRowKeys);
    setSelectedRowKeys(newSelectedRowKeys);
  };
  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
  };
  const hasSelected = selectedRowKeys.length > 0;
  return (
    <div>
      <div
        style={{
          marginBottom: 16,
        }}
      >
        <span
          style={{
            marginLeft: 8,
          }}
        >
          {hasSelected ? `Selected ${selectedRowKeys.length} items` : ""}
        </span>
      </div>
      <Table columns={columns} dataSource={data} />
    </div>
  );
};

export default PurchaseHistoryTable;
