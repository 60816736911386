import React from 'react';

import { Col, Row } from 'antd';
import FeatureIcon1 from "../../assets/images/warranty-feature-1.svg";
import FeatureIcon2 from "../../assets/images/warranty-feature-2.svg";
import FeatureIcon3 from "../../assets/images/warranty-feature-3.svg";
import FeatureIcon4 from "../../assets/images/warranty-feature-4.svg";

const WarrantyFeature = () => {

  const featureData = [
      {
          img: FeatureIcon1,
          title: "Transferable for next owner",
          desc: "A transferable warranty can help boost the resale value of your vehicle if you decide to sell",
      },

      {
          img: FeatureIcon2,
          title: "24/7 Roadside Assistance",
          desc: "Enjoy a stress-free driving experience every time you use your vehicle for transportation",
      },

      {
          img: FeatureIcon3,
          title: "Featuring a $3000 maximum claim value",
          desc: "Total Claim Limit up to the Purchase value of the vehicle",
      },

      {
          img: FeatureIcon4,
          title: "Nationwide coverage",
          desc: "No matter where you travel in Australia, our coverage has got you covered",
      },
  ];

  return (
      <section className="banner-bottom border-0 py-4">
          <div className="container">
              <Row gutter={[24, 10]} justify="space-between">
                  {featureData.map((feature, index) => {
                      return (
                        <Col
                          xs={{ span: 24 }}
                          sm={{ span: 12 }}
                          lg={{ span: 6 }}
                          key={index}
                        >
                          <div
                            className="banner-bottom-content feature-content"
                            data-aos="fade-up"
                            data-aos-easing="ease-in-sine"
                            data-aos-duration="1000"
                          >
                            <div className="img-box">
                              <img
                                src={feature.img}
                                className="img-fluid mb-10"
                                alt="img"
                                width={105}
                              />
                            </div>
                            <div className='content-wrapper'>
                            <h4 className="fs-18 fw-800 text-dark1 mb-10">
                              {feature.title}
                            </h4>
                            <p className="fs-14 text-dark3 lh-24">
                              {feature.desc}
                            </p>
                            </div>
                          </div>
                        </Col>
                      );
                  })}
              </Row>
          </div>
      </section>
  );
};

export default WarrantyFeature;