import { Tabs } from "antd";
import './deliveryCharges.scss'
import DistanceRate from "../../components/distanceRate/DistanceRate";


const DeliveryCharges = () => {

  const onChange = (key) => {
      console.log(key);
  };
  const items = [
      {
          key: "1",
          label: `Distance wise rates`,
          children: <DistanceRate />,
      },
      {
          key: "2",
          label: `Undeliverable area`,
          children: `Content Not Available`,
      },
  ];


  return (
      <div className="delivery-charges">
          <Tabs
              className="delivery-charges-tab"
              defaultActiveKey="1"
              items={items.map((item, i) => {

                  const id = String(i + 1);

                  return {
                      label: (
                          <span className="tabs-lebel">
                              {item.label}
                              <svg width="16" height="12" viewBox="0 0 16 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                                  <path
                                      d="M10.0492 11.025C9.89922 10.875 9.82422 10.7043 9.82422 10.513C9.82422 10.321 9.89922 10.1583 10.0492 10.025L13.3742 6.7H1.64922C1.44922 6.7 1.28255 6.63333 1.14922 6.5C1.01589 6.36667 0.949219 6.2 0.949219 6C0.949219 5.8 1.01589 5.63333 1.14922 5.5C1.28255 5.36667 1.44922 5.3 1.64922 5.3H13.3742L10.0242 1.95C9.89089 1.81667 9.82422 1.65833 9.82422 1.475C9.82422 1.29167 9.89922 1.125 10.0492 0.975C10.1826 0.825 10.3409 0.75 10.5242 0.75C10.7076 0.75 10.8742 0.825 11.0242 0.975L15.4742 5.4C15.5576 5.48333 15.6202 5.575 15.6622 5.675C15.7036 5.775 15.7242 5.88333 15.7242 6C15.7242 6.11667 15.7036 6.225 15.6622 6.325C15.6202 6.425 15.5576 6.51667 15.4742 6.6L10.9992 11.05C10.8826 11.1833 10.7286 11.25 10.5372 11.25C10.3452 11.25 10.1826 11.175 10.0492 11.025Z"
                                      fill="#0C3A66"
                                  />
                              </svg>
                          </span>
                      ),
                      key: id,
                      children: <div>{item.children}</div>,
                  };
              })}
              onChange={onChange}
          />
          
      </div>
  );
};

export default DeliveryCharges;