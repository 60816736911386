import React from 'react';
import BudgetCar from '../../components/budgetCar/BudgetCar';
import CallToAction from '../../components/callToAction/CallToAction';
import FeaturedListing from '../../components/featuredListing/FeaturedListing';
import HomeBanner from '../../components/homeBanner/HomeBanner';
import HomeFeature from '../../components/homeFeature/HomeFeature';
import WhyCarbarn from '../../components/whyCarbarn/WhyCarbarn';
import WhyHybrid from '../../components/whyHybrid/WhyHybrid';
import useFilter from '../../hooks/useFilter';

const Home = () => {

    const { setMake, setModel, filteredData, makeOptions, modelOptions } = useFilter();
    
    console.log(filteredData);

    const featuredListingTitle = `<b>Featured</b> listing`;
    const featuredListingSubTitle = `Vehicles come with a 5-Years Warranty, 24/7 Roadside Assistance, No Reimbursement Wait`;


    return (
        <>
            <HomeBanner setMake={setMake} setModel={setModel} filteredData={filteredData} makeOptions={makeOptions} modelOptions={modelOptions} />
            <HomeFeature />
            <FeaturedListing featuredListingTitle={featuredListingTitle} featuredListingSubTitle={featuredListingSubTitle} />
            <WhyCarbarn />
            <BudgetCar />
            <WhyHybrid />
            <CallToAction />
        </>
    );
};

export default Home;