import { Tabs } from 'antd';
import React from 'react';
import { Link } from 'react-router-dom';
import useFilter from '../../hooks/useFilter';
import OverFourty from '../budgetCarList/OverFourty';
import ThirtyToFourty from '../budgetCarList/ThirtyToFourty';
import TweentyToThirty from '../budgetCarList/TweentyToThirty';
import UnderTweenty from '../budgetCarList/UnderTweenty';

const BudgetCar = () => {
    

    const {newData} = useFilter();


  return (
      <section className="budget pt-100 pb-100">
          <div className="container">
              <div className="text-center">
                  <h1 className="text-dark1 fs-48 mb-15 ">
                      Price Matched Vehicles for Your
                      <span className="fw-800"> Budget? </span>
                  </h1>
                  <div className="common-title-pra">
                      <p className="text-dark3 fs-18 fw-medium">High-Quality Imported Vehicles at Prices You'll Love</p>
                  </div>
              </div>

              <Tabs centered className="featured-tab budget-car-tab mt-50">
                  <Tabs.TabPane tab="Under $20K" key="under20">
                      <UnderTweenty data={newData}  />
                  </Tabs.TabPane>
                  <Tabs.TabPane tab="$20K-30K" key="20To30">
                      <TweentyToThirty data={newData}  />
                  </Tabs.TabPane>
                  <Tabs.TabPane tab="$30K-40K" key="30To40">
                      <ThirtyToFourty data={newData}  />
                  </Tabs.TabPane>
                  <Tabs.TabPane tab="Over $40K" key="over40">
                      <OverFourty data={newData}  />
                  </Tabs.TabPane>
              </Tabs>

              { <div className="text-center">
                  <div className="common-fill-btn">
                      <Link to="/stock" className="fs-16 bg-clr-blue fw-bold text-white rounded fill-btn">
                          Browse all cars under $20K
                      </Link>
                  </div>
              </div>}
          </div>
      </section>
  );
};

export default BudgetCar;