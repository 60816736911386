import { Col, Row } from "antd";
import React from "react";
import RightTickImg from "../../assets/images/Subtract.png";
import AdditionalFeatureImg from "../../assets/images/additional-feature.png";

import { additionalFeature } from "../../data";
import "./additionalFeature.scss";

const AdditionalFeature = () => {
  return (
    <section className="additional-feature pb-100">
      <div className="container">
        <Row align="middle" gutter={[30, 30]}>
          <Col xs={{ span: 24 }} lg={{ span: 12 }}>
            <div className="additional-feature-content">
              <div className="waranty-left hybrid-vehical">
                <div className="vehical-content ">
                  <p className="vehical-hints fs-12 fw-800 mb-15">
                    All of our vehicles
                  </p>
                  <h1 className="text-dark1 fs-48 mb-30 lh-60">
                    Additional feature of 5 years of{" "}
                    <span className="fw-800"> 24/7 </span> hours{" "}
                    <span className="fw-800"> roadside assistance </span>{" "}
                    provided by AWN
                  </h1>
                  <p className="text-dark3 fs-18 fw-medium mb-50">
                    Stay covered on the road with  years of 24/7 roadside
                    assistance from AWN
                  </p>
                  <ul className="feature-point-wrapper">
                    {additionalFeature.map((item, index) => {
                      return (
                        <li className="feature-point" key={index}>
                          <img
                            src={RightTickImg}
                            className="img-fluid mt-8"
                            alt="icon"
                          />
                          <p className="fs-16 fw-medium text-dark3">
                            {item.title}
                          </p>
                        </li>
                      );
                    })}
                  </ul>
                </div>
              </div>
            </div>
          </Col>
          <Col xs={{ span: 24 }} lg={{ span: 12 }}>
            <div className="additional-feature-image">
              <img
                src={AdditionalFeatureImg}
                className="img-fluid"
                alt="additional"
              />
            </div>
          </Col>
        </Row>
      </div>
    </section>
  );
};

export default AdditionalFeature;
