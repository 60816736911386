import { Col, Row } from 'antd';
import React from 'react';
import useWishList from '../../hooks/useWishList';
import StockListItem from '../../components/stockListItem/StockListItem';

const RecentlyViewed = () => {

    const {recentViewedItems} = useWishList();

    return (
      <div className="stocklist pt-100">
        <div className="container">
          <div className="stocklist-wrapper pb-5 text-center">
            <h1 className="text-dark1 fs-36 mb-50">
              My <span className="fw-800">Recently</span> viewed cars.
            </h1>
            <Row justify="center" align="middle" gutter={[24, 24]}>
              {recentViewedItems?.length > 0 ? (
                recentViewedItems?.map((car, index) => (
                  <Col
                      xs={{ span: 24 }}
                      md={{ span: 12 }}
                      lg={{ span: 8 }}
                      xl={{ span: 6 }}
                      className="stock-column"
                      key={index}
                    >
                      <StockListItem car={car} />
                    </Col>
                ))
              ) : (
                <h5 className="fs-18 text-center">
                  No Cars available
                </h5>
              )}
            </Row>
          </div>
        </div>
      </div>
    );
};

export default RecentlyViewed;