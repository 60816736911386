/* eslint-disable react-hooks/exhaustive-deps */

import React, { useEffect, useRef } from 'react';
import { Link, NavLink } from 'react-router-dom';
import Logo from "../../assets/images/carbarn-logo.svg";
import useFilter from '../../hooks/useFilter';
import './sideBar.scss';

const SideBar = () => {

    const { menuOpen, setMenuOpen } = useFilter();

    const sideBarRef = useRef(null);

    const handleMenuItemClick = () => {
        setMenuOpen(false);
        window.scrollTo(0, 0);
    };


    const handleClickOutside = (event) => { 
        if (sideBarRef.current && !sideBarRef.current.contains(event.target)) {
            setMenuOpen(false);
        }
    };

    useEffect(() => {
        document.addEventListener("mousedown", handleClickOutside);

        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, []);


    return (
        <div className={`side-bar ${menuOpen && "active"}`} ref={sideBarRef}>
            <div>
                <div className="close-btn" onClick={()=>setMenuOpen(false)}>
                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path
                            d="M12 22C17.5 22 22 17.5 22 12C22 6.5 17.5 2 12 2C6.5 2 2 6.5 2 12C2 17.5 6.5 22 12 22Z"
                            stroke="#0473ea"
                            strokeWidth="1.5"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                        />
                        <path d="M9.17 14.8299L14.83 9.16992" stroke="#0473ea" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                        <path d="M14.83 14.8299L9.17 9.16992" stroke="#0473ea" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                    </svg>
                </div>
                <div className="logo-panel">
                    <div style={{ width: "175px" }}>
                        <Link to="/">
                            <img src={Logo} alt={Logo} />
                        </Link>
                    </div>
                    {/* <img src={HamburgurIcon} alt={HamburgurIcon} onClick={() => setMenuOpen(!menuOpen)} className="cursor-pointer" /> */}
                </div>
                <div className="main-menu">
                    <ul className="navbar-nav">
                        <li className="nav-item">
                            <NavLink to="/" className="nav-link fw-bold lh-24" aria-current="page" onClick={handleMenuItemClick}>
                                Home
                            </NavLink>
                        </li>
                        <li className="nav-item">
                            <NavLink to="/stock" className="nav-link fw-bold lh-24" onClick={handleMenuItemClick}>
                                Stock List
                            </NavLink>
                        </li>
                        <li className="nav-item">
                            <NavLink to="/warranty" className="nav-link fw-bold lh-24" onClick={handleMenuItemClick}>
                                Warranty
                            </NavLink>
                        </li>
                        <li className="nav-item">
                            <NavLink to="finance" className="nav-link fw-bold lh-24" onClick={handleMenuItemClick}>
                                Car Finance
                            </NavLink>
                        </li>
                        <li className="nav-item">
                            <NavLink to="how-works" className="nav-link fw-bold lh-24" onClick={handleMenuItemClick}>
                                How it Works
                            </NavLink>
                        </li>
                    </ul>
                </div>
            </div>
            <div className="bottom-link">
                <button className="login-btn">Login</button>
            </div>
        </div>
    );
};

export default SideBar;