import { Spin } from "antd";
import React from "react";
import FeaturedSlider from "../featuredSlider/FeaturedSlider";

const UnderTweenty = ({ data, loading }) => {


  const underTweenty = data?.filter(
    (item) => parseInt(item?.salePrice) < 20000
  );


  return (
    <div>
      {loading && (
        <div className="text-center mt-30 mb-20">
          <Spin />
        </div>
      )}

      {underTweenty.length > 0 && !loading && (
        <FeaturedSlider cars={underTweenty} featuredClass="car-slider" />
      )}

      {underTweenty.length === 0 && !loading && (
        <h5 className="fs-18 mt-50 text-center mb-30 w-100">
          No Data Avilable
        </h5>
      )}
    </div>
  );
};

export default UnderTweenty;
