import { Badge, Col, Space } from "antd";
import React from "react";
import { Link, useLocation } from "react-router-dom";
import GearIcon from "../../assets/images/gear-stick.svg";
import MeterIcon from "../../assets/images/km.svg";
import FireIcon from "../../assets/images/local_fire.svg";
import useWishList from "../../hooks/useWishList";

const StockListItem = ({ car }) => {

  const { wishlist, setWishlist, viewItems, setViewItems } = useWishList();


  const isWished = wishlist?.find((item) => item?.trackId === car?.trackId);

  const handleAddToWishlist = () => {
    setWishlist([...wishlist, car]);
  };

  const handleRemoveFromWishlist = () => {
    const updatedWishlist = wishlist?.filter(
      (item) => item?.trackId !== car?.trackId
    );
    setWishlist(updatedWishlist);
  };


  const handleLinkClick = () => {
    window.scrollTo(0, 0);
    setViewItems([...viewItems, car]);
  };

 

  return (
    <div
      className="Product-item"
      data-aos="fade-up"
      data-aos-easing="ease-in-sine"
      data-aos-duration="1000"
    >
      {car?.isOnOffer ? (
        <Badge.Ribbon text="ON-OFFER" color="gold">
          <Link
            to={`/${car?.make}/${car?.stockNo}`}
            className="car-image"
            onClick={handleLinkClick}
          >
            <img
              src={car?.exteriorPhoto?.[0]}
              className="img-fluid"
              alt="img"
            />
            {/* <img src={RotateIcon} className="img-fluid img-rotate" alt="rotate" /> */}
          </Link>
          <div className="car-content">
            <div className="top-content">
              <div className="title">
                <Link
                  to={`/${car?.make}/${car?.stockNo}`}
                  className="text-dark1 fw-medium fs-16 ellipsis-text"
                  onClick={handleLinkClick}
                >
                  {car?.title}
                </Link>
                <div
                  onClick={
                    isWished ? handleRemoveFromWishlist : handleAddToWishlist
                  }
                >
                  <svg
                    width="24"
                    height="24"
                    viewBox="0 0 44 44"
                    fill={isWished ? "#3078FF" : "none"}
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M30.3951 7C32.8062 7 34.8005 7.80767 36.4127 9.41978C38.0237 11.0315 38.8326 13.0407 38.8326 15.4837C38.8326 18.6032 37.5535 21.6368 34.9263 24.5929C32.2503 27.6031 29.2072 30.6768 25.7961 33.8139L25.7941 33.8157L23.5067 35.8745L23.4981 35.8826C23.0377 36.3173 22.5176 36.525 21.9163 36.525C21.3073 36.525 20.7847 36.3266 20.3266 35.9203L18.0385 33.8157L18.0373 33.8146C14.5033 30.5854 11.4304 27.4819 8.81715 24.504C6.25045 21.5784 5 18.5745 5 15.4837C5 13.0407 5.8089 11.0315 7.41993 9.41978C9.03208 7.80767 11.0264 7 13.4375 7C14.7877 7 16.1808 7.33711 17.6213 8.02772C19.0195 8.69839 20.3174 9.87485 21.5047 11.5964L21.9016 12.1719L22.318 11.6103C23.6019 9.87856 24.9206 8.69629 26.2649 8.02412C27.638 7.33756 29.0132 7 30.3951 7Z"
                      stroke={isWished ? "#3078FF" : "#ddd"}
                      stroke-width="2"
                    />
                  </svg>
                </div>
              </div>
              <div className="price">
                <span className="text-dark1 fs-12  fw-medium mt-1">$</span>
                <h2 className="text-dark1 fs-24 fw-800">
                  {car?.salePrice
                    ? parseInt(car?.salePrice).toLocaleString("en-AU")
                    : "N/A"}
                </h2>
              </div>
            </div>
            <Space align="end" wrap className="product-info">
              <div className="product-info-item">
                <img src={FireIcon} className="img-fluid" alt="local_fire" />
                <p className=" fw-medium text-dark3 text-capitalize">
                  {car?.fuel || "NA"}
                </p>
              </div>

              <div className="product-info-item">
                <img src={MeterIcon} className="img-fluid " alt="local_fire" />
                <p className="fw-medium text-dark3 text-capitalize">
                  {car?.odometer
                    ? `${parseInt(car?.odometer).toLocaleString("en-AU")} km`
                    : "NA"}
                </p>
              </div>

              <div className="product-info-item">
                <img src={GearIcon} className="img-fluid mb-4" alt="GearIcon" />
                <p className=" fw-medium text-dark3 text-capitalize">
                  {car?.transmission === "AT"
                    ? "Automatic"
                    : car?.transmission?.toLowerCase()}
                </p>
              </div>
            </Space>
          </div>
        </Badge.Ribbon>
      ) : (
        <>
          <Link
            to={`/${car?.make}/${car?.stockNo}`}
            className="car-image"
            onClick={handleLinkClick}
          >
            <img
              src={car?.exteriorPhoto?.[0]}
              className="img-fluid"
              alt="img"
            />
            {/* <img src={RotateIcon} className="img-fluid img-rotate" alt="rotate" /> */}
          </Link>
          <div className="car-content">
            <div className="top-content">
              <div className="title">
                <Link
                  to={`/${car?.make}/${car?.stockNo}`}
                  className="text-dark1 fw-medium fs-16 ellipsis-text"
                  onClick={handleLinkClick}
                >
                  {car?.title}
                </Link>
                <div
                  onClick={
                    isWished ? handleRemoveFromWishlist : handleAddToWishlist
                  }
                >
                  <svg
                    width="24"
                    height="24"
                    viewBox="0 0 44 44"
                    fill={isWished ? "#3078FF" : "none"}
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M30.3951 7C32.8062 7 34.8005 7.80767 36.4127 9.41978C38.0237 11.0315 38.8326 13.0407 38.8326 15.4837C38.8326 18.6032 37.5535 21.6368 34.9263 24.5929C32.2503 27.6031 29.2072 30.6768 25.7961 33.8139L25.7941 33.8157L23.5067 35.8745L23.4981 35.8826C23.0377 36.3173 22.5176 36.525 21.9163 36.525C21.3073 36.525 20.7847 36.3266 20.3266 35.9203L18.0385 33.8157L18.0373 33.8146C14.5033 30.5854 11.4304 27.4819 8.81715 24.504C6.25045 21.5784 5 18.5745 5 15.4837C5 13.0407 5.8089 11.0315 7.41993 9.41978C9.03208 7.80767 11.0264 7 13.4375 7C14.7877 7 16.1808 7.33711 17.6213 8.02772C19.0195 8.69839 20.3174 9.87485 21.5047 11.5964L21.9016 12.1719L22.318 11.6103C23.6019 9.87856 24.9206 8.69629 26.2649 8.02412C27.638 7.33756 29.0132 7 30.3951 7Z"
                      stroke={isWished ? "#3078FF" : "#ddd"}
                      stroke-width="2"
                    />
                  </svg>
                </div>
              </div>
              <div className="price">
                <span className="text-dark1 fs-12  fw-medium mt-1">$</span>
                <h2 className="text-dark1 fs-24 fw-800">
                  {car?.salePrice
                    ? parseInt(car?.salePrice).toLocaleString("en-AU")
                    : "N/A"}
                </h2>
              </div>
            </div>
            <Space align="end" wrap className="product-info">
              <div className="product-info-item">
                <img src={FireIcon} className="img-fluid" alt="local_fire" />
                <p className=" fw-medium text-dark3 text-capitalize">
                  {car?.fuel || "NA"}
                </p>
              </div>

              <div className="product-info-item">
                <img src={MeterIcon} className="img-fluid " alt="local_fire" />
                <p className="fw-medium text-dark3 text-capitalize">
                  {car?.odometer
                    ? `${parseInt(car?.odometer).toLocaleString("en-AU")} km`
                    : "NA"}
                </p>
              </div>

              <div className="product-info-item">
                <img
                  src={GearIcon}
                  className="img-fluid mb-4"
                  alt="local_fire"
                />
                <p className=" fw-medium text-dark3 text-capitalize">
                  {car?.transmission === "AT"
                    ? "Automatic"
                    : car?.transmission?.toLowerCase()}
                </p>
              </div>
            </Space>
          </div>
        </>
      )}
    </div>
  );
};

export default StockListItem;
