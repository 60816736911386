/* eslint-disable react-hooks/exhaustive-deps */

/* eslint-disable jsx-a11y/iframe-has-title */
import { GoogleMap, Marker } from "@react-google-maps/api";
import moment from "moment/moment";
import React, { useEffect } from "react";
import DoorDeliveryImg from "../../assets/images/door-delivery-img.png";
import SelfPickupImg from "../../assets/images/self-pickup-img.png";
import usePurchase from "../../hooks/usePurchase";
import ModalCalendar from "../nextAction/ModalCalendar";
import CustomTimePicker from "../purchaseJourneyItems/CustomTimePicker";
import DoorDeliveryMap from "../purchaseJourneyItems/DoorDeliveryMap";
import "./purchaseSteps.scss";
import axios from "axios";
import useAuth from "../../hooks/useAuth";
import { toast } from "react-toastify";
import { useState } from "react";
import { validateEmail } from "../../utils/validateEmail";

const PurchaseStep2 = ({ stepNo, setStepNo }) => {
  const { formData, setFormData, isAM, distance, isLoaded, center } =
    usePurchase();


    const {customer} = useAuth();

  const { loading, error, dispatch } = useAuth();

  const [signupResult, setSignupResult] = useState({});
  const [loginResult, setLoginResult] = useState({});

  // For Signup
  const createCustomer = async () => {
    const customerInfo = {
      username: formData?.email,
      email: formData?.email,
      password: formData?.password,	
	    dateOfBirth: formData?.dob,
      firstName:formData?.firstName,
      lastName:formData?.lastName,
      mobileNumber:formData?.phoneNo,
      drivingLicenseNumber:formData?.dlNo,
      licenseExpiry:formData?.dlExpairDate,
      address:formData?.address
    };

    try {
      await axios.post(
        "https://www.cbs.s1.importautos.com.au/carbarn/user/v1/api/customer/create",
        customerInfo
      );
    } catch (error) {
      console.error("Error for customer creation:", error);
      setSignupResult(error?.response?.data);
    }
  };


  const isValidEmail = validateEmail(formData?.email);


  useEffect(() => {
    if (stepNo === 1 && isValidEmail && !customer?.email) {
      createCustomer();
    }
  }, [stepNo]);

  // For Login
  const customerLogin = async () => {

    const credentials = {
      username: formData?.email,
      password: formData?.password,
    };

    dispatch({ type: "LOGIN_START" });

    try {
      const res = await axios.post(
        `https://www.cbs.s1.importautos.com.au/carbarn/auth/v1/api/user/signin`,
        credentials
      );

      dispatch({ type: "LOGIN_SUCCESS", payload: res?.data });
    } catch (err) {
      dispatch({ type: "LOGIN_FAILURE", payload: err?.response?.data });
      setLoginResult(err?.response?.data);
    }
  };

  useEffect(() => {
    if (formData?.email && formData?.password && !customer?.email) {
      setTimeout(()=>{
        customerLogin();
      },500)
    }
  }, []);


  if(!isValidEmail){
    toast.error("Email is not valid");
    setStepNo(0);
  }

  if(loginResult?.status === 403){
    toast.error("Username or Email already exist but password is invalid");
    setStepNo(0);
  }

  // if (customer?.email) {
  //   toast.success("Registration and Login Successful");
  // }


  const updateCustomer = async () => {

    const customerInfo = {
      id: customer?.id,
      firstName:formData?.firstName,
      lastName:formData?.lastName,
      mobileNumber:formData?.phoneNo,
      drivingLicenseNumber:formData?.dlNo,
      licenseExpiry:formData?.dlExpairDate,
      address:formData?.address
    };

    try {
      await axios.patch(
        "https://www.cbs.s1.importautos.com.au/carbarn/user/v1/api/customer/update",
        customerInfo
      );
    } catch (error) {
      console.error("Error for customer update:", error);
      // setSignupResult(error?.response?.data);
    }
  };


  useEffect(() => {
    if (stepNo === 1 && customer?.email) {
      updateCustomer();
    }
  }, [stepNo]);




  return (
    <>
      {formData?.deliveryOption === "" && (
        <div className="delivery-options-wrapper">
          <div
            className={`delivery-option mb-40 ${
              formData.deliveryOption === "door delivery" && "active"
            }`}
            onClick={() =>
              setFormData((prevData) => ({
                ...prevData,
                deliveryOption: "door delivery",
              }))
            }
          >
            <img src={DoorDeliveryImg} alt="img" className="img-fluid" />
            <h2>Door Delivery</h2>
          </div>
          <div
            className={`delivery-option ${
              formData.deliveryOption === "self pickup" && "active"
            }`}
            onClick={() =>
              setFormData((prevData) => ({
                ...prevData,
                deliveryOption: "self pickup",
              }))
            }
          >
            <img src={SelfPickupImg} alt="img" className="img-fluid" />
            <h2>Self Pick-up</h2>
          </div>
        </div>
      )}

      {formData?.deliveryOption === "door delivery" && (
        <div className="step-wrapper">
          <h5 className="fs-16 text-dark1 fw-800 mb-20">Set your address</h5>

          <DoorDeliveryMap />

          <div className="date-time mt-40">
            <div className="step-calender">
              <h5 className="fs-16 text-dark1 fw-800 mb-20">Set schedule</h5>
              <ModalCalendar />
            </div>

            <div className="time-picker">
              <h5 className="fs-16 text-dark1 fw-800 mb-50">Time:</h5>
              <CustomTimePicker />
            </div>
          </div>

          <div className="cost mt-30">
            <h5 className="fs-16 text-dark1 fw-800 mb-20">Summary</h5>
            <div className="item-wise-cost">
              <p className="fs-12 text-dark3">Address:</p>
              <h6 className="fs-14 fw-800 text-dark3">
                {formData?.doorDeliveryAddress
                  ? formData?.doorDeliveryAddress
                  : "......"}
              </h6>
            </div>
            <div className="item-wise-cost">
              <p className="fs-12 text-dark3">Date: :</p>
              <h6 className="fs-14 fw-800 text-dark3">
                {formData?.date && (
                  <>{moment(formData?.date).format("dddd MMMM DD")},</>
                )}{" "}
                {formData?.time && (
                  <>
                    {formData?.time} {isAM ? "AM" : "PM"}
                  </>
                )}
              </h6>
            </div>

            <div className="border-gray mb-15"></div>

            <div className="item-wise-cost">
              <p className="fs-16 fw-800 text-dark1">Distance :</p>
              <h6 className="fs-16 fw-800 text-dark1">
                {Math.ceil(distance / 1000)?.toLocaleString("en-AU")} KM
              </h6>
            </div>
            <div className="item-wise-cost">
              <p className="fs-16 fw-800 text-dark1">Delivery Cost :</p>
              <h6 className="fs-16 fw-800 text-dark1">
                ${(Math.ceil(distance / 1000) * 2)?.toLocaleString("en-AU")}
              </h6>
            </div>
          </div>
        </div>
      )}

      {formData?.deliveryOption === "self pickup" && (
        <>
          {!isLoaded ? (
            "Loading"
          ) : (
            <GoogleMap
              center={center}
              zoom={15}
              mapContainerStyle={{ width: "100%", height: "700px" }}
              options={{
                zoomControl: false,
                streetViewControl: false,
                mapTypeControl: false,
                fullscreenControl: false,
              }}
              // onLoad={(map) => setMap(map)}
            >
              <Marker position={center} />
              {/* {directionsResponse && (
                  <DirectionsRenderer directions={directionsResponse} />
                )} */}
            </GoogleMap>
          )}
        </>
      )}
    </>
  );
};

export default PurchaseStep2;
