import { Col, Row, Space } from "antd";
import React from "react";
import { Link, useLocation } from "react-router-dom";
import Logo from "../../assets/images/carbarn-logo.svg";
import Facebook from "../../assets/images/fb.svg";
import FooterBg from "../../assets/images/footer-bg.png";
import Instagram from "../../assets/images/ins.svg";
import Location from "../../assets/images/locator1.svg";
import Email from "../../assets/images/mail1.svg";
import Phone from "../../assets/images/telephone1.png";
import "./footer.scss";

const Footer = () => {
  const location = useLocation();

  if (location?.pathname === "/login" || location?.pathname === "/signup")
    return null;

  const footerMenu = {
    finance: [
      {
        menuName: "Get Pre-Approval",
      },
      {
        menuName: "Carbarn Finance",
      },
    ],

    navigation: [
      {
        menuName: "Home",
        route: "/home",
      },
      {
        menuName: "Stock List",
        route: "/stock",
      },
      {
        menuName: "Warranty",
        route: "/warranty",
      },
      {
        menuName: "Car Finance",
        route: "/finance",
      },
      {
        menuName: "How it Works",
        route: "/how-works",
      },
    ],

    popularMakes: [
      {
        menuName: "All Car",
        route: "/",
      },
      {
        menuName: "Camper Van",
        route: "/camper-van",
      },
      {
        menuName: "Hybrid Car",
        route: "/hybrid-car",
      },
    ],

    company: [
      {
        menuName: "FAQ",
        route: "/faq",
      },
      {
        menuName: "Contact",
        route: "/contact",
      },
      {
        menuName: "Blog",
        route: "/blog",
      },
      {
        menuName: "About",
        route: "/home",
      }
    ],
  };

  const handleLinkClick = () => {
    window.scrollTo(0, 0);
  };

  return (
    <footer className="footer" style={{ backgroundImage: `url(${FooterBg})` }}>
      <div className="footer-top pt-100">
        <div className="container">
          <Row justify="center" gutter={[24, 24]} className="mb-70">
            <Col xs={{ span: 24 }} sm={{ span: 12 }} lg={{ span: 8 }}>
              <Space
                size="large"
                align="center"
                className="contact-unit bg-clr-skyblue"
                style={{ width: "100%", height: "100%" }}
              >
                <div className="contact-img">
                  <img src={Email} alt="img" className="img-fluid img-mail" />
                </div>
                <div className="contact-content">
                  <h4 className="part-title fw-medium text-dark3">Email</h4>
                  <a
                    href="mailto:carbarn@gmail.com"
                    className="fs-18 fw-medium text-dark1"
                  >
                    info@carbarn.com.au
                  </a>
                </div>
              </Space>
            </Col>
            <Col xs={{ span: 24 }} sm={{ span: 12 }} lg={{ span: 8 }}>
              <Space
                size="large"
                align="center"
                className="contact-unit bg-clr-skyblue"
                style={{ width: "100%", height: "100%" }}
              >
                <div className="contact-img">
                  <img
                    src={Location}
                    alt="img"
                    className="img-fluid img-mail"
                  />
                </div>
                <div className="contact-content">
                  <h4 className="part-title fw-medium text-dark3">Address</h4>
                  <p
                    className="fs-18 fw-medium text-dark1"
                  >
                    136 Frances Street, Lidcombe NSW 2141
                  </p>
                </div>
              </Space>
            </Col>
            <Col xs={{ span: 24 }} sm={{ span: 12 }} lg={{ span: 8 }}>
              <Space
                size="large"
                align="center"
                className="contact-unit bg-clr-skyblue"
                style={{ width: "100%", height: "100%" }}
              >
                <div className="contact-img">
                  <img src={Phone} alt="img" className="img-fluid img-mail" />
                </div>
                <div className="contact-content">
                  <h4 className="part-title fw-medium text-dark3">Phone</h4>
                  <a
                    href="callto:+61 423 840 130"
                    className="fs-18 fw-medium text-dark1"
                  >
                    +61 423 840 130
                  </a>
                </div>
              </Space>
            </Col>
          </Row>

          <Row gutter={[24, 24]} justify="space-between" className="mb-70">
            <Col xs={{ span: 24 }} sm={{ span: 8 }} lg={{ span: 5 }}>
              <div
                className="footer-widget"
              >
                <Link to="/" className="footer-logo mb-30">
                  <div style={{ width: "170px" }}>
                    <img src={Logo} alt="logo" className="img-fluid" />
                  </div>
                </Link>
                <p className="fs-18 fw-medium text-dark3">Ayanuk Pty Ltd</p>
                <p className="fs-18 fw-medium text-dark3 mt-4">
                  Motor Dealer Licence: MD056471
                </p>
              </div>
            </Col>
            <Col xs={{ span: 12 }} sm={{ span: 8 }} lg={{ span: 4 }}>
              <div
                className="footer-widget"
              >
                <h3 className="widget-title text-dark1 fs-12 ls-2 fw-800 mb-30">
                  {" "}
                  Navigation{" "}
                </h3>
                <ul className="widget-list ">
                  {footerMenu.navigation.map((menu, index) => {
                    return (
                      <li className="mb-15" key={index}>
                        <Link
                          to={`${menu?.route}`}
                          className="text-dark3 fs-18 fw-medium "
                          onClick={handleLinkClick}
                        >
                          {menu?.menuName}
                        </Link>
                      </li>
                    );
                  })}
                </ul>
              </div>
            </Col>
            <Col xs={{ span: 12 }} sm={{ span: 8 }} lg={{ span: 4 }}>
              <div
                className="footer-widget"
              >
                <h3 className="widget-title text-dark1 fs-12 ls-2 fw-800 mb-30">
                  {" "}
                  Vehicle
                </h3>
                <ul className="widget-list">
                  {footerMenu.popularMakes.map((menu, index) => {
                    return (
                      <li className="mb-15" key={index}>
                        <Link
                          to={`${menu?.route}`}
                          className="text-dark3 fs-18 fw-medium "
                          onClick={handleLinkClick}
                        >
                          {menu.menuName}
                        </Link>
                      </li>
                    );
                  })}
                </ul>
              </div>
            </Col>
            <Col xs={{ span: 12 }} sm={{ span: 8 }} lg={{ span: 4 }}>
              <div
                className="footer-widget"
              >
                <h3 className="widget-title text-dark1 fs-12 ls-2 fw-800 mb-30">
                  {" "}
                  Company{" "}
                </h3>
                <ul className="widget-list">
                  {footerMenu.company.map((menu, index) => {
                    return (
                      <li className="mb-15" key={index}>
                        <Link
                          to={`${menu.route}`}
                          className="text-dark3 fs-18 fw-medium "
                          onClick={handleLinkClick}
                        >
                          {menu.menuName}
                        </Link>
                      </li>
                    );
                  })}
                </ul>
              </div>
            </Col>

            <Col xs={{ span: 12 }} sm={{ span: 8 }} lg={{ span: 4 }}>
              <div
                className="footer-widget"
              >
                <h3 className="widget-title text-dark1 fs-12 ls-2 fw-800 mb-30">
                  {" "}
                  Finance{" "}
                </h3>
                <ul className="widget-list">
                  {footerMenu.finance.map((menu, index) => {
                    return (
                      <li className="mb-15" key={index}>
                        <Link
                          className="text-dark3 fs-18 fw-medium "
                          onClick={handleLinkClick}
                        >
                          {menu.menuName}
                        </Link>
                      </li>
                    );
                  })}
                </ul>
              </div>
            </Col>

            <Col xs={{ span: 12 }} sm={{ span: 8 }} lg={{ span: 3 }}>
              <div
                className="footer-widget"
              >
                <h3 className="widget-title text-dark1 fs-12 ls-2 fw-800 mb-30">
                  {" "}
                  Social{" "}
                </h3>
                <ul className="social-wrapper">
                  <li className="mb-15">
                    <a href="https://www.facebook.com/CarbarnAU/" target="_new">
                      <Space>
                        <img src={Facebook} className="img-fluid" alt="icon" />
                        <p className="text-dark3 fs-18 fw-medium">Facebook</p>
                      </Space>
                    </a>
                  </li>
                  <li className="mb-15">
                    <a
                      href="https://www.instagram.com/carbarn_au/"
                      target="_new"
                    >
                      <Space>
                        <img src={Instagram} className="img-fluid" alt="icon" />
                        <p className="text-dark3 fs-18 fw-medium">Instagram</p>
                      </Space>
                    </a>
                  </li>
                </ul>
              </div>
            </Col>
          </Row>
          <p className="copyright text-dark3 ls-2 fs-12 fw-800 pb-15">
            © 2023 CARBARN
          </p>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
