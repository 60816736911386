/* eslint-disable jsx-a11y/iframe-has-title */
import { Col, Form, Input, Radio, Row, Space, Spin } from "antd";
import axios from "axios";
import dayjs from "dayjs";
import customParseFormat from "dayjs/plugin/customParseFormat";
import React, { useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import "react-time-picker-input/dist/components/TimeInput.css";
import { toast } from "react-toastify";
import { ReactComponent as EmailIcon } from "../../assets/images/email-icon-carbarn.svg";
import WheelIcon from "../../assets/images/modal-4-wheel-icon-carbarn.svg";
import CarBodyIcon from "../../assets/images/modal-body-icon-carbarn.svg";
import CarDoorIcon from "../../assets/images/modal-car-door-icon-carbarn.svg";
import ColorFilterIcon from "../../assets/images/modal-color-icon-carbarn.svg";
import EngineIcon from "../../assets/images/modal-engine-icon-carbarn.svg";
import MilageIcon from "../../assets/images/modal-milage-icon-carbarn.svg";
import TorqueMeterIcon from "../../assets/images/modal-torque-meter-icon-carbarn.svg";
import { ReactComponent as PhoneIcon } from "../../assets/images/phone-icon-carbarn.svg";
import { ReactComponent as UserIcon } from "../../assets/images/user-icon-carbarn.svg";
import useFetch from "../../hooks/useFetch";
import usePurchase from "../../hooks/usePurchase";
import ConfirmationModal from "../confirmationModal/ConfirmationModal";
import ModalCalendar from "../nextAction/ModalCalendar";
import CustomTimePicker from "../purchaseJourneyItems/CustomTimePicker";
import "./testDriveModal.scss";
dayjs.extend(customParseFormat);

const FeaturedItem = ({ featureIcon, featureName, exteriorColor }) => {
  return (
    <div className="feature-item">
      <img src={featureIcon} alt="icon" className="img-fluid" />
      <p className="fs-14 text-dark1 lh-24">
        {featureName} {exteriorColor && `/${exteriorColor}`}
      </p>
    </div>
  );
};

const TestDriveModal = ({ modalTitle }) => {
  const { formData, setFormData, handleChange, isAM } = usePurchase();

  const { carId } = useParams();

  const [value, setValue] = useState(1);

  const [isOpen, setIsOpen] = useState(false);
  const [verifyEmailOpen, setVerifyEmailOpen] = useState(false);
  const [showFinanceApplication, setShowFinanceApplication] = useState(false);

  const { data: singleDetails, loading } = useFetch(
    `https://www.cbs.s1.importautos.com.au/product-index/v1/api/vehicle/chassis/${carId}`
  );

  const showConfirmation = () => {
    setIsOpen(true);
  };

  // const showEmailVerification = () => {
  //   setVerifyEmailOpen(true);
  // };

  // const handleFinanceApplication = () => {
  //   setShowFinanceApplication(true);
  // };

  const onRadioChange = (e) => {
    console.log("radio checked", e.target.value);
    setInspectionType(e.target.value);
  };

  // const [isModalOpen, setIsModalOpen] = useState(false);

  // const showModal = () => {
  //   setIsModalOpen(true);
  // };

  // const handleCancel = () => {
  //   setIsModalOpen(false);
  // };

  // const images = singleDetails?.vehiclePhotos?.split("|");

  const navigate = useNavigate();

  const goBack = () => {
    navigate(-1);
  };

  const [inspectionType, setInspectionType] = useState("");

  const handleSubmit = async (e) => {
    e.preventDefault();

    const updatedData = {
      customerFirstName: formData?.firstName || "",
      customerLastName: formData?.lastName || "",
      customerAddress: formData?.address || "",
      customerPhone: formData?.phoneNo || "",
      customerEmail: formData?.email || "",
      scheduleDate: formData?.date,
      chessisId: carId,
      status: {
        id: 1,
      },
      clockTime: `${formData?.time} ${isAM ? "AM" : "PM"}`,
      testDriveOrVideoCall: inspectionType,
    };

    try {
      await axios.post(
        "https://www.cbs.s1.importautos.com.au/carbarn/user/v1/api/test_drive/create",
        updatedData
      );

      toast.success("Successfully updated data");
      setFormData({});

      navigate(-1);
      
    } catch (err) {
      toast.error("There is something wrong");
      console.log(err);
    }
  };


  const engineCC = (Math.ceil(singleDetails?.engineCapacity / 100)*100)/1000

  return (
    <>
      {/* <h6 onClick={showModal}>
        Book Online <br /> Inspection <br /> Or Test Drive
      </h6> */}

      {/* <Modal
        centered
        title={modalTitle}
        open={isModalOpen}
        onCancel={handleCancel}
        width={1220}
        className="action-modal"
        footer={false}
      > */}
      <div className="container">
        {loading ? (
          <div className="text-center mt-50 mb-50">
            <Spin className="mx-auto" />
          </div>
        ) : (
          <div className="car-info mt-50 mb-50">
            <div className="img-box">
              <img
                src={singleDetails?.exteriorPhoto?.[0]}
                alt="car-img"
                className="img-fluid"
              />
            </div>
            <div className="car-feature-wrapper">
              <p className="text-dark3 mb-10">
                Stock No: {singleDetails?.stockNo || "N/A"}
              </p>
              <h4 className="fs-24 fw-800 text-dark1 lh-24 mb-15">
                {singleDetails?.title}
              </h4>
              <p className="text-dark3 mb-20">
                {singleDetails?.engineCode}, {singleDetails?.fuel || "Desel"},{" "}
                {singleDetails?.cylinder || "4"},{" "}
                {singleDetails?.roof_type || "Open Roof"},{" "}
                {singleDetails?.seats || "7"}
              </p>

              <div className="car-feature">
                {singleDetails?.engineNo && (
                  <FeaturedItem
                    featureIcon={EngineIcon}
                    featureName={singleDetails?.engineNo}
                  />
                )}
                {singleDetails?.exteriorColor && (
                  <FeaturedItem
                    featureIcon={ColorFilterIcon}
                    featureName={singleDetails?.interiorColor}
                    exteriorColor={singleDetails?.exteriorColor}
                  />
                )}
                {singleDetails?.engineCapacity && (
                  <FeaturedItem
                    featureIcon={MilageIcon}
                    featureName={`${engineCC} L`}
                  />
                )}
                {singleDetails?.weel_base_type && (
                  <FeaturedItem
                    featureIcon={WheelIcon}
                    featureName={singleDetails?.weel_base_type}
                  />
                )}
                {singleDetails?.roof_type && (
                  <FeaturedItem
                    featureIcon={CarDoorIcon}
                    featureName={singleDetails?.roof_type}
                  />
                )}
                {singleDetails?.driveTrain && (
                  <FeaturedItem
                    featureIcon={CarBodyIcon}
                    featureName={singleDetails?.driveTrain}
                  />
                )}
                {singleDetails?.engine_torque && (
                  <FeaturedItem
                    featureIcon={TorqueMeterIcon}
                    featureName={singleDetails?.engine_torque}
                  />
                )}
              </div>
            </div>
          </div>
        )}

        <form onSubmit={handleSubmit}>
          <Row justify="between" gutter={[24, 24]}>
            <Col xs={{ span: 24 }} lg={{ span: 12 }}>
              <div className="step-wrapper test-drive-picker">
                <div>
                  <h5 className="fs-16 text-dark1 fw-800 mb-20">
                    Set schedule
                  </h5>
                  <ModalCalendar />
                </div>
                <div>
                  <h5 className="fs-16 text-dark1 fw-800 mb-40">Time:</h5>
                  <CustomTimePicker />
                </div>
              </div>
            </Col>
            <Col xs={{ span: 24 }} lg={{ span: 12 }}>
              <div className="step-wrapper">
                <h5 className="fs-16 text-dark1 fw-800 mb-20">
                  Contact details
                </h5>

                <Form.Item
                  rules={[
                    {
                      required: true,
                      message: "Please input your First Name!",
                    },
                  ]}
                >
                  <Input
                    prefix={<UserIcon />}
                    placeholder="First Name"
                    type="text"
                    name="firstName"
                    onChange={handleChange}
                  />
                </Form.Item>
                <Form.Item
                  rules={[
                    {
                      required: true,
                      message: "Please input your Last Name!",
                    },
                  ]}
                >
                  <Input
                    prefix={<UserIcon />}
                    type="text"
                    placeholder="Last Name"
                    name="lastName"
                    onChange={handleChange}
                  />
                </Form.Item>

                <Form.Item
                  rules={[
                    {
                      required: true,
                      message: "Please input your mobile number!",
                    },
                  ]}
                >
                  <Input
                    prefix={<PhoneIcon />}
                    type="telephone"
                    placeholder="Mobile No"
                    name="phoneNo"
                    onChange={handleChange}
                  />
                </Form.Item>
                <Form.Item
                  rules={[
                    {
                      required: true,
                      message: "Please input your email!",
                    },
                  ]}
                >
                  <Input
                    prefix={<EmailIcon />}
                    type="email"
                    placeholder="Email Address"
                    name="email"
                    onChange={handleChange}
                  />
                </Form.Item>

                <Radio.Group
                  onChange={onRadioChange}
                  style={{ marginTop: "20px" }}
                >
                  <Space direction="vertical">
                    <Radio value="Test Drive" className="mb-10">
                      Test Drive
                    </Radio>
                    <Radio value="Online Inspection">
                      Online Inspection (Video Call)
                    </Radio>
                  </Space>
                </Radio.Group>

                <div className="mt-30">
                  {/* <p className="fs-16 text-dark3 text-center mb-20">
                    Need help? Give us a call at 0423840130
                  </p> */}

                  <div className="test-drive-btn-wrapper">
                    <Link
                      className="banner-btn px-40 fs-16 text-white w-100"
                      onClick={goBack}
                    >
                      <span>Back</span>
                    </Link>

                    <button
                      type="submit"
                      className="banner-btn px-40 fs-16 text-white w-100"
                      // onClick={showConfirmation}
                      style={{ border: "none" }}
                    >
                      <span>Request Booking</span>
                    </button>
                  </div>
                  <ConfirmationModal isOpen={isOpen} setIsOpen={setIsOpen} />
                </div>
              </div>
            </Col>
          </Row>
        </form>
      </div>
      {/* </Modal> */}
    </>
  );
};

export default TestDriveModal;
