import { useElements, useStripe } from "@stripe/react-stripe-js";
import { Col, Row, Steps } from "antd";
import axios from "axios";
import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import CircleLeftIcon from "../../assets/images/circle-left-angle.svg";
import ConfirmationIcon from "../../assets/images/confirmation-tick.svg";
import LoacationIcon from "../../assets/images/step-location.svg";
import PrfileIcon from "../../assets/images/step-profile.svg";
import PurchaseIcon from "../../assets/images/step-purchase.svg";
import RightTickIcon from "../../assets/images/step-right-tick.svg";
import PurchaseStep1 from "../../components/purchaseSteps/PurchaseStep1";
import PurchaseStep2 from "../../components/purchaseSteps/PurchaseStep2";
import PurchaseStep3 from "../../components/purchaseSteps/PurchaseStep3";
import PurchaseStep4 from "../../components/purchaseSteps/PurchaseStep4";
import usePurchase from "../../hooks/usePurchase";
import "./purchaseJourney.scss";
import useAuth from "../../hooks/useAuth";

const PurchaseJourney = ({
  singleDetails,
  carId,
  clientSecret,
  stripe,
  amount,
}) => {

  const {customer} = useAuth();

  const [current, setCurrent] = useState(0);

  const navigate = useNavigate();
  const getStripe = useStripe();
  const elements = useElements();

  const [message, setMessage] = useState(null);
  const [isProcessing, setIsProcessing] = useState(false);

  const [intentData, setIntentData] = useState();
  const [loading, setLoading] = useState(false);

  const {
    formData,
    setFormData,
    setFinancing,
    financing,
    editUser,
    editDeliveryOption,
  } = usePurchase();

  const steps = [
    {
      title: "Necessary Info",
      content: <PurchaseStep1 stepNo={current} />,
      icon: <img src={PrfileIcon} alt="icon" className="img-fluid" />,
    },
    {
      title: "Delivery Selection",
      content: <PurchaseStep2 singleDetails={singleDetails} stepNo={current} setStepNo={setCurrent} />,
      icon: <img src={LoacationIcon} alt="icon" className="img-fluid" />,
    },
    {
      title: "Purchase Options",
      content: <PurchaseStep4 singleDetails={singleDetails} />,
      icon: <img src={PurchaseIcon} alt="icon" className="img-fluid" />,
    },
    {
      title: "Checkout",
      content: (
        <PurchaseStep3
          singleDetails={singleDetails}
          clientSecret={clientSecret}
          stripe={stripe}
        />
      ),
      icon: <img src={RightTickIcon} alt="icon" className="img-fluid" />,
    },
  ];

  const [errors, setErrors] = useState({});

  // Form Validation

  const validateStep = () => {
    const {
      firstName,
      lastName,
      phoneNo,
      email,
      password,
      deliveryOption,
      doorDeliveryAddress,
      address,
      date,
      time,
    } = formData;

    const newErrors = {};

    if (current === 0) {
      if (!firstName && !customer?.email) {
        newErrors.firstName = "First Name is required";
        toast.error(newErrors.firstName);
      }
      else if (!lastName && !customer?.email) {
        newErrors.lastName = "Last Name is required";
        toast.error(newErrors.lastName);
      }
      else if (!email && !customer?.email) {
        newErrors.email = "Email is required";
        toast.error(newErrors.email);
      }
      else if (!phoneNo && !customer?.email) {
        newErrors.phoneNo = "Phone number is required";
        toast.error(newErrors.phoneNo);
      }
      // else if (!password) {
      //   newErrors.password = "Password is required";
      //   toast.error(newErrors.password);
      // }
      else if (!address && !customer?.email) {
        newErrors.address = "Address is required";
        toast.error(newErrors.address);
      }
    } else if (current === 1) {
      if (!deliveryOption) {
        newErrors.deliveryOption = "Delivery type is required";
        toast.error(newErrors.deliveryOption);
      }
      else if (deliveryOption === "door delivery" && !doorDeliveryAddress) {
        newErrors.doorDeliveryAddress = "Delivery address is required";
        toast.error(newErrors.doorDeliveryAddress);
      }
      else if (deliveryOption === "door delivery" && !date) {
        newErrors.date = "Date is required";
        toast.error(newErrors.date);
      }
      else if (deliveryOption === "door delivery" && !time) {
        newErrors.time = "Time is required";
        toast.error(newErrors.time);
      }
    }

    setErrors(newErrors);

    return Object.keys(newErrors).length === 0;
  };

  const next = () => {
    const isValid = validateStep();
    if (isValid) {
      setCurrent(current + 1);
    }
  };
  const prev = () => {
    setCurrent(current - 1);
  };

  const items = steps.map((item) => ({
    key: item.title,
    icon: item.icon,
  }));

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!getStripe) {
      // Stripe.js hasn't yet loaded.
      // Make sure to disable form submission until Stripe.js has loaded.
      return;
    }

    setIsProcessing(true);
    setLoading(true);

    const updatedData = {
      requestList: [
        {
          id: null,
          chassisId: carId,
          stockNumber: singleDetails?.stockNo || "",
          customer: {
            id: null,
            userId: null,
            username: formData?.email || "",
            password: formData?.password || "",
            email: formData?.email || "",
            dateOfBirth: "11/11/11",
            firstName: formData?.firstName || "",
            lastName: formData?.lastName || "",
            mobileNumber: formData?.phoneNo || "",
            drivingLicenseNumber: formData?.dlNo || "",
            licenseExpiry: formData?.dlExpairDate || "",
            address: formData?.address || "",
          },
          deliveryType: {
            id: formData?.deliveryOption === "self pickp" ? 2 : 1,
            type:
              formData?.deliveryOption === "self pickp"
                ? "Self Pickup"
                : "Door Delivery",
          },

          deliveryStatus: {
            id: 1,
            status: "Pending",
          },
          totalPrice: singleDetails?.salePrice,
          paymentStatusReq: {
            id: 3,
            status: "Pending",
          },
          payments: [
            {
              method: {
                id: 52,
                method: "Stripe",
              },
              reason: {
                id: 3,
                reason: "Deposit",
              },
              description: "Stripe Deposit Payment",
              amount: amount,
              referenceNumber: "",
              stripeNumber: "",
            },
          ],
        },
      ],
    };

    // Conditionally set the key based on formData.deliveryOption
    if (formData.deliveryOption === "self pickp") {
      updatedData.requestList[0].selfPickup = {
        id: 1,
        dateTime: "30/12/2023",
        branch: {
          id: 1,
          branchName: "Australian",
          address: "Melbourne",
        },
      };
    } else {
      updatedData.requestList[0].doorDelivery = {
        id: 2,
        dateTime: `${formData?.date} ${formData?.time}`,
        address: formData?.doorDeliveryAddress,
        deliveryCost: formData?.deliveryCost,
        addressLatitude: formData?.destinationLat,
        addressLongitude: formData?.destinationLong,
      };
    }

    try {
      const { error: submitError } = await elements.submit();
      if (submitError) {
        setMessage(submitError);
        setIsProcessing(false);
        return;
      }

      const { error, paymentIntent } = await getStripe.confirmPayment({
        elements,
        clientSecret,
        redirect: "if_required",
      });

      if (error) {
        setMessage(error);
      } else {
        setIntentData(paymentIntent);
      }

      updatedData.requestList[0].payments[0] = {
        method: {
          id: 52,
          method: "Stripe",
        },
        reason: {
          id: 3,
          reason: "Deposit",
        },
        description: "Stripe Deposit Payment",
        amount: amount,
        referenceNumber: paymentIntent?.id,
        stripeNumber: paymentIntent?.payment_method,
      };

    

      if (!paymentIntent?.id) {
        console.log("Payment Intent not found");
        toast.error("Card is not valid");
        setLoading(false);
      } else {
        // Sales Request

        const response = await axios.post(
          "https://www.cbs.s1.importautos.com.au/carbarn/v1/api/sales/create",
          updatedData
        );

        // Check the response status code
        if (response.status === 417) {
          // Handle the 417 error here
          console.error("Received a 417 error");

          toast.error("There is an error in purchase. Please try again");
          
          setLoading(false);
        } else {
          // Handle successful response here
          toast.success("Successfully purchase vehicle");
          setLoading(false);
          navigate("/user");
        }
      }
    } catch (err) {
      toast.error("There is something wrong");
      console.log(err);
      setLoading(false);
    }
  };

  return (
    <section className="purchase-journey">
      <form onSubmit={handleSubmit} className="container">
        <Row gutter={[70, 40]} className="purchase-form-wrapper">
          <Col xs={24} lg={12}>
            <div>{steps[current].content}</div>

            <div
              style={{
                marginTop: 24,
              }}
            >
              {current === 0 && (
                <Link
                  className="banner-btn px-40 fs-16 text-white w-100"
                  onClick={() => next()}
                >
                  Next
                </Link>
              )}
            </div>

            {current > 0 && (
              <div className="payment-wrapper payment-wrapper-mobile">
                {current < steps.length - 1 && (
                  <div
                    className="common-fill-btn text-center"
                    onClick={() => next()}
                  >
                    <Link className="fs-16 bg-clr-blue fw-bold text-white radius-16 fill-btn width-initial">
                      {current === 1 && "Proceed to payment"}
                      {current === 2 && "Review Order"}
                    </Link>
                  </div>
                )}

                {formData?.deliveryOption === "door delivery" &&
                  current === 1 && (
                    <div className="option-wrapper mx-auto">
                      <h5 className="option-text">Want to get it yourself?</h5>
                      <Link
                        className="option-text option-link"
                        onClick={() =>
                          setFormData((prevData) => ({
                            ...prevData,
                            deliveryOption: "self pickup",
                          }))
                        }
                      >
                        Proceed with self pick-up
                      </Link>
                    </div>
                  )}

                {formData?.deliveryOption === "self pickup" &&
                  current === 1 && (
                    <div className="option-wrapper">
                      <h5 className="option-text">Feeling lazy?</h5>
                      <Link
                        className="option-text option-link"
                        onClick={() =>
                          setFormData((prevData) => ({
                            ...prevData,
                            deliveryOption: "door delivery",
                          }))
                        }
                      >
                        Click here for delivery options!
                      </Link>
                    </div>
                  )}

                {current === 2 && !financing && (
                  <div className="option-wrapper">
                    <h5 className="option-text">Need a loan?</h5>
                    <Link
                      className="option-text option-link"
                      onClick={() => setFinancing(true)}
                    >
                      Proceed with financing.
                    </Link>
                  </div>
                )}

                {current === 2 && financing && (
                  <div className="option-wrapper text-center">
                    <h5 className="option-text">Want to buy at once?</h5>
                    <Link
                      className="option-text option-link"
                      onClick={() => setFinancing(false)}
                    >
                      Click here.
                    </Link>
                  </div>
                )}

                {current === steps.length - 1 && (
                  <div className="common-fill-btn text-center">
                    <button
                      type="submit"
                      className="fs-16 bg-clr-blue fw-bold text-white radius-16 fill-btn width-initial"
                      disabled={loading}
                    >
                      {loading ? "Processing" : "Finish order!"}
                    </button>
                  </div>
                )}
              </div>
            )}
          </Col>
          <Col xs={24} lg={12}>
            {current > 0 && (
              <Link
                style={{
                  display: "block",
                  marginBottom: "30px",
                  textAlign: "right",
                }}
                onClick={() => prev()}
              >
                <img src={CircleLeftIcon} alt="icon" className="close-icon" />
              </Link>
            )}

            <div>
              <div className="step-info">
                <img src={ConfirmationIcon} alt="img" className="img-fluid" />

                {/* for 1st step  */}
                {current === 0 && <h3>A perfect choice!</h3>}
                {current === 0 && <h2>{singleDetails?.title}</h2>}
                {current === 0 && <h3>Let’s get it wrapped up in a minute!</h3>}
                {/* --------------------------------------------- */}

                {/* 2nd step  */}
                {current === 1 && formData?.deliveryOption === "" && (
                  <h3>How would you like your new car?</h3>
                )}
                {current === 1 && formData?.deliveryOption !== "" && (
                  <h3>Can’t wait to meet you!</h3>
                )}
                {current === 1 && formData?.deliveryOption === "" && (
                  <h2>Please make your selection</h2>
                )}
                {current === 1 &&
                  formData?.deliveryOption === "door delivery" && (
                    <h2>Please set your address and let us know when</h2>
                  )}
                {current === 1 &&
                  formData?.deliveryOption === "self pickup" && (
                    <Link className="fs-48 fw-800">
                      Click here to get directions
                    </Link>
                  )}
                {current === 1 &&
                  formData?.deliveryOption === "self pickup" && (
                    <h3>136 Frances Street, Lidcombe NSW 2141, Australia</h3>
                  )}

                {/* --------------------------------------------------------- */}

                {/* 3rd Step  */}

                {current === 3 && !financing && <h3>Nearly done!</h3>}
                {current === 3 && financing && <h3>We’re here to assist!</h3>}
                {current === 3 && !financing && (
                  <h2>Please select your preferred payment method</h2>
                )}

                {current === 3 && financing && (
                  <h2>Please set your preferred payment schedule</h2>
                )}

                {/* ------------------------------------------------------ */}

                {/* 4th step  */}

                {current === 2 && !editUser && !editDeliveryOption && (
                  <h3>Awesome!</h3>
                )}
                {current === 2 && (editUser || editDeliveryOption) && (
                  <h3>Take your time!</h3>
                )}

                {current === 2 && !editUser && !editDeliveryOption && (
                  <h2>Last step for your new ride!</h2>
                )}
                {current === 2 && (editUser || editDeliveryOption) && (
                  <h2>Your new ride awaits!</h2>
                )}

                {current === 2 && !editUser && !editDeliveryOption && (
                  <h3>Please check your info and hit finish!</h3>
                )}
                {current === 2 && (editUser || editDeliveryOption) && (
                  <h3>While you edit the fields as necessary.</h3>
                )}

                {/* ------------------------------------------------------------- */}
              </div>
              <Steps
                className="purchase-step"
                current={current}
                items={items}
                direction="horizontal"
              />
            </div>

            <div className="step-title-wrapper">
              <p className="ml-12">
                Necessary <br /> Info
              </p>
              <p className="mr-5">
                Delivery <br /> Selection
              </p>
              <p className="mr-5">
                Purchase <br /> Options
              </p>
              <p className="mr-8 mr-10">Checkout</p>
            </div>

            {current > 0 && (
              <div className="payment-wrapper">
                {current < steps.length - 1 && (
                  <div className="common-fill-btn" onClick={() => next()}>
                    <Link className="fs-16 bg-clr-blue fw-bold text-white radius-16 fill-btn">
                      {current === 1 && "Proceed to payment"}
                      {current === 2 && "Review Order"}
                    </Link>
                  </div>
                )}

                {formData?.deliveryOption === "door delivery" &&
                  current === 1 && (
                    <div className="option-wrapper">
                      <h5 className="option-text">Want to get it yourself?</h5>
                      <Link
                        className="option-text option-link"
                        onClick={() =>
                          setFormData((prevData) => ({
                            ...prevData,
                            deliveryOption: "self pickup",
                          }))
                        }
                      >
                        Proceed with self pick-up
                      </Link>
                    </div>
                  )}

                {formData?.deliveryOption === "self pickup" &&
                  current === 1 && (
                    <div className="option-wrapper">
                      <h5 className="option-text">Feeling lazy?</h5>
                      <Link
                        className="option-text option-link"
                        onClick={() =>
                          setFormData((prevData) => ({
                            ...prevData,
                            deliveryOption: "",
                          }))
                        }
                      >
                        Click here for delivery options!
                      </Link>
                    </div>
                  )}

                {current === 3 && !financing && (
                  <div className="option-wrapper">
                    <h5 className="option-text">Need a loan?</h5>
                    <Link
                      className="option-text option-link"
                      onClick={() => setFinancing(true)}
                    >
                      Proceed with financing.
                    </Link>
                  </div>
                )}

                {current === 3 && financing && (
                  <div className="option-wrapper text-center">
                    <h5 className="option-text">Want to buy at once?</h5>
                    <Link
                      className="option-text option-link"
                      onClick={() => setFinancing(false)}
                    >
                      Click here.
                    </Link>
                  </div>
                )}

                {current === steps.length - 1 && (
                  <div className="common-fill-btn">
                    <button
                      type="submit"
                      className="fs-16 bg-clr-blue fw-bold text-white radius-16 fill-btn"
                      disabled={loading}
                    >
                      {loading ? "Processing" : "Finish order!"}
                    </button>
                  </div>
                )}
              </div>
            )}
          </Col>
        </Row>
      </form>
    </section>
  );
};

export default PurchaseJourney;
