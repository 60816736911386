import React from 'react';
import './blog.scss';
import { Row } from 'antd';
import { blogData } from '../../data';
import BlogItem from '../../components/blogItem/BlogItem';
import { Link } from 'react-router-dom';

const Blog = () => {
  return (
      <>
          <section className="blog-banner"></section>
          <section className="over-flow-hidden">
              <div className="container">
                  <div className="blog-header text-center py-50 text-dark1" style={{ width: "60%", margin: "auto" }}>
                      <h3 className="fs-48 fw-800 mb-15">Our Blog</h3>
                      <p className="fs-18 text-dark3 lh-32">
                          Hybrid or SUV? Family sedan or 4x4? It can be hard to know where to start when you’re looking for a new used car. Our buying guides, tips and comparisons will help you find
                          the car that’s right for you.
                      </p>
                  </div>
                  <Row gutter={[40, 40]}>
                      {blogData.map((blog) => (
                          <BlogItem blog={blog} />
                      ))}
                  </Row>
                  <div className="common-fill-btn text-center mt-50">
                      <Link className="fs-16 bg-clr-blue fw-bold text-white rounded fill-btn">
                          Load More
                      </Link>
                  </div>
              </div>
          </section>
      </>
  );
};

export default Blog;