import { Col, Form, Input, Row, Select } from 'antd';
import React from 'react';
import VehiclePrice from '../vehiclePrice/VehiclePrice';
import './monthlyPayment.scss';

const MonthlyPayment = () => {
    return (
        <div className='container'>
            <Row gutter={[36, 20]} className="">
                <Col className=""xs={{ span: 24 }} lg={{ span: 12 }}>
                    <div className='MonthlyPaymentForm bg-white'>
                        <Form name="normal_login" className="login-form" initialValues={{ remember: true, }} >
                            <Form.Item className='singleField'
                                label="Vehicle price"
                                name="vehiclePrice"
                                rules={[
                                    {

                                        vehiclePrice: 'Please input your vehicle-price!',
                                    },
                                ]}
                            >
                                <Input placeholder="$25,000" />
                            </Form.Item>
                            <Form.Item className='singleField'
                                label="Down payment"
                                name="Down payment"
                                rules={[
                                    {
                                        message: 'Please input your Down payment!',
                                    },
                                ]}
                            >
                                <Input type="text" placeholder="$25,000" />
                            </Form.Item>
                            <Form.Item className='singleField'
                                label="State"
                                name="State"
                                rules={[
                                    {
                                        message: 'Please input your State!',
                                    },
                                ]}
                            >
                                <Select
                                    defaultValue="Select make"
                                    options={[
                                        {
                                            value: 'jack',
                                            label: 'Jack',
                                        },
                                        {
                                            value: 'lucy',
                                            label: 'Lucy',
                                        },
                                        {
                                            value: 'Yiminghe',
                                            label: 'yiminghe',
                                        },
                                    ]}
                                />
                            </Form.Item>
                            <Form.Item className='singleField'
                                label="Credit Score"
                                name="Credit Score"
                                rules={[
                                    {
                                        message: 'Please input your Credit Score!',
                                    },
                                ]}
                            >
                                <Select
                                    defaultValue="Good (670-739 FICO R Score)"
                                    options={[
                                        {
                                            value: 'jack',
                                            label: 'Jack',
                                        },
                                        {
                                            value: 'lucy',
                                            label: 'Lucy',
                                        },
                                        {
                                            value: 'Yiminghe',
                                            label: 'yiminghe',
                                        },
                                    ]}
                                />
                            </Form.Item>
                            <Form.Item className='singleField'
                                label="APR"
                                name="APR"
                                rules={[
                                    {
                                        message: 'Please input your APR!',
                                    },
                                ]}
                            >
                                <Input type="text" placeholder="10%" />
                            </Form.Item>
                            <Form.Item className='singleField'
                                label="Term Length"
                                name="Term Length"
                                rules={[
                                    {
                                        message: 'Please input your Term Length',
                                    },
                                ]}
                            >
                                <Select
                                    defaultValue="72 months"
                                    options={[
                                        {
                                            value: '48 months',
                                            label: '48 months',
                                        },
                                        {
                                            value: '24 months',
                                            label: '24 months',
                                        },
                                        {
                                            value: '12 months',
                                            label: '12 months',
                                        },
                                    ]}
                                />
                            </Form.Item>
                        </Form>
                    </div>
                </Col>
                <Col className=""xs={{ span: 24 }} lg={{ span: 12 }}>
                    <VehiclePrice />
                </Col>
            </Row>
        </div>
    );
};

export default MonthlyPayment;