import React from 'react'
import ContactInfo from '../../components/contactInfo/ContactInfo'
import bgImg from '../../assets/images/contact.png'

const ContactCarbarn = () => {
  return (
      <ContactInfo title="Contact Us" hints="Welcome to carbarn" bgImg={bgImg}/>
  )
}

export default ContactCarbarn