import { Col, Row, Tooltip } from "antd";
import React from "react";
import OdometerHistoryIcon from "../../assets/images/odometer-icon.svg";
import RecallHistoryIcon from '../../assets/images/recall-icon.svg';
import WhatIcon from "../../assets/images/what-icon.svg";
import "./history.scss";

const History = ({singleDetails}) => {

  return (
    <div className="history">
      <Row style={{ gap: "20px" }}>
        <Col
          xs={{ span: 24 }}
          sm={{ span: 24 }}
          lg={{ span: 12 }}
          flex={1}
          style={{ padding: "0px" }}
        >
          <div className="card-wrapper">
            <div className="img-wrapper">
              <img className="img-fluid" src={OdometerHistoryIcon} alt="icon" />
            </div>
            <div className="details-wrapper">
              <h4>Odometer History</h4>
              <div className="text-with-icon">
                <h5 style={{ color: "#0C3A66" }}>
                  Guaranteed by Japan Car Assc.
                </h5>
                <span>
                  <img className="img-fluid" src={WhatIcon} alt="icon" />
                </span>
              </div>

              <hr className="divider" />
              <div className="history-item-wrapper">
                <div className="history-item">
                  <p>{singleDetails?.odometerHistory1Date || "N/A"}</p>
                  <h6>
                    {parseInt(singleDetails?.odometerHistory1)?.toLocaleString(
                      "en-AU"
                    ) || "N/A"}{" "}
                    {singleDetails?.odometerHistory1 && "KM"}
                  </h6>
                </div>
                <div className="history-item">
                  <p>{singleDetails?.odometerHistory2Date || "N/A"}</p>
                  <h6>
                    {parseInt(singleDetails?.odometerHistory2)?.toLocaleString(
                      "en-AU"
                    )}{" "}
                    {singleDetails?.odometerHistory2 && "KM"}
                  </h6>
                </div>
              </div>
            </div>
          </div>
        </Col>
        <Col
          xs={{ span: 24 }}
          sm={{ span: 24 }}
          lg={{ span: 12 }}
          flex={1}
          style={{ padding: "0px" }}
        >
          <div className="card-wrapper">
            <div className="img-wrapper">
              <img className="img-fluid" src={RecallHistoryIcon} alt="icon" />
            </div>
            <div className="details-wrapper">
              <h4>Recall History</h4>
              <div className="text-with-icon">
                <h5 style={{ color: "#0C3A66" }}>
                  This vehicle has passed 3 factory recalls.
                </h5>
                <span>
                  <img className="img-fluid" src={WhatIcon} alt="icon" />
                </span>
              </div>

              <hr className="divider" />

              <div className="history-item-wrapper">
                {singleDetails?.recallReport?.map((item) => (
                  <div className="history-item" key={item?.id}>
                    <p>{item?.Date}</p>
                    <Tooltip placement="bottomLeft" title={item["Recall Name"]}>
                      <p className="elipsis-text">{item["Recall Name"]}</p>
                    </Tooltip>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </Col>
      </Row>
    </div>
  );
};

export default History;
