import { Col, Row, Space } from "antd";
import axios from "axios";
import React from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import Instagram from "../../assets/images/Instagram.svg";
import Phone from "../../assets/images/call.svg";
import Facebook from "../../assets/images/facebook.svg";
import User from "../../assets/images/user.svg";
import Eye from "../../assets/images/view.svg";
import useAuth from "../../hooks/useAuth";
import "./topBar.scss";

const Topbar = () => {
  const { customer, dispatch } = useAuth();

  const location = useLocation();
  const navigate = useNavigate();

  if (location?.pathname === "/login" || location?.pathname === "/signup")
    return null;

  const handleLogout = async () => {
    try {
      await axios.post(
        `https://www.cbs.s1.importautos.com.au/carbarn/auth/v1/api/user/signout`,
        null
      );
      dispatch({ type: "LOGOUT" });
      navigate("/");
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <div
      className="header-top bg-clr-blue"
      data-aos="fade-down"
      data-aos-easing="ease-in-sine"
      data-aos-duration="1000"
    >
      <div className="container">
        <Row justify="space-between" align="middle">
          <Col></Col>
          <Col>
            <Space size={"large"}>
              <Link to="/recent">
                <Space>
                  <img src={Eye} alt="view" />
                  <p className="fs-13 text-white">Recently Viewed</p>
                </Space>
              </Link>
              <Link to="/contact">
                <Space>
                  <img src={Phone} alt="call" />
                  <p className="fs-13 text-white">Contact us</p>
                </Space>
              </Link>
              {customer?.email && (
                <Link to="/user">
                  <Space>
                    <img src={User} alt="user" />
                    <p className="fs-13 text-white">My Dashbord</p>
                  </Space>
                </Link>
              )}
              <>
                <Space>
                  <img src={User} alt="user" />
                  {!customer?.email && (
                    <p className="fs-13 text-white">
                      <Link to="/login" className="text-white">
                        Login
                      </Link>{" "}
                      /{" "}
                      <Link to="/signup" className="text-white">
                        Sign up
                      </Link>
                    </p>
                  )}
                  {customer?.email && (
                    <p className="text-white">
                      <Link to="/user" className="text-white">
                        {customer?.firstName} {customer?.lastName}
                      </Link>{" "}
                      /{" "}
                      <Link className="text-white" onClick={handleLogout}>
                        Logout
                      </Link>
                    </p>
                  )}
                </Space>
              </>

              <Space>
                <Link to="https://www.facebook.com/CarbarnAU/" target="_new">
                  <img src={Facebook} alt="facebook" />
                </Link>
                <Link to="https://www.instagram.com/carbarn_au/" target="_new">
                  <img src={Instagram} alt="Instagram" />
                </Link>
              </Space>
            </Space>
          </Col>
        </Row>
      </div>
    </div>
  );
};

export default Topbar;
