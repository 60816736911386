import { Col, Row, Space } from "antd";
import React from "react";
import MilageIcon from "../../assets/images/milage-icon.svg";
// import MilageIcon from "../../assets/images/milage-icon.svg";
import BodyIcon from "../../assets/images/body-icon.svg";
import DoorIcon from "../../assets/images/door-icon.svg";
import DriveIcon from "../../assets/images/drive-icon.svg";
import EngineIcon from "../../assets/images/engine-icon.svg";
import FinancingIcon from "../../assets/images/financing-icon.svg";
import FuelIcon from "../../assets/images/fuel-icon.svg";
import SeatIcon from "../../assets/images/seat-icon.svg";
import TransmissionIcon from "../../assets/images/transmission-icon.svg";

import { Link } from "react-router-dom";
import "./cardetailsTopFeature.scss";
import PurchaseOptions from "../purchaseOptions/PurchaseOptions";
import PurchaseOptionModal from "../purchaseJourneyItems/PurchaseOptionModal";

const FeaturedItem = ({ featureImg, featureTitle, feature, gradeNo }) => {
  return (
    <div className="feature-item">
      <div className="feature-img">
        {gradeNo ? (
          <div className="grade-wrapper">
            <p>{gradeNo}</p>
          </div>
        ) : (
          <img src={featureImg} alt="icon" className="img-fluid" />
        )}
      </div>
      <div className="feature-info">
        <p className="fs-12 text-dark3">{featureTitle}</p>
        <h5 className="fs-12 fw-800 text-dark2">{feature}</h5>
      </div>
    </div>
  );
};

const CardetailsTopFeature = ({ singleDetails }) => {
  const engineCC =
    (Math.ceil(singleDetails?.engineCapacity / 100) * 100) / 1000;

  return (
    <div className="top-feature">
      <p className="fs-14 text-dark3 fw-semi-bold mb-4 stockNo">
        Stock No: #{singleDetails?.stockNo}
      </p>
      <h1 className="fs-30">{singleDetails?.title}</h1>
      <div className="mb-20 flex-between-center">
        <div className="price">
          <h4>
            $
            {singleDetails?.salePrice
              ? parseInt(singleDetails?.salePrice).toLocaleString("en-AU")
              : "N/A"}
          </h4>
          <p className="fs-14">Excl. Govt. Charges</p>
        </div>
        <div className="finance-link">
          <img src={FinancingIcon} alt="icon" />
          <PurchaseOptionModal
            btnText="See financing options"
            title="Carbarn Financing"
            finance={true}
          />
        </div>
      </div>

      <div className="mobile-purchase-option">
        <PurchaseOptions singleDetails={singleDetails} />
      </div>

      <Row align="middle" gutter={[15, 20]} className="feature-item-wrapper">
        <Col xs={{ span: 12 }} md={{ span: 8 }} p>
          <FeaturedItem
            featureImg={MilageIcon}
            featureTitle="Mileage"
            feature={`${
              singleDetails?.odometer
                ? parseInt(singleDetails?.odometer).toLocaleString("en-AU")
                : "N/A"
            } ${singleDetails?.odometer && "KM"}`}
          />
        </Col>
        <Col xs={{ span: 12 }} md={{ span: 8 }}>
          <FeaturedItem
            featureImg={FuelIcon}
            featureTitle="Fuel"
            feature={singleDetails?.fuel ? singleDetails?.fuel : "Desel"}
          />
        </Col>
        <Col xs={{ span: 12 }} md={{ span: 8 }}>
          <FeaturedItem
            featureImg={EngineIcon}
            featureTitle="Engine Size"
            feature={`${engineCC} L` || "N/A"}
          />
        </Col>
        <Col xs={{ span: 12 }} md={{ span: 8 }}>
          <FeaturedItem
            featureImg={TransmissionIcon}
            featureTitle="Transmission"
            feature={
              singleDetails?.transmission === "AT"
                ? "Automatic"
                : singleDetails?.transmission
            }
          />
        </Col>
        <Col xs={{ span: 12 }} md={{ span: 8 }}>
          <FeaturedItem
            featureImg={DriveIcon}
            featureTitle="Drive Type"
            feature={
              singleDetails?.driveTrain ? singleDetails?.driveTrain : "4wd"
            }
          />
        </Col>
        <Col xs={{ span: 12 }} md={{ span: 8 }}>
          <FeaturedItem
            featureImg={BodyIcon}
            featureTitle="Body"
            feature={singleDetails?.bodyType ? singleDetails?.bodyType : "Van"}
          />
        </Col>
        <Col xs={{ span: 12 }} md={{ span: 8 }}>
          <FeaturedItem
            featureImg={SeatIcon}
            featureTitle="Seats"
            feature={`${singleDetails?.seats ? singleDetails?.seats : "7"}`}
          />
        </Col>
        <Col xs={{ span: 12 }} md={{ span: 8 }}>
          <FeaturedItem
            featureImg={DoorIcon}
            featureTitle="Door"
            feature={`${
              singleDetails?.doors ? singleDetails?.doors : "5"
            } -Door`}
          />
        </Col>
        {singleDetails?.auctionGrade && (
          <Col xs={{ span: 12 }} md={{ span: 8 }}>
            <FeaturedItem
              featureImg={SeatIcon}
              featureTitle="Auction Grade"
              feature={`${
                singleDetails?.auctionGrade > 4
                  ? "Excellent Condition"
                  : "Good Condition"
              }`}
              gradeNo={singleDetails?.auctionGrade}
            />
          </Col>
        )}
      </Row>
    </div>
  );
};

export default CardetailsTopFeature;
