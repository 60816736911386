/* eslint-disable jsx-a11y/iframe-has-title */
import { GoogleMap, Marker } from "@react-google-maps/api";
import { Radio } from "antd";
import moment from "moment";
import React from "react";
import { Link } from "react-router-dom";
import CloseIcon from "../../assets/images/close-icon.svg";
import EditIcon from "../../assets/images/edit-icon.svg";
import usePurchase from "../../hooks/usePurchase";
import ModalCalendar from "../nextAction/ModalCalendar";
import CustomTimePicker from "../purchaseJourneyItems/CustomTimePicker";
import DoorDeliveryMap from "../purchaseJourneyItems/DoorDeliveryMap";
import ReviewUserDetails from "../purchaseJourneyItems/ReviewUserDetails";

const PurchaseStep4 = ({ singleDetails }) => {
  const {
    formData,
    setFormData,
    user,
    editUser,
    setEditUser,
    handleChange,
    isAM,
    editDeliveryOption,
    setEditDeliveryOption,
    directionsResponse,
    center,
    distance,
    map,
  } = usePurchase();

  const totalPrice =
    parseInt(singleDetails?.salePrice) + Math.ceil(distance / 1000) * 2;

  const fivePercentOfTotal = Math.ceil(0.05 * totalPrice);

  const onChange = (e) => {
    console.log("radio checked", e.target.value);
    setFormData({
      ...formData,
      deliveryOption: e.target.value,
    });
  };

  return (
    <div className="step-wrapper">
      <div className="step-header">
        <h6>Contact details</h6>
        <div className="step-header-btn-wrapper">
          {!editUser && (
            <button
              className="step-header-btn"
              onClick={() => setEditUser(true)}
            >
              <img src={EditIcon} alt="icon" className="img-fluid" />
            </button>
          )}
          {editUser && (
            <button
              className="step-header-btn"
              onClick={() => setEditUser(false)}
            >
              <img src={CloseIcon} alt="icon" className="img-fluid" />
            </button>
          )}
        </div>
      </div>
      <ReviewUserDetails />

      <div className="step-header mt-30">
        <h6>Delivery Selection</h6>
        <div className="step-header-btn-wrapper">
          {!editDeliveryOption && (
            <button
              className="step-header-btn"
              onClick={() => setEditDeliveryOption(true)}
            >
              <img src={EditIcon} alt="icon" className="img-fluid" />
            </button>
          )}
          {editDeliveryOption && (
            <button
              className="step-header-btn"
              onClick={() => setEditDeliveryOption(false)}
            >
              <img src={CloseIcon} alt="icon" className="img-fluid" />
            </button>
          )}
        </div>
      </div>

      {!editDeliveryOption && (
        <p className="fs-16 text-dark3 ">
          {formData?.deliveryOption === "self pickup"
            ? "Pick-up from Carbarn HQ"
            : formData?.address}
        </p>
      )}

      {editDeliveryOption && (
        <>
          <div className="option-wrapper mb-30" style={{ textAlign: "left" }}>
            <Radio.Group
              onChange={onChange}
              value={formData.deliveryOption}
              size="large"
            >
              <Radio
                className="text-dark3 fs-16 fw-semi-bold"
                value="door delivery"
                checked
              >
                I want door delivery.{" "}
                <Link
                  className="fw-bold text-dark3"
                  style={{ textDecoration: "underline" }}
                >
                  See prices.
                </Link>
              </Radio>
              <Radio
                className="text-dark3 fs-16 fw-semi-bold"
                value="self pickup"
              >
                I will pick up myself.{" "}
                <Link
                  className="fw-bold text-dark3"
                  style={{ textDecoration: "underline" }}
                >
                  See details.
                </Link>
              </Radio>
            </Radio.Group>
          </div>

          {formData?.deliveryOption === "door delivery" && (
            <div>
              <h5 className="fs-16 text-dark1 fw-800 mb-20">
                Set your address
              </h5>

              <DoorDeliveryMap />

              <div className="date-time mt-40">
                <div className="step-calender">
                  <h5 className="fs-16 text-dark1 fw-800 mb-20">
                    Set schedule
                  </h5>
                  <ModalCalendar />
                </div>

                <div className="time-picker">
                  <h5 className="fs-16 text-dark1 fw-800 mb-50">Time:</h5>
                  <CustomTimePicker />
                </div>
              </div>

              <div className="cost mt-30">
                <h5 className="fs-16 text-dark1 fw-800 mb-20">Summary</h5>
                <div className="item-wise-cost">
                  <p className="fs-12 text-dark3">Address:</p>
                  <h6 className="fs-14 fw-800 text-dark3">
                    {formData?.address ? formData?.address : "......"}
                  </h6>
                </div>
                <div className="item-wise-cost">
                  <p className="fs-12 text-dark3">Date: :</p>
                  <h6 className="fs-14 fw-800 text-dark3">
                    {formData?.date && (
                      <>{moment(formData?.date).format("dddd MMMM DD")},</>
                    )}{" "}
                    {formData?.time && (
                      <>
                        {formData?.time} {isAM ? "AM" : "PM"}
                      </>
                    )}
                  </h6>
                </div>

                <div className="border-gray mb-15"></div>

                <div className="item-wise-cost">
                  <p className="fs-16 fw-800 text-dark1">Delivery Cost :</p>
                  <h6 className="fs-16 fw-800 text-dark1">$500</h6>
                </div>
              </div>
            </div>
          )}

          {formData?.deliveryOption === "self pickup" && (
            <>
              <GoogleMap
                center={center}
                zoom={15}
                mapContainerStyle={{ width: "100%", height: "300px" }}
                options={{
                  zoomControl: false,
                  streetViewControl: false,
                  mapTypeControl: false,
                  fullscreenControl: false,
                }}
                // onLoad={(map) => setMap(map)}
              >
                <Marker position={center} />
                {/* {directionsResponse && (
                  <DirectionsRenderer directions={directionsResponse} />
                )} */}
              </GoogleMap>

              <Link className="banner-btn px-40 fs-16 text-dark1 bg-clr-skyblue w-100 mt-30">
                Get directions
              </Link>
            </>
          )}
        </>
      )}

      <div className="payment-tooltip">
        {/* <h5 className="fs-16 text-dark1 fw-800 mt-30 mb-30">
          Purchase Options
          <span className="mx-10">
            <Tooltip
              className="payment-tooltip"
              color="#fffedf"
              placement="right"
              title="Questions? Please contact us. "
            >
              <img src={ToolTipIcon} alt="icon" className="img-fluid" />
            </Tooltip>
          </span>
        </h5> */}

        <div className="my-20" style={{ alignItems: "flex-start" }}>
          <div>
            <h5 className="fs-18 text-dark1 fw-800 mb-20">Payment Summary </h5>

            {/* <div className="border-gray mb-15"></div> */}

            <div className="price-summary" style={{ flexDirection: "column", gap:"10px" }}>
              <div className="price-summary-list flex-between-center w-100">
                <p>Vehicle Base Price</p>
                <h6>${parseInt(singleDetails?.salePrice?.toLocaleString('en-AU'))}</h6>
              </div>
              <div className="price-summary-list flex-between-center w-100">
                <p>Delivery Cost</p>
                <h6>${Math.ceil((distance / 1000) * 2)?.toLocaleString('en-AU')}</h6>
              </div>
            </div>

            <div className="border-gray my-20 "></div>

            <div className="flex-between-center w-100">
              <p className="fs-16 text-dark3 mb-10">Total Price:</p>
              <h3 className="fs-40 text-blue">${totalPrice?.toLocaleString('en-AU')}</h3>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PurchaseStep4;
