import React, { useState } from "react";
import SwiperCore, { FreeMode, Navigation, Thumbs } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import { Link } from "react-router-dom";
import TestDriveCalender from "../../assets/images/test-drive-calender.svg";
import "swiper/swiper-bundle.min.css";
import "./cardetailsSlider.scss";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { Image } from "antd";

SwiperCore.use([Navigation, Thumbs]);

const CardetailsSlider = ({ singleDetails }) => {
  
  const exteriorImages = singleDetails?.exteriorPhoto;
  const interiorImages = singleDetails?.interiorPhoto;
  const imparfectionsImages = singleDetails?.imperfectionPhoto;

  const [photos, setPhotos] = useState(exteriorImages);

  const modalTitle = "Schedule Your Virtual Inspection or On-Site Test Drive";

  return (
    <>
      <Swiper
        style={{
          "--swiper-navigation-color": "#fff",
          "--swiper-pagination-color": "#fff",
        }}
        loop={true}
        autoplay={true}
        spaceBetween={20}
        navigation={true}
        modules={[FreeMode, Navigation, Thumbs]}
        className="car-gallery"
      >
        {photos?.map((image, index) => {
          return (
            <SwiperSlide key={index}>
              <div className="img-wrapper">
                {/* <img src={image} alt="img" className="img-fluid" width={"100%"} /> */}
                {/* <LazyLoadImage
                  alt="Car Image"
                  effect="blur"
                  src={image}
                  className="img-fluid"
                  width={"100%"}
                /> */}
                <Image
                  width={"100%"}
                  src={image}
                />
              </div>
            </SwiperSlide>
          );
        })}
      </Swiper>
      <div className="thumbnail-tab">
        <div className="thumbnail-tab-wrapper">
          <div className="tab-item" onClick={() => setPhotos(exteriorImages)}>
            <div className="tab-item-img exterior"></div>
            <h6>
              Exterior <br /> Photos
            </h6>
          </div>
          <div className="tab-item" onClick={() => setPhotos(interiorImages)}>
            <div className="tab-item-img interior"></div>
            <h6>
              Interior <br /> Photos
            </h6>
          </div>
          <div
            className="tab-item"
            onClick={() => setPhotos(imparfectionsImages)}
          >
            <div className="tab-item-img imperfections"></div>
            <h6>Imperfections</h6>
          </div>
          <Link
            className={`${singleDetails.isOnOffer && "not-allowed"} tab-item test-drive-tab`}
            to={!singleDetails?.isOnOffer && `/${singleDetails?.make}/${singleDetails?.trackId}/test-drive`}
          >
            <div className="tab-item-img test-drive">
              <img
                src={TestDriveCalender}
                alt="icon"
                className="img-fluid"
                width={42}
              />
              <p className="">
                Book Online <br /> Inspection <br /> Or Test Drive
              </p>
            </div>
            <p className="mobile-text">
              Book Online <br /> Inspection <br /> Or Test Drive
            </p>
          </Link>
        </div>
      </div>
    </>
  );
};

export default CardetailsSlider;
