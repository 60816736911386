import React from 'react';
import carFinanceImg from "../../assets/images/carfinenceImg.png";
import commonBannerBg from "../../assets/images/commonBannerBg.jpg";
import CommonBanner from '../../components/commonBanner/CommonBanner';
import Faq from '../../components/faq/Faq';
import FinanceCalculator from '../../components/financeCalculator/FinanceCalculator';
import useFetch from '../../hooks/useFetch';
import './carFinance.scss';




const CarFinance = () => {

    const { data } = useFetch("https://script.google.com/macros/s/AKfycbxQOdiy0DPw51py3RhEaGjjZlLMcv8_FPDoqKJMaKoAeKP4KY5v3nc_Z3suN4LY0J-8/exec");

    const financeFaq = data.filter((item) => item.page === "Finance");

    return (
        <>
            <CommonBanner
                sectionBg={commonBannerBg}
                sectionTag={"credit score"}
                sectionCaption={`Obtain financing results without affecting your <b>credit score</b>`}
                sectionIntro={"Amet minim mollit non deserunt ullamco est sit aliqua dolor do amet sint. Velit officia consequat duis enim velit mollit"}
                sectionImg={carFinanceImg}
                addRowclassName={"d-xs-block"}
                spaceing={"pb-100 pt-20"}
            />
            
            <FinanceCalculator />

            <Faq faqTitle="FAQS about vehicle <b>financing</b>" faqData={financeFaq} />
        </>
    );
};

export default CarFinance;