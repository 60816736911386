/* eslint-disable react/prop-types */

import { createContext, useEffect, useReducer } from "react";
import useFetch from "../hooks/useFetch";

const INITIAL_STATE = {
  customer: JSON.parse(localStorage.getItem("customer")) || null,
  wishlist:[],
  loading: false,
  error: null,
};

export const AuthContext = createContext(INITIAL_STATE);

const AuthReducer = (state, action) => {
  switch (action.type) {
    case "LOGIN_START":
      return {
        customer: null,
        loading: true,
        error: null,
      };

    case "LOGIN_SUCCESS":
      return {
        customer: action.payload,
        loading: false,
        error: null,
      };

    case "LOGIN_FAILURE":
      return {
        customer: null,
        loading: false,
        error: action.payload,
      };

    case "LOGOUT":
      return {
        customer: null,
        loading: false,
        error: null,
      };

    default:
      return state;
  }
};

export const AuthProvider = ({ children }) => {

  const [state, dispatch] = useReducer(AuthReducer, INITIAL_STATE);

  // const {data:customerData} = useFetch('https://www.cbs.s1.importautos.com.au/carbarn/user/v1/api/customer/');

  useEffect(() => {
    localStorage.setItem("customer", JSON.stringify(state.customer));
  }, [state.customer]);

  const value = {
    customer: state.customer,
    wishlist: state.wishlist,
    loading: state.loading,
    error: state.error,
    dispatch,
  };

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};
