import { Form, Input } from "antd";
import axios from "axios";
import React, { useState } from "react";
import { toast } from "react-toastify";
import CloseIcon from "../../assets/images/close-icon.svg";
import EditIcon from "../../assets/images/edit-icon.svg";
import { ReactComponent as LockIcon } from "../../assets/images/lock.svg";
import useAuth from "../../hooks/useAuth";

const UpdatePassword = () => {
  const { customer } = useAuth();

  const [updatePassword, setUpdatePassword] = useState(false);
  const [passwordData, setPasswordData] = useState({
    oldPassword: "",
    newPassword: "",
    retypeNewPassword: "",
  });

  const handlePasswordChange = (e) => {
    const type = e.target.type;

    const name = e.target.name;

    const value = type === "file" ? e.target.files[0] : e.target.value;

    setPasswordData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const updatedData = {
      id: customer?.id,
      oldPassword: passwordData?.oldPassword,
      newPassword: passwordData?.newPassword,
      reTypePassword: passwordData?.retypeNewPassword,
    };

    try {
      await axios.patch(
        "https://www.cbs.s1.importautos.com.au/carbarn/auth/v1/api/user/password/update",
        updatedData
      );

      toast.success("Password updated successfully");
      setPasswordData({});
      
    } catch (error) {
      console.error("Error for Password update:", error);
      // setSignupResult(error?.response?.data);
      toast.error("Password must be consists of one capital letter and a small letter and a special character and minimum length must be 8");
    }
  };

  return (
    <div>
      <div className="step-header">
        <h6>Change Password</h6>
        {customer?.email && (
          <div className="step-header-btn-wrapper">
            {!updatePassword && (
              <button
                className="step-header-btn"
                onClick={() => setUpdatePassword(true)}
              >
                <img src={EditIcon} alt="icon" className="img-fluid" />
              </button>
            )}
            {updatePassword && (
              <button
                className="step-header-btn"
                onClick={() => setUpdatePassword(false)}
              >
                <img src={CloseIcon} alt="icon" className="img-fluid" />
              </button>
            )}
          </div>
        )}
      </div>

      {updatePassword && (
        <form onSubmit={handleSubmit}>
          <Form.Item
            rules={[
              {
                required: true,
                message: "Please enter your old password!",
              },
            ]}
          >
            <Input.Password
              prefix={<LockIcon />}
              placeholder="Enter Your Old Password"
              name="oldPassword"
              value={passwordData?.oldPassword}
              onChange={handlePasswordChange}
              required
            />
          </Form.Item>
          <Form.Item
            rules={[
              {
                required: true,
                message: "Please enter your new password!",
              },
            ]}
          >
            <Input.Password
              prefix={<LockIcon />}
              placeholder="Enter Your New Password"
              name="newPassword"
              value={passwordData?.newPassword}
              onChange={handlePasswordChange}
              required
            />
          </Form.Item>
          <Form.Item
            rules={[
              {
                required: true,
                message: "Please retype your password!",
              },
            ]}
          >
            <Input.Password
              prefix={<LockIcon />}
              placeholder="Retype Your Password"
              name="retypeNewPassword"
              value={passwordData?.retypeNewPassword}
              onChange={handlePasswordChange}
              required
            />
          </Form.Item>

          <button
            type="submit"
            className="yes-no-btn"
            style={{ margin: "0 auto" }}
          >
            Save Password
          </button>
        </form>
      )}
    </div>
  );
};

export default UpdatePassword;
