/* eslint-disable react-hooks/exhaustive-deps */
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import { Spin } from "antd";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import useFetch from "../../hooks/useFetch";
import usePurchase from "../../hooks/usePurchase";
import PurchaseJourney from "../purchaseJourney/PurchaseJourney";

const StraipePurchaseJourney = () => {
  const {
    paymentIntent,
    setPaymentIntent,
    distance,
    payment,
    card,
    customAmount,
    isPaymentElementEmpty,
  } = usePurchase();

  const [clientSecret, setClientSecret] = useState(null);
  const [stripe, setStripe] = useState(null);

  const location = useLocation();

  const carId = location?.pathname.split("/")[2];

  const { data: singleDetails, loading } = useFetch(
    `https://www.cbs.s1.importautos.com.au/product-index/v1/api/vehicle/chassis/${carId}`
  );

  const stripeClientSecret = process.env.REACT_APP_STRIPE_CLIENT_SECRET;

  const stripePublicKey = process.env.REACT_APP_STRIPE_PUBLIC_KEY;

  const totalPrice =
    parseInt(singleDetails?.salePrice) + Math.ceil(distance / 1000) * 2;

  const fivePercentOfTotal = Math.ceil(0.05 * totalPrice);

  const userCustomAmount = customAmount + Math.ceil(distance / 1000) * 2;

  let amount =
    card === "Visa Card Payment" && payment === 1
      ? totalPrice
      : card === "Visa Card Payment" && payment === 2
      ? fivePercentOfTotal
      : userCustomAmount >= fivePercentOfTotal
      ? customAmount
      : fivePercentOfTotal;

  const createPaymentIntent = async () => {
    const paymentData = {
      amount: !isPaymentElementEmpty ? amount * 100 : 100,
      currency: "aud",
      capture_method: "manual",
    };

    try {
      const response = await axios.post(
        "https://api.stripe.com/v1/payment_intents",
        paymentData,
        {
          headers: {
            Authorization: `Bearer ${stripeClientSecret}`,
            "Content-Type": "application/x-www-form-urlencoded",
          },
        }
      );

      setClientSecret(response?.data?.client_secret);
      setPaymentIntent(response?.data);
    } catch (error) {
      console.error("Error creating PaymentIntent:", error);
    }
  };

  useEffect(() => {
    // Load the Stripe.js library
    const stripePromise = loadStripe(stripePublicKey);

    // Create a new Stripe object
    stripePromise.then((stripe) => setStripe(stripe));

    createPaymentIntent();
  }, [!isPaymentElementEmpty]);

  // console.log(fivePercentOfTotal);
  // console.log(paymentIntent?.amount);
  // console.log(totalPrice);

  return (
    <>
      {clientSecret ? (
        <Elements stripe={stripe} options={{ clientSecret }}>
          <PurchaseJourney
            singleDetails={singleDetails}
            carId={carId}
            clientSecret={clientSecret}
            stripe={stripe}
            amount={amount}
          />
        </Elements>
      ) : (
        <div
          className="loader text-center"
          style={{ marginTop: "350px", marginBottom: "350px" }}
        >
          <Spin />
        </div>
      )}
    </>
  );
};

export default StraipePurchaseJourney;
