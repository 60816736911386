import { Col, Row } from "antd";
import React from "react";
import FeatureIcon1 from "../../assets/images/home-feature-1.svg";
import FeatureIcon2 from "../../assets/images/home-feature-2.svg";
import FeatureIcon3 from "../../assets/images/home-feature-3.svg";
import FeatureIcon4 from "../../assets/images/home-feature-4.svg";
import "./homeFeature.scss";

const HomeFeature = () => {

  const featureData = [
      {
          img: FeatureIcon1,
          title: "5-Years Warranty",
          desc: "Maximum Claim value is $3000 and allow for multiple claims, up to the total cost of your car, within the specified time fram, providing complete coverage and peace of mind.",
      },

      {
          img: FeatureIcon2,
          title: "24/7 Roadside Assistance",
          desc: "All of our vehicles also come with an added feature of  years of 24/7 roadside assistance provided by AWN all over Australia, giving you peace of mind wherever you may be in the country",
      },

      {
          img: FeatureIcon3,
          title: "Fast Door Delivery",
          desc: "Pick up delivery options available. Just like anytime else you buy online. your car is home-delivered carbarn will guide you through a complete handover",
      },

      {
          img: FeatureIcon4,
          title: "Easy Pre-Approval",
          desc: "With our partner lender, you can easily and confidently apply for an auto pre-approval without any concerns about your credit score being affected",
      },
  ];


    return (
        <section className="banner-bottom py-4">
            <div className="container">
                <Row gutter={[24, 24]} justify="space-between" >
                    {featureData.map((feature, index) => {
                        return (
                          <Col
                            xs={{ span: 24 }}
                            sm={{ span: 12 }}
                            lg={{ span: 6 }}
                            key={index}
                          >
                            <div
                              className="banner-bottom-content"
                              data-aos="fade-up"
                              data-aos-easing="ease-in-sine"
                              data-aos-duration="1000"
                            >
                              <div className="imh-box">
                                <img
                                  src={feature.img}
                                  className="img-fluid mb-10"
                                  alt="img"
                                />
                              </div>
                              <h4 className="fs-18 fw-800 text-dark1 mb-10">
                                {feature.title}
                              </h4>
                            </div>
                          </Col>
                        );
                    })}
                </Row>
            </div>
        </section>
    );
};

export default HomeFeature;
