import { Col, Row } from "antd";
import React from "react";
import Logo from "../../assets/images/carbarn-logo.svg";
import ServiceIcon1 from "../../assets/images/service-item1.svg";
import ServiceIcon2 from "../../assets/images/service-item2.svg";
import ServiceIcon3 from "../../assets/images/service-item3.svg";
import ServiceIcon4 from "../../assets/images/service-item4.svg";
import "./whyCarbarn.scss";

const WhyCarbarn = () => {
  const featureData = [
    {
      img: ServiceIcon1,
      title: "5-Years Warranty",
      desc: "Experience peace of mind with a 5-Years warranty, featuring a $3,000 max claim value and multiple claims up to your vehicle's cost for total coverage",
    },

    {
      img: ServiceIcon2,
      title: "24/7 Roadside Assistance",
      desc: "Each vehicle we provide comes with 24/7 roadside assistance, ensuring support and peace of mind across Australia, regardless of where your travels lead",
    },

    {
      img: ServiceIcon3,
      title: "Fast Door Delivery",
      desc: "Enjoy convenient pick-up and delivery options for your vehicle purchase. With our fast door delivery service, your car is brought right to your home. CarBarn ensures a smooth and complete handover, just like any other online purchase",
    },

    {
      img: ServiceIcon4,
      title: "Easy Pre-Approval",
      desc: "Take advantage of our completely online pre-approval process, which is designed to be quick and worry-free. Secure financing for your vehicle with ease and without concerns about your credit score being affected.",
    },
  ];

  return (
    <section className="why-carbarn bg-clr-skyblue pt-100 pb-100">
      <div className="container">
        <div className="text-center mb-50">
          <div className="mx-auto" style={{ width: "130px" }}>
            <img src={Logo} className="img-fluid mb-15" alt="logo" />
          </div>
          <h2 className="text-dark1 fs-48 text-center">
            Why <span className="fw-800">Carbarn </span>
          </h2>
        </div>
        <Row gutter={[20, 20]} justify="space-between">
          {featureData.map((feature, index) => {
            return (
              <Col
                xs={{ span: 24 }}
                md={{ span: 12 }}
                lg={{ span: 6 }}
                key={index}
              >
                <div className="why-carbarn-item text-center h-100">
                  <img src={feature.img} className="service" alt="icon" />
                  <h4 className=" text-dark1 fs-24 fw-semi-bold my-20">
                    {feature.title}
                  </h4>
                  <p className="fs-16 text-dark3 lh-24">{feature.desc}</p>
                </div>
              </Col>
            );
          })}
        </Row>
      </div>
    </section>
  );
};

export default WhyCarbarn;
