
import { DirectionsRenderer, GoogleMap, Marker } from "@react-google-maps/api";
import { Form, Input } from "antd";
import React, { useEffect, useState } from "react";
import { ReactComponent as LocationIcon } from "../../assets/images/location.svg";
import usePurchase from "../../hooks/usePurchase";
import useFetch from "../../hooks/useFetch";

const DoorDeliveryMap = () => {
  const {
    formData,
    setFormData,
    handleChange,
    isAM,
    distance,
    setDistance,
    directionsResponse,
    setDirectionsResponse,
    center,
    isLoaded,
    loadError,
  } = usePurchase();

  const [map, setMap] = useState(null);
  // const [duration, setDuration] = useState("");

  const {data:deliveryCost} = useFetch(
    `https://www.cbs.s1.importautos.com.au/carbarn/user/v1/api/geolocation/geocode?address=${formData?.doorDeliveryAddress}`
  );

  console.log(deliveryCost);



  useEffect(() => {
    if (loadError) {
      console.error("Error loading Google Maps API:", loadError);
      return;
    }

    if (isLoaded) {
      const getDistance = async () => {
        try {
          const directionsService = new window.google.maps.DirectionsService();
          const results = await directionsService.route({
            origin: "136 Frances Street, Lidcombe NSW, Australia",
            destination: formData?.doorDeliveryAddress,
            travelMode: window.google.maps.TravelMode.DRIVING,
          });
          setDirectionsResponse(results);
          setDistance(results.routes[0].legs[0].distance.value);
          // setDuration(results.routes[0].legs[0].duration.text);
          
        } catch (err) {
          console.error(err);
        }
      };
      getDistance();
    }

if (directionsResponse) {
  // Access the destination coordinates from the directions response
  const destinationLatitude =
    directionsResponse.routes[0].legs[0].end_location.lat();
  const destinationLongitude =
    directionsResponse.routes[0].legs[0].end_location.lng();
  
    setFormData((prevData) => ({
      ...prevData,
      deliveryCost: Math.ceil(distance / 1000) * 2,
      destinationLat: destinationLatitude,
      destinationLong: destinationLongitude,
    }));
}

  }, [formData?.doorDeliveryAddress, setDirectionsResponse, setDistance, isLoaded, loadError]);



  return (
    <>
      <Form.Item
        rules={[
          {
            required: true,
            message: "Please input your address!",
          },
        ]}
      >
        <Input
          name="doorDeliveryAddress"
          prefix={<LocationIcon />}
          placeholder="Input address please"
          type="text"
          value={formData?.doorDeliveryAddress}
          onChange={handleChange}
          allowClear
        />
      </Form.Item>

      <div>
        {!isLoaded ? (
          "Loading"
        ) : (
          <GoogleMap
            center={center}
            zoom={15}
            mapContainerStyle={{ width: "100%", height: "300px" }}
            options={{
              zoomControl: false,
              streetViewControl: false,
              mapTypeControl: false,
              fullscreenControl: false,
            }}
            onLoad={(map) => setMap(map)}
          >
            {formData?.doorDeliveryAddress && <Marker position={center} />}
            {directionsResponse && (
              <DirectionsRenderer directions={directionsResponse} />
            )}
          </GoogleMap>
        )}
      </div>
    </>
  );
};

export default DoorDeliveryMap;
