import { Col, Row } from 'antd';
import React from 'react';
import WarrantyCar from "../../assets/images/5_years_warranty.png";
import './warrantyBanner.scss';

const WarrantyBanner = () => {
  return (
      <section className="waranty-wrapper">
          <div className="container">
              <div className="socket-list-title">
                  <Row align="middle" justify="space-between" gutter={[50, 30]}>
                      <Col xs={{ span: 24 }} lg={{ span: 12 }}>
                          <div className="waranty-left hybrid-vehical">
                              <div className="vehical-content " data-aos="zoom-in" data-aos-easing="linear" data-aos-duration="1000">
                                  <p className="vehical-hints fs-12 fw-800 mb-10">All of our vehicles are under</p>
                                  <div className="text-start">
                                      <h1 className="text-dark1 fs-110 fw-800 mb-15">5-Years warranty</h1>
                                      <div className="common-title-pra">
                                          <p className="text-dark1 fs-48 lh-58 ">against mechanical failure across the country</p>
                                      </div>

                                      <p className="text-dark3 fs-18 lh-24 mt-20">Experience peace of mind while driving every kilometre with AWN</p>
                                  </div>
                              </div>
                          </div>
                      </Col>
                      <Col xs={{ span: 24 }} lg={{ span: 12 }}>
                          <div className="waraty-car" data-aos="flip-left" data-aos-easing="ease-out-cubic" data-aos-duration="1000">
                              <img src={WarrantyCar} className="img-fluid" alt="img" />
                          </div>
                      </Col>
                  </Row>
              </div>
          </div>
      </section>
  );
};

export default WarrantyBanner;