import { Tabs } from "antd";
import axios from "axios";
import React from "react";
import { toast } from "react-toastify";
import CloseIcon from "../../assets/images/close-icon.svg";
import EditIcon from "../../assets/images/edit-icon.svg";
import UserInfoForm from "../../components/purchaseJourneyItems/UserInfoForm";
import UpdatePassword from "../../components/updatePassword/UpdatePassword";
import useAuth from "../../hooks/useAuth";
import useFetch from "../../hooks/useFetch";
import usePurchase from "../../hooks/usePurchase";
import PurchaseHistoryTable from "./PurchaseHistoryTable";
import "./user.scss";

const User = () => {
  const { customer } = useAuth();
  
  const { formData,setFormdata, editUser, setEditUser } = usePurchase();

  const { data: salesData } = useFetch(
    "https://www.cbs.s1.importautos.com.au/carbarn/v1/api/sales/"
  );

  const userSalesData = salesData?.resultList?.filter(
    (item) => item?.customer?.email === customer?.email
  );

  const handleUpdateCustomer = async (e) => {

    e.preventDefault();

    const customerInfo = {
      id: customer?.id,
      firstName:formData?.firstName,
      lastName:formData?.lastName,
      mobileNumber:formData?.phoneNo,
      drivingLicenseNumber:formData?.dlNo,
      licenseExpiry:formData?.dlExpairDate,
      address:formData?.address
    };

    try {
      await axios.patch(
        "https://www.cbs.s1.importautos.com.au/carbarn/user/v1/api/customer/update",
        customerInfo
      );

      toast.success("User details updated successfully");
      setFormdata({});
    } catch (error) {
      console.error("Error for customer update:", error);
      toast.error("Update failed");
    }
  };


  return (
    <section className="user">
      <div className="container">
        <div className="user-tab-wrapper">
          <Tabs centered className="full-width-tab mt-50 ">
            <Tabs.TabPane tab="Purchase History" key="Purchase history">
              <div className="tab-header mt-15">
                <h3 className="fs-24 fw-800 text-dark1 mb-10">Hi</h3>
                <p className="fs-14 fw-normal text-dark3">
                  {customer?.firstName} {customer?.lastName}
                </p>
              </div>
              <PurchaseHistoryTable userSalesData={userSalesData} />
            </Tabs.TabPane>
            <Tabs.TabPane tab="Personal Details" key="Personal details">
              {/* <div className="tab-header mt-15">
                <h3 className="fs-24 fw-800 text-dark1 mb-40">
                  Personal Information
                </h3>
              </div> */}
              <div
                className="step-wrapper"
                style={{
                  maxWidth: "800px",
                  width: "100%",
                  margin: "20px auto",
                }}
              >
                <div className="step-header">
                  <h6>Contact details</h6>
                  {customer?.email && (
                    <div className="step-header-btn-wrapper">
                      {!editUser && (
                        <button
                          className="step-header-btn"
                          onClick={() => setEditUser(true)}
                        >
                          <img
                            src={EditIcon}
                            alt="icon"
                            className="img-fluid"
                          />
                        </button>
                      )}
                      {editUser && (
                        <button
                          className="step-header-btn"
                          onClick={() => setEditUser(false)}
                        >
                          <img
                            src={CloseIcon}
                            alt="icon"
                            className="img-fluid"
                          />
                        </button>
                      )}
                    </div>
                  )}
                </div>

                <form className="mb-40" onSubmit={handleUpdateCustomer}>
                  <UserInfoForm />
                  {editUser && (
                    <button
                      type="submit"
                      className="yes-no-btn"
                      style={{ margin: "0 auto" }}
                    >
                      Save User Details
                    </button>
                  )}
                </form>

                <UpdatePassword />
              </div>
            </Tabs.TabPane>
          </Tabs>
        </div>
      </div>
    </section>
  );
};

export default User;
