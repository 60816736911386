import { Col, Row, Space, Spin } from "antd";
import React, { useState } from "react";
import { Link } from "react-router-dom";
import CallToAction from "../../components/callToAction/CallToAction";
import CommonSelect from "../../components/commonSelect/CommonSelect";
import StockListItem from "../../components/stockListItem/StockListItem";
import useFilter from "../../hooks/useFilter";

const StockList = () => {
  const {
    make,
    setMake,
    setModel,
    makeOptions,
    modelOptions,
    newData,
    loading,
  } = useFilter();

  const [itemsPerPage, setItemsPerPage] = useState(8);

  const modelData = modelOptions?.filter((options) => options.make === make);

  const uniqueModelOptions = modelData?.filter((item, index) => {
    return (
      index ===
      modelData.findIndex((obj) => {
        return obj.value === item.value;
      })
    );
  });

  const uniqueMakeOptions = makeOptions?.filter((item, index) => {
    return (
      index ===
      makeOptions.findIndex((obj) => {
        return obj.value === item.value;
      })
    );
  });

  return (
    <>
      <section className="stocklist-banner banner pb-100">
        <div className="container">
          <div className="bannerContent pt-100 mb-50">
            <Row justify="center">
              <Col span={24}>
                <div
                  className="bannerInfo text-center"
                  data-aos="zoom-in"
                  data-aos-easing="linear"
                  data-aos-duration="1000"
                >
                  <h1 className="text-dark1 fs-56 mb-30">
                    <span className="fw-800">Find</span> your next match.
                  </h1>
                  <div className="bannerIntro ">
                    <p className="mb-30 text-dark3 fs-18 fw-medium">
                      Find the right price, dealer and advice
                    </p>
                  </div>

                  <Space size="large" className="selectWrapper">
                    <CommonSelect
                      setOptions={setMake}
                      uniqueOptions={uniqueMakeOptions}
                      defaultValue="Select Make"
                    />

                    <CommonSelect
                      setOptions={setModel}
                      uniqueOptions={uniqueModelOptions}
                      defaultValue="Select Model"
                    />

                    <div className="common-fill-btn">
                      <Link
                        to="/stock"
                        className="fs-16 bg-clr-blue fw-bold text-white rounded fill-btn"
                      >
                        Browse {newData?.length} Cars
                      </Link>
                    </div>
                  </Space>
                </div>
              </Col>
            </Row>
          </div>

          {/* <!--stocklist start --> */}
          <div className="stocklist">
            {!loading && (
              <div className="stocklist-wrapper pb-5">
                <Row
                  justify="center"
                  align="middle"
                  gutter={[24, 36]}
                  className="stocklist-row"
                >
                  {newData?.slice(0, itemsPerPage)?.map((car, index) => (
                    <Col
                      xs={{ span: 24 }}
                      md={{ span: 12 }}
                      lg={{ span: 8 }}
                      xl={{ span: 6 }}
                      className="stock-column"
                      key={index}
                    >
                      <StockListItem car={car} />
                    </Col>
                  ))}
                </Row>
                {newData?.length > itemsPerPage && (
                  <div className="common-fill-btn text-center mt-50">
                    <Link
                      className="fs-16 bg-clr-blue fw-bold text-white rounded fill-btn"
                      onClick={() => setItemsPerPage(itemsPerPage + 4)}
                    >
                      Load More
                    </Link>
                  </div>
                )}
              </div>
            )}
            {loading && (
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <Spin size="large" />
              </div>
            )}
          </div>
          {/* <!--/ stocklist end --> */}
        </div>
      </section>

      <CallToAction />
    </>
  );
};

export default StockList;
