import { timePicker } from "analogue-time-picker";
import React, { useEffect, useState } from "react";
import usePurchase from "../../hooks/usePurchase";
import { Link } from "react-router-dom";

const CustomTimePicker = () => {

  const { setFormData, isAM, setIsAM } = usePurchase();

  const [hour, setHour] = useState("");
  const [minute, setMinute] = useState("");


  // Get the current time using the Date object
  const currentTime = new Date();
  const currentHour = currentTime.getHours() % 12 || 12; // Convert 0 to 12
  const currentMinute = currentTime.getMinutes();

  useEffect(() => {

    const clockElement = document.getElementById("clock");

    if (clockElement) {
      const showTime = timePicker({
        element: clockElement,
        mode: 12,
        width: "200px",
        time: { hour: currentHour, minute: currentMinute },
      });

      // Define the click handler
      const clickHandler = () => {
        const object = showTime.getTime();
        setHour(object.hour);
        setMinute(object.minute);
      };

      // Attach the click event listener to the clock element
      clockElement.addEventListener("click", clickHandler);

      // Set the initial state with the current time
      setHour(currentHour);
      setMinute(currentMinute);
      // Clean up the event listener when the component unmounts
      return () => {
        clockElement.removeEventListener("click", clickHandler);
      };
    }
  }, []);

  function handleAMClick() {
    setIsAM(true);
  }

  function handlePMClick() {
    setIsAM(false);
  }

  useEffect(() => {
    setFormData((prevData) => ({
      ...prevData,
      time: `${hour}:${minute}`,
    }));

  }, [hour, minute]);


  const handleResetClock = () => {
    // Set the timePicker to the new time
    const clockElement = document.getElementById('clock');

    if (clockElement) {

      const showTime = timePicker({
        element: clockElement,
        mode: 12,
        width: '200px',
        time: { hour: currentHour, minute: currentMinute },
      });
  
      const object = showTime.getTime();
      setHour(object.hour);
      setMinute(object.minute);
    }

     // Try to destroy the existing timePicker instance
     if (clockElement.timePickerInstance) {
      clockElement.timePickerInstance.destroy();
    }
  }



  return (
    <div>
      <div id="clock"></div>
      <div className="mat-card">
        <button type="button" className={`${isAM && "active"}`} onClick={handleAMClick}>
          AM
        </button>
        <button type="button" className={`${!isAM && "active"}`} onClick={handlePMClick}>
          PM
        </button>
      </div>
      <div className="time-box">
        <span>
          <svg
            width="20"
            height="20"
            viewBox="0 0 20 20"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M18.3332 10.0001C18.3332 14.6001 14.5998 18.3334 9.99984 18.3334C5.39984 18.3334 1.6665 14.6001 1.6665 10.0001C1.6665 5.40008 5.39984 1.66675 9.99984 1.66675C14.5998 1.66675 18.3332 5.40008 18.3332 10.0001Z"
              stroke="#525355"
              strokeWidth="1.5"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M13.0919 12.65L10.5086 11.1083C10.0586 10.8416 9.69189 10.2 9.69189 9.67497V6.2583"
              stroke="#525355"
              strokeWidth="1.5"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
        </span>
        <h3>
          {hour} : {minute} {isAM ? "AM" : "PM"}
        </h3>
      </div>

      <div className="text-center mt-15">
         <Link className="fw-bold" onClick={handleResetClock}>Reset Clock</Link>
      </div>
    </div>
  );
};

export default CustomTimePicker;
