import { Col, Row } from 'antd';
import React from 'react';

const commonBanner = ({sectionTag, sectionCaption, sectionIntro, sectionImg, sectionBg, addRowClass}) => {
    return (
        <section className={`commonBanner bg-no-repeat py-80`} style={{ backgroundImage: `url(${sectionBg})`, backgroundSize: "cover" }}>
            <div className="container">
                <Row align="middle" gutter={[24, 24]} className={`${addRowClass}`}>
                    <Col className="" xl={{ span: 12 }}>
                        <div className="bannerInfo text-lg-start text-xs-center" data-aos="zoom-in" data-aos-easing="linear" data-aos-duration="1000">
                            <h6 className="secTag ls-2 fs-12 text-blue fw-800 text-uppercase mb-10">{sectionTag}</h6>
                            <h2 dangerouslySetInnerHTML={{ __html: sectionCaption }} className="bannerCaption fs-48 text-dark1 lh-58 fw-normal mb-20"></h2>
                            <p className="bannerIntro fs-16 fw-medium text-dark3 lh-24">{sectionIntro}</p>
                        </div>
                    </Col>
                    <Col className="" xl={{ span: 12 }}>
                        <div className="bannerImg text-lg-start text-xs-center" data-aos="flip-left" data-aos-easing="ease-out-cubic" data-aos-duration="1000">
                            <img src={sectionImg} alt="bannerImg" className="img-fluid" />
                        </div>
                    </Col>
                </Row>
            </div>
        </section>
    );
};

export default commonBanner;