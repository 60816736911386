import { Spin } from "antd";
import React, { useEffect, useState } from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import VanHeroImg from "./assets/images/Carbarn-hero.png";
import HybridHeroImg from "./assets/images/hybrid-car-landing-img.svg";
import Footer from "./components/footer/Footer";
import Header from "./components/header/Header";
import SideBar from "./components/sideBar/SideBar";
import TestDriveModal from "./components/testDriveModal/TestDriveModal";
import Topbar from "./components/topBar/Topbar";
import HybridCar from "./landingPage/HybridCar";
import Checkout from "./pages/Checkoutpage/Checkout";
import Blog from "./pages/blog/Blog";
import BlogDetails from "./pages/blogDetails/BlogDetails";
import CarDetails from "./pages/carDetails/CarDetails";
import CarFinance from "./pages/carFinance/CarFinance";
import ContactCarbarn from "./pages/contactCarbarn/ContactCarbarn";
import FaqCarbarn from "./pages/faqCarbarn/FaqCarbarn";
import Home from "./pages/home/Home";
import HowItWork from "./pages/howItWork/HowItWork";
import Login from "./pages/login/Login";
import RecentlyViewed from "./pages/recentlyViewed/RecentlyViewed";
import Registration from "./pages/registration/Registration";
import StockList from "./pages/stockList/StockList";
import StraipePurchaseJourney from "./pages/straipePurchaseJourney/StraipePurchaseJourney";
import User from "./pages/user/User";
import ViewCars from "./pages/viewCars/ViewCars";
import Warranty from "./pages/warranty/Warranty";
import WishList from "./pages/wishList/WishList";
import PrivateRoute from "./utils/PrivateRoute";
import PublicRoute from "./utils/PublicRoute";
import ChatBox from "./components/chatBox/ChatBox";

function App() {
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    setTimeout(() => setLoading(false), 2000);
    window.scrollTo(0, 0);
  }, []);

  // const filteredData = data?.filter((item) => item?.make?.toLowerCase() === make?.toLowerCase() || item?.model?.toLowerCase() === model);

  return (
    <>
      {loading ? (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "100vh",
          }}
        >
          <Spin size="large" />
        </div>
      ) : (
        <BrowserRouter basename="/">
          <ToastContainer theme="colored" position="top-right" />
          <Topbar />
          <SideBar />
          <Header />
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/home" element={<Home />} />
            <Route path="/stock" element={<StockList />} />
            <Route path="/wish-list" element={<WishList />} />
            <Route path="/recent" element={<RecentlyViewed />} />
            <Route path="/warranty" element={<Warranty />} />
            <Route path="/finance" element={<CarFinance />} />
            <Route path="/how-works" element={<HowItWork />} />
            <Route path="/view-cars" element={<ViewCars />} />
            <Route path="/blog" element={<Blog />} />

            <Route
              path="/hybrid-car"
              element={
                <HybridCar
                  landingHeroImg={HybridHeroImg}
                  title="All Hybrid Vehicle"
                  isHybrid={true}
                />
              }
            />
            <Route
              path="/camper-van"
              element={
                <HybridCar landingHeroImg={VanHeroImg} title="All Camper Van" />
              }
            />

            <Route path="/faq" element={<FaqCarbarn />} />
            <Route path="/contact" element={<ContactCarbarn />} />
            <Route path="/checkout" element={<Checkout />} />
            <Route
              path="/user"
              element={
                <PrivateRoute>
                  <User />
                </PrivateRoute>
              }
            />
            <Route
              path="/login"
              element={
                <PublicRoute>
                  <Login />
                </PublicRoute>
              }
            />
            <Route
              path="/signup"
              element={
                <PublicRoute>
                  <Registration />
                </PublicRoute>
              }
            />

            <Route path="/blog/:blogId" element={<BlogDetails />} />
            <Route path="/:brand/:carId" element={<CarDetails />} />
            <Route
              path="/:brand/:carId/test-drive"
              element={<TestDriveModal />}
            />
            <Route
              path="/:brand/:carId/purchase-journey"
              element={<StraipePurchaseJourney />}
            />
            <Route
              path="*"
              element={
                <div className="text-center py-80">
                  <h3 className="fs-30 fw-800">Page not found 404</h3>
                </div>
              }
            />
          </Routes>
          {/* <CallToAction /> */}
          <Footer />
          <ChatBox/>
        </BrowserRouter>
      )}
    </>
  );
}

export default App;
