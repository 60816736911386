import { Modal } from "antd";
import React, { useState } from "react";
import { Link } from "react-router-dom";
import FinancingCalculator from "./FinancingCalculator";
import DeliveryCharges from "../deliveryCharges/DeliveryCharges";

const PurchaseOptionModal = ({ title, btnText, finance, priceing}) => {
  const [isOpen, setIsOpen] = useState(false);

  const showModal = () => {
    setIsOpen(true);
  };

  const handleCancel = () => {
    setIsOpen(false);
  };

  return (
    <div>
      <>
        <Link
          className={`text-dark3 fs-14 fw-bold text-under-line ${priceing && finance && "fs-12"}`}
          onClick={showModal}
        >
          {btnText}
        </Link>
        <Modal
          centered
          title={title}
          onCancel={handleCancel}
          open={isOpen}
          width={800}
          className="action-modal"
          footer={false}
        >
          {finance && (
            <>
              <FinancingCalculator />
            </>
          )}

          {priceing && (
            <>
              <DeliveryCharges />
            </>
          )}
        </Modal>
      </>
    </div>
  );
};

export default PurchaseOptionModal;
