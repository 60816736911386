/* eslint-disable array-callback-return */
import React, { createContext, useState } from "react";
import useFetch from "../hooks/useFetch";

const FilterContext = createContext();

export const FilterProvider = ({ children }) => {

  const { data, loading } = useFetch(
    "https://www.cbs.s1.importautos.com.au/product-index/v1/api/vehicle/"
  );

  //   Convert Into New Array
  const convertedData = data?.filter((item) => item.soldStatus === "UnSold");

  const [menuOpen, setMenuOpen] = useState(false);
  const [searchOpen, setSearchOpen] = useState(false);

  const [make, setMake] = useState();
  const [model, setModel] = useState();
  const [year, setYear] = useState();
  const [colour, setColour] = useState();
  const [fuelType, setFuelType] = useState();
  const [bodyType, setBodyType] = useState();
  const [door, setDoor] = useState();
  const [seats, setSeats] = useState();
  const [price, setPrice] = useState();
  const [kms, setKms] = useState();

  const [minPrice, setMinPrice] = useState();
  const [maxPrice, setMaxPrice] = useState();

  const [minKm, setMinKm] = useState();
  const [maxKm, setMaxKm] = useState();

  const [fromYear, setFromYear] = useState();
  const [toYear, setToYear] = useState();

  const [searchText, setSearchText] = useState("");


  let makeOptions = [];
  let modelOptions = [];
  let yearOptions = [];
  let colorOptions = [];
  let fuelTypeOptions = [];
  let bodyTypeOptions = [];
  let doorOptions = [];
  let seatOptions = [];

  convertedData?.map((item) => {
    makeOptions?.push({
      value: item?.make,
      label: item?.make,
      make: item?.make,
    });

    modelOptions?.push({
      label: item?.model,
      value: item?.model,
      make: item?.make,
    });

    yearOptions?.push({
      value: item?.builtYear,
      label: item?.builtYear,
    });

    colorOptions?.push({
      value: item?.interiorColor,
      label: item?.interiorColor,
    });

    fuelTypeOptions?.push({
      value: item?.fuel,
      label: item?.fuel,
    });

    bodyTypeOptions?.push({
      value: item?.bodyType,
      label: item?.bodyType,
    });

    doorOptions?.push({
      value: item?.doors,
      label: item?.doors,
    });

    seatOptions?.push({
      value: item?.seats,
      label: item?.seats,
    });
  });

  const filteredData = convertedData?.filter((product) => {
    const makeMatch = make?.includes(product?.make);
    const modelMatch = model?.includes(product?.model);
    const colorMatch = colour?.includes(product?.interiorColor);
    const fuelTypeMatch = fuelType?.includes(product?.fuel);
    const bodyTypeMatch = bodyType?.includes(product?.bodyType);
    const doorMatch = door?.includes(product?.doors);
    const seatTypeMatch = seats?.includes(product?.seats);

    const priceFilter =
      parseInt(product?.salePrice) >= parseInt(minPrice) &&
      parseInt(product?.salePrice) <= parseInt(maxPrice);
    const kmFilter =
      parseInt(product?.odometer) >= parseInt(minKm) &&
      parseInt(product?.odometer) <= parseInt(maxKm);
    const filterByYear =
      product?.builtYear >= fromYear && product?.builtYear <= toYear;


        // Check if both make and model are selected, and they match the product
    const makeAndModelMatch = make && model && makeMatch && modelMatch;

     // Check if only make is selected or both make and model match the product
  const commonMatch = makeAndModelMatch || (make && !model && makeMatch) || (!make && model && modelMatch) ;


    return (
      commonMatch ||
      colorMatch ||
      fuelTypeMatch ||
      bodyTypeMatch ||
      doorMatch ||
      seatTypeMatch ||
      priceFilter ||
      kmFilter ||
      filterByYear
    );
  });

  const newData = filteredData?.length > 0 ? filteredData : convertedData;

  const clearFilter = () => {
    setSearchText('');
    setToYear('');
    setFromYear('');
    setMaxKm('');
    setMinKm('');
    setMaxPrice('');
    setMinPrice('');
    setKms('');
    setPrice('');
    setSeats('');
    setDoor('');
    setBodyType('');
    setFuelType('');
    setColour('');
    setYear('');
    setModel('');
    setMake('');
  }

  return (
    <FilterContext.Provider
      value={{
        menuOpen,
        setMenuOpen,
        searchOpen,
        setSearchOpen,
        searchText,
        make,
        model,
        year,
        colour,
        fuelType,
        bodyType,
        door,
        seats,
        price,
        kms,
        setSearchText,
        setMake,
        setModel,
        setYear,
        setColour,
        setFuelType,
        setBodyType,
        setDoor,
        setSeats,
        setPrice,
        setKms,
        makeOptions,
        modelOptions,
        yearOptions,
        colorOptions,
        fuelTypeOptions,
        bodyTypeOptions,
        doorOptions,
        seatOptions,
        minPrice,
        setMinPrice,
        maxPrice,
        setMaxPrice,
        minKm,
        setMinKm,
        maxKm,
        setMaxKm,
        fromYear,
        setFromYear,
        toYear,
        setToYear,
        filteredData,
        newData,
        loading,
        clearFilter
      }}
    >
      {children}
    </FilterContext.Provider>
  );
};

export default FilterContext;
