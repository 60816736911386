import { Col, Row } from 'antd';
import React from 'react';
import { useParams } from 'react-router-dom';
import { blogData } from '../../data';
import './blogDetails.scss';


const BlogDetails = () => {

  const { blogId } = useParams();

  const singleDetails = blogData?.find((item) => item?.id === Number(blogId));

  console.log(singleDetails);

  return (
      <section className="py-50">
          <div className="blog-container">
              <Row>
                  <Col>
                      <div className="blog-content">
                          <div className="blog-img mb-30">
                              <img src={singleDetails?.blogImg} alt="img" className="img-fluid" />
                          </div>

                          <h5 className="fs-18 text-blue mb-30">{singleDetails.category}</h5>

                          <h2 className="fs-48 fw-medium text-dark1 mb-30 w-100">{singleDetails?.title}</h2>

                          <p>
                              What you may not have considered, however, is whether you want that shiny new vehicle to take petrol or diesel in the tank. Hear us out, as it might be something you want
                              to weigh up.
                          </p>

                          <p>
                              If you thought that diesel was just for trucks, you’re not alone. Many of us see the diesel pump at the petrol station – with its ultra-big handle – and think that it’s
                              the juice for well, ultra-big vehicles.
                          </p>

                          <p>But did you know, diesel can fuel a range of vehicles, from the teeny to the tremendous?</p>

                          <p>With that in mind, let’s look at the pros and cons.</p>

                          <div className="blog-img">
                              <img src="https://s3-ap-south-1.amazonaws.com/bike-blog-content-dev/2022/03/audi-diesel-engine_v1.jpg" alt="img" className="img-fluid" />
                          </div>

                          <h4 className="sub-title">The benefits of diesel</h4>

                          <p>
                              With its ability to ruin petrol-run engines, and its price at the pump, diesel often gets a bad wrap from your everyday driver. But truth be told, diesel has its own list
                              of benefits.
                          </p>

                          <p>
                              For starters, it’s more powerful. Put simply, when put in a diesel-run engine, diesel can put more “oomph” behind the vehicle you’re driving, while placing less strain on
                              the engine.
                          </p>

                          <p>
                              On a related note, you’ll get more bang for your buck. While the price at the pump may turn you off, a diesel dollar goes further – making long trips suited to diesel
                              vehicles. You can see why trucks run on diesel now, can’t you?
                          </p>

                          <div className="blog-img">
                              <img src="https://s3-ap-south-1.amazonaws.com/bike-blog-content-dev/2022/03/diesel-pump_v1.jpg" alt="img" className="img-fluid" />
                          </div>

                          <h4 className="sub-title">The benefits of diesel</h4>

                          <p>It’s not all daisies for diesel, however. Just like any car-related feature you’ll cross on your purchasing journey, it does have its own caveats.</p>

                          <p>
                              As mentioned, diesel is more expensive at the pump. You may feel that the number ticking higher and higher feels astronomical in comparison to what you’re used to paying
                              for a petrol-run vehicle. Even if you get more out of the tank.
                          </p>

                          <p>
                              You may also want to consider that, even though there is less CO2 going into the air with a diesel vehicle, the nitrogen oxide gases that come out of diesel engines get
                              quite the bad wrap. They’re linked with bad air quality and breathing difficulties among those who consume the air.
                          </p>

                          <p>
                              Finally, diesel engines don’t do as well with shorter trips. Modern diesel engines are designed to run at their optimum once the engine reaches a certain temperature.
                              Once at that level, their diesel particulate filter (or DPF) can run efficiently, reducing nasty emissions.
                          </p>
                      </div>

                      <div className="similar-cars">
                          <h3>You might also like</h3>
                      </div>
                  </Col>
              </Row>
          </div>
      </section>
  );
};

export default BlogDetails;