import React from "react";
import FeaturedSlider from "../featuredSlider/FeaturedSlider";
import { Spin } from "antd";

const TweentyToThirty = ({ data, loading }) => {
  const tweentyToThirty = data?.filter(
    (item) =>
      parseInt(item?.salePrice) > 20000 &&
      parseInt(item?.salePrice) < 30000
  );

  return (
    <div className="budget-slider-wrapper">
      {loading && (
        <div className="text-center mt-30">
          <Spin />
        </div>
      )}

      {tweentyToThirty.length > 0 && !loading && (
        <FeaturedSlider cars={tweentyToThirty} featuredClass="car-slider" />
      )}

      {tweentyToThirty.length === 0 && !loading && (
        <h6 className="fs-18 mt-50 text-center mb-30 w-100">
          No Data Avilable
        </h6>
      )}
    </div>
  );
};

export default TweentyToThirty;
