import React from 'react';
import FeaturedSlider from '../featuredSlider/FeaturedSlider';

const OverFourty = ({ data, loading }) => {

    const overFourty = data?.filter(
      (item) => parseInt(item?.salePrice?.replace(",", "")) > 40000
    );

    return (
        <div className="budget-slider-wrapper">
            {loading && <h5 className="fs-18 mt-50 text-center mb-30">Loading</h5>}

            {overFourty?.length > 0 && !loading && <FeaturedSlider cars={overFourty} featuredClass="car-slider" />}

            {overFourty?.length === 0 && !loading && <h5 className="fs-18 mt-50 text-center mb-30 w-100">No Data Avilable</h5>}
        </div>
    );
};

export default OverFourty;