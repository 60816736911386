import React from 'react'
import CheckoutPayment from '../../components/CheckoutPayment/CheckoutPayment'

const Checkout = () => {
  return (
    <div>
        <CheckoutPayment />
    </div>
  )
}

export default Checkout