import { Select } from 'antd';
import React from 'react';
import './commonSelect.scss';

const CommonSelect = ({ setOptions, uniqueOptions, defaultValue, customWidth }) => {


    const handleChange = (value) => {
        setOptions(value);
    };


    return (
        <div className="car-selector-wrapper">
            <Select className={`car-selector ${customWidth}`} defaultValue={defaultValue} onChange={handleChange} options={uniqueOptions?.sort((a, b) => b.value - a.value)} />
        </div>
    );
};

export default CommonSelect; 