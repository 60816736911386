import React from 'react';
import HeightMeasurementImg from '../../assets/images/measurment-car-1.svg'
import BodyMeasurementImg from '../../assets/images/measurment-car-2.svg'
import './measurement.scss'

const Measurement = () => {
    return (
        <div className='measurement'>
            <div className='height-measurement-wrapper'>
                <div>
                    <img src={HeightMeasurementImg} alt='img' className='img-fluid'/>
                </div>
                <p className='exterior-height'>5476</p>
                <p className='interior-width'>1476</p>
                <p className='gap-1'>54</p>
                <p className='gap-2'>54</p>
            </div>

            <div className='body-measurement-wrapper'>
                <div>
                    <img src={BodyMeasurementImg} alt='img' className='img-fluid'/>
                </div>
                <p className='body-length'>5476</p>
                <p className='body-height'>1476</p>
                <p className='front-length'>114</p>
                <p className='middle-length'>2324</p>
                <p className='back-length'>1324</p>
                <p className='length'>3324</p>
            </div>
        </div>
    );
};

export default Measurement;