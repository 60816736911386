import React from "react";
import RightIconWhite from "../../assets/images/right-tick-icon-white.svg";
import WarningIcon from "../../assets/images/warning-icon-black.svg";
import useFetch from "../../hooks/useFetch";
import InspectionReportAccordion from "../inspectionReportAccordion/InspectionReportAccordion";
import InspectionReportTab from "../inspectionReportTab/InspectionReportTab";
import "./inspectionReport.scss";
import InspectionReportAnchor from "../inspectionReportAnchor/InspectionReportAnchor";

const InspectionReport = ({ singleDetails }) => {
  const { data: inspectionItem, loading } = useFetch(
    "https://script.googleusercontent.com/a/macros/iubat.edu/echo?user_content_key=0KtsWWMrGyMVgo8EHvE5XgMuYNNpeKHIURyXRFNaLsNYj1BXzFH1DCtXiaabLHJH5pUUy-1ObRRrS2hgPAtddMQho9LUDik6m5_BxDlH2jW0nuo2oDemN9CCS2h10ox_nRPgeZU6HP-6TeIvguKx93hyel13aBqkBc-2tuT68_YNlLfk0pe31v8ToQcmwo0VOj1OQ2X6gly95FmHJk9t_dv1jGAqk8GPwTC4Dbs2JtJ-ZCakbQENJ1m8ESWxrYKfvJsw0wqpxiI&lib=MBZf8JeW-nRvM3jaJSIuVrFoA7Bw04aHQ"
  );

  const uniqueInspectionGroups = [
    ...new Set(inspectionItem?.map((item) => item?.group)),
  ];

  const bodyExterior = inspectionItem?.filter(
    (item) => item?.group === uniqueInspectionGroups[0]
  );
  const engineAndTransmission = inspectionItem?.filter(
    (item) => item?.group === uniqueInspectionGroups[1]
  );
  const interior = inspectionItem?.filter(
    (item) => item?.group === uniqueInspectionGroups[2]
  );
  const electronics = inspectionItem?.filter(
    (item) => item?.group === uniqueInspectionGroups[3]
  );
  const steeringAndSuspension = inspectionItem?.filter(
    (item) => item?.group === uniqueInspectionGroups[4]
  );

  const totalCheckPoints =
    bodyExterior?.length +
    engineAndTransmission?.length +
    interior?.length +
    electronics?.length +
    steeringAndSuspension?.length;


  const imperfectItems = singleDetails?.inspectionReport?.filter(
    (imperfectItem) => imperfectItem?.repairStatus === "IMPERFECTION"
  );



  return (
    <div className="inspection-report">
      <div className="inspection-report-header">
        <h3>Inspection Report</h3>
        <div className="report-status-wrapper">
          <div className="report-status">
            <div className="status-text">
              <h2>{imperfectItems?.length}</h2>
              <p>
                Require <br /> Attention
              </p>
            </div>
            <div>
              <img src={WarningIcon} alt="icon" className="imf-fluid" />
            </div>
          </div>
          <div
            className="report-status"
            style={{ backgroundColor: "#3078FF", gap: "20px" }}
          >
            <div className="status-text">
              <h2 className="text-white">{totalCheckPoints}</h2>
              <p className="text-white">
                Checkpoints <br /> Cleared
              </p>
            </div>
            <div>
              <img src={RightIconWhite} alt="icon" className="img-fluid" />
            </div>
          </div>
        </div>
      </div>

   
      {/* <div className="inspection-report-anchor">
        <InspectionReportAnchor
          singleDetails={singleDetails}
          uniqueInspectionGroups={uniqueInspectionGroups}
          bodyExterior={bodyExterior}
          engineAndTransmission={engineAndTransmission}
          interior={interior}
          Electronics={electronics}
          steeringAndSuspension={steeringAndSuspension}
    
        />
      </div> */}

      <div className="inspection-report-tab">
        <InspectionReportTab
          singleDetails={singleDetails}
          uniqueInspectionGroups={uniqueInspectionGroups}
          bodyExterior={bodyExterior}
          engineAndTransmission={engineAndTransmission}
          interior={interior}
          Electronics={electronics}
          steeringAndSuspension={steeringAndSuspension}
        />
      </div>

      <div className="inspection-report-accordion">
        <InspectionReportAccordion
          singleDetails={singleDetails}
          uniqueInspectionGroups={uniqueInspectionGroups}
          bodyExterior={bodyExterior}
          engineAndTransmission={engineAndTransmission}
          interior={interior}
          Electronics={electronics}
          steeringAndSuspension={steeringAndSuspension}
        />
      </div>
    </div>
  );
};

export default InspectionReport;
