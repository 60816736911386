import React from "react";
import FeaturedSlider from "../featuredSlider/FeaturedSlider";
import { Spin } from "antd";

const ThirtyToFourty = ({ data, loading }) => {
  const thirtyToFourty = data.filter(
    (item) =>
      parseInt(item?.salePrice?.replace(",", "")) > 30000 &&
      parseInt(item?.salePrice?.replace(",", "")) < 40000
  );

  return (
    <div className="budget-slider-wrapper">
      {loading && (
        <div className="text-center mt-30">
          <Spin />
        </div>
      )}

      {thirtyToFourty?.length > 0 && !loading && (
        <FeaturedSlider cars={thirtyToFourty} featuredClass="car-slider" />
      )}

      {thirtyToFourty?.length === 0 && (
        <h5 className="fs-18 mt-50 text-center mb-30 w-100">
          No Data Avilable
        </h5>
      )}
    </div>
  );
};

export default ThirtyToFourty;
