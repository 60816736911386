import { Form, Input } from "antd";
import React from "react";
import { ReactComponent as DateExpireIcon } from "../../assets/images/date-expire-icon.svg";
import { ReactComponent as EmailIcon } from "../../assets/images/email-icon-carbarn.svg";
import { ReactComponent as NoteIcon } from "../../assets/images/note-icon.svg";
import { ReactComponent as PhoneIcon } from "../../assets/images/phone-icon-carbarn.svg";
import { ReactComponent as UserIcon } from "../../assets/images/user-icon-carbarn.svg";
import { ReactComponent as LocationIcon } from "../../assets/images/location.svg";
import { ReactComponent as Key } from "../../assets/images/password-key.svg";
import usePurchase from "../../hooks/usePurchase";
import "./purchaseJourneyItems.scss";

const ReviewUserDetails = () => {

  const { formData, handleChange, user, editUser } = usePurchase();

  return (
    <div>
      {!editUser ? (
        <>
          <div className="contact-item">
            <div className="contact-icon">
              <UserIcon />
            </div>
            <div className="contact-item-info">
              <p className="fieldName">Name</p>
              <p>
                {formData?.firstName} {formData?.lastName}
              </p>
            </div>
          </div>
          <div className="contact-item">
            <div className="contact-icon">
              <EmailIcon />
            </div>
            <div className="contact-item-info">
              <p className="fieldName">Email</p>
              <p>{formData?.email}</p>
            </div>
          </div>
          <div className="contact-item">
            <div className="contact-icon">
              <PhoneIcon />
            </div>
            <div className="contact-item-info">
              <p className="fieldName">Phone</p>
              <p>{formData?.phoneNo}</p>
            </div>
          </div>
          <div className="contact-item">
            <div className="contact-icon">
              <NoteIcon />
            </div>
            <div className="contact-item-info">
              <p className="fieldName">License Number</p>
              <p>{formData?.dlNo}</p>
            </div>
          </div>
          <div className="contact-item">
            <div className="contact-icon">
              <DateExpireIcon />
            </div>
            <div className="contact-item-info">
              <p className="fieldName">License Expiry</p>
              <p>{formData?.dlExpairDate}</p>
            </div>
          </div>
          <div className="contact-item">
            <div className="contact-icon">
              <LocationIcon />
            </div>
            <div className="contact-item-info">
              <p className="fieldName">Address</p>
              <p>{formData?.address}</p>
            </div>
          </div>
        </>
      ) : (
        <>
          <Form.Item
            rules={[
              {
                required: true,
                message: "Please input your First Name!",
              },
            ]}
          >
            <Input
              prefix={<UserIcon />}
              placeholder="First Name"
              type="text"
              name="firstName"
              value={formData?.firstName}
              onChange={handleChange}
            />
          </Form.Item>
          <Form.Item
            rules={[
              {
                required: true,
                message: "Please input your Last Name!",
              },
            ]}
          >
            <Input
              prefix={<UserIcon />}
              type="text"
              placeholder="Last Name"
              name="lastName"
              value={formData.lastName}
              onChange={handleChange}
            />
          </Form.Item>
          <Form.Item
            rules={[
              {
                required: true,
                message: "Please input your mobile number!",
              },
            ]}
          >
            <Input
              prefix={<PhoneIcon />}
              type="telephone"
              placeholder="Mobile No"
              name="phoneNo"
              value={formData.phoneNo}
              onChange={handleChange}
            />
          </Form.Item>
          <Form.Item
            rules={[
              {
                required: true,
                message: "Please input your email!",
              },
            ]}
          >
            <Input
              prefix={<EmailIcon />}
              type="email"
              placeholder="Email Address"
              name="email"
              value={formData.email}
              onChange={handleChange}
            />
          </Form.Item>
          <Form.Item
            rules={[
              {
                required: true,
                message: "Please enter your password",
              },
            ]}
          >
            <Input
              prefix={<Key />}
              type="password"
              placeholder="Password"
              name="password"
              value={formData?.password}
              onChange={handleChange}
            />
          </Form.Item>
          <Form.Item
            rules={[
              {
                required: true,
                message: "Please enter your Driver License No",
              },
            ]}
          >
            <Input
              prefix={<NoteIcon />}
              placeholder="Driver License No"
              type="text"
              name="dlNo"
              value={formData.dlNo}
              onChange={handleChange}
            />
          </Form.Item>
          <Form.Item
            rules={[
              {
                required: true,
                message: "Please enter your Driver License Expiration date",
              },
            ]}
          >
            <Input
              prefix={<DateExpireIcon />}
              placeholder="License Expiry"
              type="text"
              name="dlExpairDate"
              value={formData?.dlExpairDate}
              onChange={handleChange}
            />
          </Form.Item>
          <Form.Item
            rules={[
              {
                required: true,
                message: "Please input your address!",
              },
            ]}
          >
            <Input
              name="doorDeliveryAddress"
              prefix={<LocationIcon />}
              placeholder="Input address please"
              type="text"
              value={formData?.address}
              onChange={handleChange}
              allowClear
            />
          </Form.Item>
        </>
      )}
    </div>
  );
};

export default ReviewUserDetails;
