import AccomodationIcon from "./assets/images/Accomodation.svg";
import AmbulanceIcon from "./assets/images/Ambulance-icon.svg";
import DeadBatteryIcon from "./assets/images/Dead-Battery.svg";
import FitCar from "./assets/images/Fit-Hire.svg";
import DeadTyreIcon from "./assets/images/Flat-Tyre.svg";
import KeyLockIcon from "./assets/images/Key-Lock.svg";
import AcIcon from "./assets/images/ac-icon.svg";
import CylinderHeads from "./assets/images/cylinder-heads.svg";
import ChipIcon from "./assets/images/electric-chip.svg";
import FuelIndicatorIcon from "./assets/images/fuel-indicator.svg";
import AbsBreakIcon from "./assets/images/hydrolic-break.svg";
import IgnitationIcon from "./assets/images/ignition-system.svg";
import MotorIcon from "./assets/images/motor.svg";
import RackerVanIcon from "./assets/images/racker-van.svg";
import RadiatorFan from "./assets/images/radiator-fan.svg";
import PartsImg1 from "./assets/images/warranty-logo1.svg";
import PartsImg10 from "./assets/images/warranty-logo10.svg";
import PartsImg11 from "./assets/images/warranty-logo11.svg";
import PartsImg12 from "./assets/images/warranty-logo12.svg";
import PartsImg2 from "./assets/images/warranty-logo2.svg";
import PartsImg3 from "./assets/images/warranty-logo3.svg";
import PartsImg4 from "./assets/images/warranty-logo4.svg";
import PartsImg5 from "./assets/images/warranty-logo5.svg";
import PartsImg6 from "./assets/images/warranty-logo6.svg";
import PartsImg7 from "./assets/images/warranty-logo7.svg";
import PartsImg9 from "./assets/images/warranty-logo9.svg";





export const mechanicalWarranty = [
    {
        partsImg: PartsImg1,
        name: "Engine",
    },
    {
        partsImg: PartsImg2,
        name: "Transmission",
    },
    {
        partsImg: PartsImg3,
        name: "Differential",
    },
    // {
    //     partsImg: PartsImg5,
    //     name: "Electrical System",
    // },
    {
        partsImg: PartsImg6,
        name: "Braking System",
    },
    {
        partsImg: PartsImg7,
        name: "Steering system",
    },
    {
        partsImg: PartsImg9,
        name: "Clutch",
    },
    {
        partsImg: PartsImg10,
        name: "Fuel Pump & Injection Systems",
    },
    {
        partsImg: PartsImg11,
        name: "Drive Shafts, CV Joints, Universals",
    },
    // {
    //     partsImg: SparkplugIcon,
    //     name: "Electronic Ignition System",
    // },
    // {
    //     partsImg: BatteryIcon,
    //     name: "Electronic Solenoids and Computers",
    // },
    {
        partsImg: AbsBreakIcon,
        name: "Abs Braking System",
    },
    {
        partsImg: RadiatorFan,
        name: "Radiator",
    },
    // {
    //     partsImg: MotorIcon,
    //     name: "Power Window Motors and Switches",
    // },
    {
        partsImg: PartsImg4,
        name: "Cooling System",
    },
    // {
    //     partsImg: PartsImg8,
    //     name: "AIR Conditioning",
    // },
    {
        partsImg: PartsImg12,
        name: "Turbo",
    },
    {
        partsImg: CylinderHeads,
        name: "Cylinder Heads",
    },
];


export const electricalWarranty = [
  {
    partsImg: PartsImg5,
    name: "Electirical System",
  },
  {
    partsImg: IgnitationIcon,
    name: "Ignition System",
  },
  {
    partsImg: ChipIcon,
    name: "Electronic Solenoids and Computers",
  }
];


export const bodyWarranty = [
    {
        partsImg: MotorIcon,
        name: "Power Window Motors and switches",
    },
];


export const comfortWarranty = [
    {
        partsImg: AcIcon,
        name: "Air Conditioning",
    },
];

export const roadsideWarranty = [
    {
        partsImg: DeadBatteryIcon,
        name: "Flat Battery",
    },
    {
        partsImg: DeadTyreIcon,
        name: "Flat Tyre",
    },
    {
        partsImg: FuelIndicatorIcon,
        name: "Out of Fuel",
    },
    {
        partsImg: KeyLockIcon,
        name: "Lock Outs",
    },
    {
        partsImg: RackerVanIcon,
        name: "Towing (Breakdown)",
    },
    {
        partsImg: FitCar,
        name: "Car Hire",
    },
    {
        partsImg: AccomodationIcon,
        name: "Accommodation",
    },
    {
        partsImg: AmbulanceIcon,
        name: "Ambulance Cover",
    },
];


 export const additionalFeature = [
     {
         title: "Flat Battery - Jump start Vehicle (FREE)",
     },
     {
         title: "Flat Tyre - Replace flat with spare (FREE)",
     },
     {
         title: "Out of Fuel - Fuel supplied, or Vehicle transported depending on fuel type (FREE)",
     },
     {
         title: "Lock Outs - Access Vehicle or replace key (FREE)",
     },
     {
         title: "Towing (Breakdown) - Towing up to 50km free service radius",
     },
     {
         title: "Car Hire - Assistance with car rental costs up to $200 per claim.",
     },
     {
         title: "Accommodation - Assistance with emergency accommodation costs up to $200 per claim",
     },
     {
         title: "Ambulance Cover - Assistance with ambulance costs up to $200 per claim.",
     },
 ];

 export const notCover = [
     {
         title: "Damage to covered components resulting from improper maintenance of coolants and lubricants, causing the component to overheat.",
     },
     {
         title: "Damage resulting from road accidents, floods, fires, earthquakes, natural disasters, riots, civil commotions, war, terrorism, etc.",
     },
     {
         title: "Modifications made to the vehicle that are not in line with the manufacturer's recommendations in the logbook.",
     },
     {
         title: "Non-manufacturer fitted parts that do not comply with Australian Design Rules.",
     },
     {
         title: "Failure to maintain the vehicle in accordance with our service requirements, such as having it serviced by unlicensed mechanics or at backyard garages.",
     },
     {
         title: "Deterioration resulting from normal wear and tear or the gradual reduction in the vehicle's operating performance or any covered component.",
     },
 ];


 export const blogData = [
     {
         id: 1,
         title: "The best cars ride share cars",
         desc: "If you’re the kind of driver who’s eco-conscious, then you may want to consider a hybrid vehicle for your work. Not only will your footprint on the planet be far less, but you’re likely to spend a lot less on fuel. That’s more money for you to pocket from jobs at the end of the day.The Toyota Camry Hybrid has an excellent warranty on the car’s battery - with 8 years included with your purchase. With enough to worry about when you’re ridesharing, the life of your car battery shouldn’t be one of them. Choose the Camry, and you can drive with added confidence.This vehicle also won’t break the bank - particularly when compared to larger SUVs with more luxurious extras.Of course, if we’re continuing to compare this vehicle to SUVs, the Camry also provides a lowered, smaller design. If you’re concerned about weaving in and out of the CBD at your passenger’s request, you may find a sedan like this one fits the bill.When you’re making a decision on a carshare vehicle, always consult the platform you wish to perform business through in the first instance - and once you’ve made your purchase, be sure to keep up to date with their list of requirements.While it may feel like a costly outlay at first, provide yourself with a quality car and you’ll be able to make back the money you’ve spent on your vehicle swiftly.",
         blogImg: "https://s3-ap-south-1.amazonaws.com/bike-blog-content-dev/2022/04/toyota-camry-hybrid_2020_v2.jpg",
         category: "buying guide",
     },
     {
         id: 2,
         title: "Why are Jeeps so dividing?",
         desc: "If you’re the kind of driver who’s eco-conscious, then you may want to consider a hybrid vehicle for your work. Not only will your footprint on the planet be far less, but you’re likely to spend a lot less on fuel. That’s more money for you to pocket from jobs at the end of the day.The Toyota Camry Hybrid has an excellent warranty on the car’s battery - with 8 years included with your purchase. With enough to worry about when you’re ridesharing, the life of your car battery shouldn’t be one of them. Choose the Camry, and you can drive with added confidence.This vehicle also won’t break the bank - particularly when compared to larger SUVs with more luxurious extras.Of course, if we’re continuing to compare this vehicle to SUVs, the Camry also provides a lowered, smaller design. If you’re concerned about weaving in and out of the CBD at your passenger’s request, you may find a sedan like this one fits the bill.When you’re making a decision on a carshare vehicle, always consult the platform you wish to perform business through in the first instance - and once you’ve made your purchase, be sure to keep up to date with their list of requirements.While it may feel like a costly outlay at first, provide yourself with a quality car and you’ll be able to make back the money you’ve spent on your vehicle swiftly.",
         blogImg: "https://s3-ap-south-1.amazonaws.com/bike-blog-content-dev/2022/03/jeep-wrangler-2018_v1.jpg",
         category: "Advice",
     },
     {
         id: 3,
         title: "Do you really need Apple CarPlay or Android Auto?",
         desc: "If you’re the kind of driver who’s eco-conscious, then you may want to consider a hybrid vehicle for your work. Not only will your footprint on the planet be far less, but you’re likely to spend a lot less on fuel. That’s more money for you to pocket from jobs at the end of the day.The Toyota Camry Hybrid has an excellent warranty on the car’s battery - with 8 years included with your purchase. With enough to worry about when you’re ridesharing, the life of your car battery shouldn’t be one of them. Choose the Camry, and you can drive with added confidence.This vehicle also won’t break the bank - particularly when compared to larger SUVs with more luxurious extras.Of course, if we’re continuing to compare this vehicle to SUVs, the Camry also provides a lowered, smaller design. If you’re concerned about weaving in and out of the CBD at your passenger’s request, you may find a sedan like this one fits the bill.When you’re making a decision on a carshare vehicle, always consult the platform you wish to perform business through in the first instance - and once you’ve made your purchase, be sure to keep up to date with their list of requirements.While it may feel like a costly outlay at first, provide yourself with a quality car and you’ll be able to make back the money you’ve spent on your vehicle swiftly.",
         blogImg: "https://s3-ap-south-1.amazonaws.com/bike-blog-content-dev/2022/03/apple-carplay_alt_v3.jpg",
         category: "Advice",
     },
     {
         id: 4,
         title: "Should I consider a diesel car",
         desc: "If you’re the kind of driver who’s eco-conscious, then you may want to consider a hybrid vehicle for your work. Not only will your footprint on the planet be far less, but you’re likely to spend a lot less on fuel. That’s more money for you to pocket from jobs at the end of the day.The Toyota Camry Hybrid has an excellent warranty on the car’s battery - with 8 years included with your purchase. With enough to worry about when you’re ridesharing, the life of your car battery shouldn’t be one of them. Choose the Camry, and you can drive with added confidence.This vehicle also won’t break the bank - particularly when compared to larger SUVs with more luxurious extras.Of course, if we’re continuing to compare this vehicle to SUVs, the Camry also provides a lowered, smaller design. If you’re concerned about weaving in and out of the CBD at your passenger’s request, you may find a sedan like this one fits the bill.When you’re making a decision on a carshare vehicle, always consult the platform you wish to perform business through in the first instance - and once you’ve made your purchase, be sure to keep up to date with their list of requirements.While it may feel like a costly outlay at first, provide yourself with a quality car and you’ll be able to make back the money you’ve spent on your vehicle swiftly.",
         blogImg: "https://s3-ap-south-1.amazonaws.com/bike-blog-content-dev/2022/03/discovery-sport-diesel_alt_v1.jpg",
         category: "buying guide",
     },
     {
         id: 5,
         title: "Why wagons are making a comeback",
         desc: "If you’re the kind of driver who’s eco-conscious, then you may want to consider a hybrid vehicle for your work. Not only will your footprint on the planet be far less, but you’re likely to spend a lot less on fuel. That’s more money for you to pocket from jobs at the end of the day.The Toyota Camry Hybrid has an excellent warranty on the car’s battery - with 8 years included with your purchase. With enough to worry about when you’re ridesharing, the life of your car battery shouldn’t be one of them. Choose the Camry, and you can drive with added confidence.This vehicle also won’t break the bank - particularly when compared to larger SUVs with more luxurious extras.Of course, if we’re continuing to compare this vehicle to SUVs, the Camry also provides a lowered, smaller design. If you’re concerned about weaving in and out of the CBD at your passenger’s request, you may find a sedan like this one fits the bill.When you’re making a decision on a carshare vehicle, always consult the platform you wish to perform business through in the first instance - and once you’ve made your purchase, be sure to keep up to date with their list of requirements.While it may feel like a costly outlay at first, provide yourself with a quality car and you’ll be able to make back the money you’ve spent on your vehicle swiftly.",
         blogImg: "https://s3-ap-south-1.amazonaws.com/bike-blog-content-dev/2022/03/audi-a6_2021_v1.jpg",
         category: "Advice",
     },
     {
         id: 6,
         title: "Hybrid or electric?",
         desc: "If you’re the kind of driver who’s eco-conscious, then you may want to consider a hybrid vehicle for your work. Not only will your footprint on the planet be far less, but you’re likely to spend a lot less on fuel. That’s more money for you to pocket from jobs at the end of the day.The Toyota Camry Hybrid has an excellent warranty on the car’s battery - with 8 years included with your purchase. With enough to worry about when you’re ridesharing, the life of your car battery shouldn’t be one of them. Choose the Camry, and you can drive with added confidence.This vehicle also won’t break the bank - particularly when compared to larger SUVs with more luxurious extras.Of course, if we’re continuing to compare this vehicle to SUVs, the Camry also provides a lowered, smaller design. If you’re concerned about weaving in and out of the CBD at your passenger’s request, you may find a sedan like this one fits the bill.When you’re making a decision on a carshare vehicle, always consult the platform you wish to perform business through in the first instance - and once you’ve made your purchase, be sure to keep up to date with their list of requirements.While it may feel like a costly outlay at first, provide yourself with a quality car and you’ll be able to make back the money you’ve spent on your vehicle swiftly.",
         blogImg: "https://s3-ap-south-1.amazonaws.com/bike-blog-content-dev/2022/02/volvo-xc40-electric_v2.jpg",
         category: "buying guide",
     },
 ];



