import { DownOutlined } from "@ant-design/icons";
import { Collapse } from "antd";
import React from "react";
import { Link } from "react-router-dom";
import BodyIcon from "../../assets/images/body-exterior-icon.svg";
import EngineIcon from "../../assets/images/engine-icon.svg";
import InteriorIcon from "../../assets/images/interior-icon.svg";
import TickIcon from "../../assets/images/right-tick-icon.svg";
import BreakIcon from "../../assets/images/steering-icon.svg";
import InspectionContent from "../inspectionContent/InspectionContent";
import './inspectionReportAccordion.scss';

const { Panel } = Collapse;

const BodyHeader = () => {
  return (
    <div className="accordion-header">
      <img src={BodyIcon} alt="icon" className="img-fluid" width={34} />
      <h6>Body Exterior</h6>
    </div>
  );
};

const EnginHeader = () => {
  return (
    <div className="accordion-header">
      <img src={EngineIcon} alt="icon" className="img-fluid" width={34} />
      <h6>Engine and Transmission</h6>
    </div>
  )
}

const InteriorHeader = () => {
  return (
    <div className="accordion-header">
      <img src={InteriorIcon} alt="icon" className="img-fluid" width={34} />
      <h6>Interior</h6>
    </div>
  );
};

const ElectronicsHeader = () => {
  return (
    <div className="accordion-header">
      <img src={EngineIcon} alt="icon" className="img-fluid" width={34} />
      <h6>Electronics</h6>
    </div>
  );
};


const SteeringHeader = () => {
  return (
    <div className="accordion-header">
      <img src={BreakIcon} alt="icon" className="img-fluid" width={34} />
      <h6>Steering, Suspension and Brakes</h6>
    </div>
  );
};

const InspectionReportAccordion = ({singleDetails, bodyExterior, engineAndTransmission, interior, Electronics, steeringAndSuspension}) => {
  return (
    <>
      <Collapse
        accordion
        bordered={false}
        expandIcon={({ isActive }) => (
          <DownOutlined rotate={isActive ? 180 : 0} />
        )}
        expandIconPosition="end"
        className="inspection-accordian"
        key="1"
      >
        <Panel
          extra={
            <img src={TickIcon} alt="icon" className="img-fluid" width="34px" />
          }
          header={<BodyHeader />}
          key={1}
          className="accordian-item"
        >
          <InspectionContent repairedItems={bodyExterior} singleDetails={singleDetails} />
        </Panel>
        <Panel
          extra={
            <img src={TickIcon} alt="icon" className="img-fluid" width="34px" />
          }
          header={<EnginHeader />}
          key={2}
          className="accordian-item"
        >
           <InspectionContent repairedItems={engineAndTransmission} singleDetails={singleDetails} />
        </Panel>
        <Panel
          extra={
            <img src={TickIcon} alt="icon" className="img-fluid" width="34px" />
          }
          header={<InteriorHeader />}
          key={3}
          className="accordian-item"
        >
           <InspectionContent repairedItems={interior} singleDetails={singleDetails} />
        </Panel>
        <Panel
          extra={
            <img src={TickIcon} alt="icon" className="img-fluid" width="34px" />
          }
          header={<ElectronicsHeader />}
          key={4}
          className="accordian-item"
        >
           <InspectionContent repairedItems={Electronics} singleDetails={singleDetails} />
        </Panel>
        <Panel
          extra={
            <img src={TickIcon} alt="icon" className="img-fluid" width="34px" />
          }
          header={<SteeringHeader />}
          key={5}
          className="accordian-item"
        >
          <InspectionContent repairedItems={steeringAndSuspension} singleDetails={singleDetails} />
        </Panel>
      </Collapse>

      <div className=" mt-15">
        <p className="fs-12 text-center">
          Want more detail? <Link>See the full check-list.</Link>
        </p>
      </div>
    </>
  );
};

export default InspectionReportAccordion;
