import { Col, Row, Space, Spin } from "antd";
import React from "react";
import { Link } from "react-router-dom";
import BgImg from "../assets/images/d.png";
import HeroImg from "../assets/images/hybrid-car-landing-img.svg";
import CommonSelect from "../components/commonSelect/CommonSelect";
import useFilter from "../hooks/useFilter";
import LandingFeature from "./LandingFeature";
import StockListItem from "../components/stockListItem/StockListItem";
import { useState } from "react";

const HybridCar = ({ landingHeroImg, title, subTitle, isHybrid  }) => {
  const {
    setMake,
    setModel,
    makeOptions,
    modelOptions,
    make,
    newData,
    loading,
  } = useFilter();

  const modelData = modelOptions?.filter((options) => options?.make === make);

  const [itemsPerPage, setItemsPerPage] = useState(8);

  const uniqueModelOptions = modelData?.filter((item, index) => {
    return (
      index ===
      modelData.findIndex((obj) => {
        return obj.value === item.value;
      })
    );
  });

  const uniqueMakeOptions = makeOptions?.filter((item, index) => {
    return (
      index ===
      makeOptions.findIndex((obj) => {
        return obj.value === item.value;
      })
    );
  });

  
  const hybridCar =  newData?.filter((car) => car.fuel === "Hybrid");
  const camperVan =  newData?.filter((car) => car.bodyType === "VAN");

  const rederedData = isHybrid ? hybridCar : camperVan


  return (
    <>
      <section className="banner" style={{ backgroundImage: `url(${BgImg})` }}>
        <div className="container">
          <div className="bannerContent py-80">
            <Row justify="center" gutter={[24, 24]} className="banner-row">
              <Col xs={{ span: 24 }} lg={{ span: 12 }}>
                <div
                  className="bannerInfo"
                  data-aos="fade-right"
                  data-aos-offset="300"
                  data-aos-easing="ease-in-sine"
                  data-aos-duration="1000"
                >
                  <h1 className="text-dark1 fs-56 mb-30 ">
                    <span className="fw-800">Find</span> your <br />{" "}
                    <span className="text-blue">next match</span>
                  </h1>
                  <div className="bannerIntro ">
                    <p className="mb-30 text-dark3 fs-18 fw-medium">
                      The Ultimate Blend: Campervans, Vans & Hybrids
                    </p>
                  </div>

                  <Space
                    size="large"
                    direction="vertical"
                    className="selectWrapper"
                  >
                    <div>
                      <Space size="large">
                        <CommonSelect
                          setOptions={setMake}
                          selectOptions={uniqueMakeOptions}
                          defaultValue="Select Make"
                          customWidth="min-width-220"
                        />

                        <CommonSelect
                          setOptions={setModel}
                          selectOptions={uniqueModelOptions}
                          defaultValue="Select Model"
                          customWidth="min-width-220"
                        />
                      </Space>
                    </div>

                    <div className="common-fill-btn">
                      <Link
                        to="/view-cars"
                        className="fs-16 bg-clr-blue fw-bold text-white rounded fill-btn w-100"
                      >
                        Browse {newData?.length} Cars
                      </Link>
                    </div>
                  </Space>
                </div>
              </Col>
              <Col xs={{ span: 24 }} lg={{ span: 12 }}>
                <div
                  className="hero-car"
                  data-aos="fade-left"
                  data-aos-offset="300"
                  data-aos-easing="ease-in-sine"
                  data-aos-duration="1000"
                >
                  <img src={landingHeroImg} alt="img" className="img-fluid" />
                </div>
              </Col>
            </Row>
          </div>
        </div>
      </section>
      <LandingFeature />

      <section className=" pt-100 ">
        <div className="container">
          <div className="stocklist">
            <div className="text-center">
              <h1 className="text-dark1 fs-36 fw-bold mb-50">
                {title}
              </h1>
              <div className="common-title-pra">
                <p className="text-dark3 fs-18 fw-medium">
                    {subTitle}
                </p>
              </div>
            </div>

            {!loading && (
              <div className="stocklist-wrapper pb-5">
                <Row
                  justify="center"
                  align="middle"
                  gutter={[24, 36]}
                  className="stocklist-row"
                >
                  {rederedData?.slice(0, itemsPerPage)?.map((car, index) => (
                    <Col
                      xs={{ span: 24 }}
                      md={{ span: 12 }}
                      lg={{ span: 8 }}
                      xl={{ span: 6 }}
                      className="stock-column"
                      key={index}
                    >
                      <StockListItem car={car} />
                    </Col>
                  ))}
                </Row>
                {rederedData?.length > itemsPerPage && (
                  <div className="common-fill-btn text-center mt-50">
                    <Link
                      className="fs-16 bg-clr-blue fw-bold text-white rounded fill-btn"
                      onClick={() => setItemsPerPage(itemsPerPage + 4)}
                    >
                      Load More
                    </Link>
                  </div>
                )}
              </div>
            )}
            {loading && (
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <Spin size="large" />
              </div>
            )}
          </div>
        </div>
      </section>
    </>
  );
};

export default HybridCar;
