import { Col, Divider, Row, Space } from 'antd';
import Search from 'antd/es/input/Search';
import React from 'react';
import { Link, NavLink, useLocation, useNavigate } from 'react-router-dom';
import { CSSTransition } from 'react-transition-group';
import Logo from "../../assets/images/carbarn-logo.svg";
import HumburgerIcon from "../../assets/images/hambarger.svg";
import LoveIcon from "../../assets/images/heart.svg";
import SearchIcon from "../../assets/images/search.svg";
import useFilter from '../../hooks/useFilter';
import useWishList from '../../hooks/useWishList';
import "./header.scss";
import CustomNavLink from '../../utils/CustomNavLink';

const Header = () => {

    const { menuOpen, setMenuOpen, searchOpen, setSearchOpen, setSearchText } = useFilter();


    const { wishlist } = useWishList();

    const handleLinkClick = () => {
        window.scrollTo(0, 0);
    };

    const navigate = useNavigate();

    const onSearch = () => {
        navigate('/view-cars');
    };

    const location = useLocation();

    if (location?.pathname === "/login" || location?.pathname === "/signup") return null;

    return (
        <header className="header-sticky">
            <nav className="navbar bg-white">
                <div className="container">
                    <Row justify="space-between" align="middle" className="nav-row">
                        <Col>
                            <Link to="/" className="navbar-brand">
                                <div style={{ width: "170px" }}>
                                    <img src={Logo} alt="logo" className="img-fluid" />
                                </div>
                            </Link>
                        </Col>
                        <Col className="nav-col">
                            <Space align="center">
                                <ul className="navbar-nav">
                                    <li className="nav-item">
                                        <CustomNavLink to="/">
                                            Home
                                        </CustomNavLink>
                                    </li>
                                    <li className="nav-item">
                                        <CustomNavLink to="/stock">
                                            Stock List
                                        </CustomNavLink>
                                    </li>
                                    <li className="nav-item">
                                        <CustomNavLink to="/warranty">
                                            Warranty
                                        </CustomNavLink>
                                    </li>
                                    <li className="nav-item">
                                        <CustomNavLink to="/finance">
                                            Car Finance
                                        </CustomNavLink>
                                    </li>
                                    <li className="nav-item">
                                        <CustomNavLink to="/how-works">
                                            How it Works
                                        </CustomNavLink>
                                    </li>
                                </ul>

                                <CSSTransition in={searchOpen} timeout={500} unmountOnExit classNames="search-bar-transition">
                                    <div className="search-bar">
                                        <Link onClick={() => setSearchOpen(false)}>
                                            <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M1 13L13 1" stroke="#ddd" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                                                <path d="M13 13L1 1" stroke="#ddd" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                                            </svg>
                                        </Link>
                                        <Search
                                            placeholder="Find the right price, dealer and advice"
                                            allowClear
                                            onSearch={onSearch}
                                            onChange={(e) => setSearchText(e.target.value)}
                                            // onClick={onSearch}
                                            className="search-input"
                                        />
                                    </div>
                                </CSSTransition>

                                <Divider className="m-none" type="vertical" style={{ height: "30px", background: "#C9C9C9" }} />
                                <div className='action-icon'>
                                    <NavLink onClick={() => setSearchOpen(!searchOpen)}>
                                        <img src={SearchIcon} className="img-fluid" alt="search" />
                                    </NavLink>
                                    <NavLink to="/wish-list" className="wish-list-counter">
                                        <img src={LoveIcon} className="img-fluid" alt="heart" />
                                        {wishlist?.length > 0 && <span className="wish-list-count">{wishlist?.length}</span>}
                                    </NavLink>
                                    <NavLink className="hamburger-icon" onClick={() => setMenuOpen(!menuOpen)}>
                                        <img src={HumburgerIcon} className="img-fluid" alt="hambarger" />
                                    </NavLink>
                                </div>
                            </Space>
                        </Col>
                    </Row>
                </div>
            </nav>
        </header>
    );
};

export default Header;