import { Col, Row } from 'antd';
import React from 'react';
import { Link } from 'react-router-dom';
import SecureImg from "../../assets/images/secure-img.png";
import SecureShape from "../../assets/images/secure-shape.png";
import './secure.scss';

const Secure = () => {
    return (
        <section className="secure bg-no-repeat pb-100 pt-100" style={{ backgroundImage: `url(${SecureShape})`, backgroundPosition: "0 center" }}>
            <div className="container">
                <Row gutter={[30, 30]} className="item-center">
                    <Col className="" xl={{ span: 12 }}>
                        <div className="secureInfo text-lg-start text-xs-center">
                            <span className="secTag ls-2 fs-12 text-blue fw-800 text-uppercase">secure financing</span>
                            <h2 className="secureCaption fs-48 text-dark1 lh-58 fw-normal mb-20">
                                Apply for pre-approval at CARBARN to <b>secure financing</b> for your selected vehicle.
                            </h2>
                            <p className="secureIntro fs-16 fw-medium text-dark3 lh-24 mb-50">Complete the application and hear back from our team promptly with a decision on your financing.</p>
                            <div className="common-fill-btn">
                                <Link className="fs-16 bg-clr-blue fw-bold text-white rounded fill-btn">Apply Now</Link>
                            </div>
                        </div>
                    </Col>
                    <Col className="" xl={{ span: 12 }}>
                        <div className="secureImg text-xs-center">
                            <img src={SecureImg} alt="secure Img" className="img-fluid" />
                        </div>
                    </Col>
                </Row>
            </div>
        </section>
    );
};

export default Secure;