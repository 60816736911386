import React from 'react';
import AdditionalFeature from '../../components/additionalFeature/AdditionalFeature';
import Faq from '../../components/faq/Faq';
import MechanicalBreakdown from '../../components/mechanicalBreakdown/MechanicalBreakdown';
import NotCover from '../../components/notCover/NotCover';
import WarrantyBanner from '../../components/warrantyBanner/WarrantyBanner';
import WarrantyComponent from '../../components/warrantyComponent/WarrantyComponent';
import WarrantyFeature from '../../components/warrantyFeature/WarrantyFeature';
import useFetch from '../../hooks/useFetch';

const Warranty = () => {

   const { data } = useFetch("https://script.google.com/macros/s/AKfycbxQOdiy0DPw51py3RhEaGjjZlLMcv8_FPDoqKJMaKoAeKP4KY5v3nc_Z3suN4LY0J-8/exec");

   const warrantyFaq = data.filter((item) => item.page === "Warranty");

  return (
      <div>
          <WarrantyBanner />
          <WarrantyFeature />
          <WarrantyComponent />
          <AdditionalFeature />
          <NotCover />
          <MechanicalBreakdown />
          <Faq faqTitle="Warranty <b>FAQ</b>" faqData={warrantyFaq} />
      </div>
  );
};

export default Warranty;