import React from 'react';

import commonBannerImg from "../../assets/images/how-it-work-img.png";
import commonBannerBg from "../../assets/images/how-it-works-bg.png";

import CommonBanner from '../../components/commonBanner/CommonBanner';
import OffersBanner from '../../components/offersBanner/OffersBanner';
import Secure from '../../components/secure/Secure';
import Significant from '../../components/significant/Significant';
import WorkProcess from '../../components/workProcess/WorkProcess';





const HowItWork = () => {

    return (
        <div>
            <CommonBanner
                sectionBg={commonBannerBg}
                sectionTag={"How it's work"}
                sectionCaption={`CARBARN is <br/> <b>revolutionizing</b> the <br/> way you buy used cars`}
                sectionIntro={"Our online process makes buying a used car hassle-free, especially for those interested in vehicles manufactured by Japanese automakers. "}
                sectionImg={commonBannerImg}
                addRowclassName={"item-end d-xs-block"}
            />
            <WorkProcess />
            <OffersBanner />
            <Secure />
            <Significant />
        </div>
    );
};

export default HowItWork;