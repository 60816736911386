import React from 'react';
import { Link } from 'react-router-dom';
import './vehiclePrice.scss';

const VehiclePrice = () => {
    return (
        <div className='VehiclePrice'>
            <div className=''>
                <div className='PaymentSummary radius-16 p-15'>
                    <h4 className='fs-24 fw-800 text-white mb-30'>
                        Summary
                    </h4>
                    <ul className='summaryList'>
                        <li className='flex content-between item-center mb-20'>
                            <span className='summaryCaption fs-18 fw-medium text-white'>
                                Vechile Price
                            </span>
                            <span className='summaryPrice fs-18 fw-800 text-white'>
                                $25000
                            </span>
                        </li>
                        <li className='flex content-between item-center mb-20'>
                            <span className='summaryCaption fs-18 fw-medium text-white'>
                                Down payment
                            </span>
                            <span className='summaryPrice fs-18 fw-800 text-white'>
                                -$2000
                            </span>
                        </li>
                        <li className='flex content-between item-center mb-20 border-bottom pb-20'>
                            <span className='summaryCaption fs-18 fw-medium text-white'>
                                Est. Tax, Title & Fee
                            </span>
                            <span className='summaryPrice fs-18 fw-800 text-white'>
                                Currently Unavailable
                            </span>
                        </li>
                        <li className='flex content-between item-center mb-30'>
                            <span className='summaryCaption fs-24 fw-medium text-white'>
                                Total:
                            </span>
                            <span className='totalPrice fs-24 fw-800 text-white'>
                                $23,000
                            </span>
                        </li>
                    </ul>
                    <div className='EstimatedPayment text-center'>
                        <h4 className='fs-24 fw-800 text-white mb-20'>
                            Estimated monthly payment
                        </h4>
                        <h3 className='totalEstimated fs-48 fw-800 text-white mb-20'>
                            $426
                        </h3>
                        <div className="common-fill-btn mb-15">
                            <Link className="fs-16 bg-white fw-bold text-dark2 rounded d-em-block fill-btn" to="/">
                                Shop with budget
                            </Link>
                        </div>
                        <div className="common-fill-btn mb-20">
                            <Link className="fs-16 fw-bold text-white rounded d-em-block fill-btn" to="/">
                                Get pre qualified
                            </Link>
                        </div>
                        <div className='payment-text'>
                            <p className='fs-16 fw-medium text-white lh-24'>
                                ** Estimated monthly payment may be inaccurate without title, taxes and fees. Please try again later for the most accurate estimations
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default VehiclePrice;