import { Form, Input, Space } from 'antd';
import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import Logo from "../../assets/images/carbarn-logo.svg";
import FeatureIcon1 from "../../assets/images/home-feature-1.svg";
import FeatureIcon2 from "../../assets/images/home-feature-2.svg";
import FeatureIcon3 from "../../assets/images/home-feature-3.svg";
import FeatureIcon4 from "../../assets/images/home-feature-4.svg";
import { ReactComponent as EmailIcon } from "../../assets/images/email-icon-carbarn.svg";
import { ReactComponent as LockIcon } from "../../assets/images/lock.svg"; 
import LoginStep1 from '../../components/loginStep/LoginStep1';
import LoginStep2 from '../../components/loginStep/LoginStep2';
import './login.scss';
import usePurchase from '../../hooks/usePurchase';
import axios from 'axios';
import useAuth from '../../hooks/useAuth';
import { toast } from 'react-toastify';

const Login = () => {

    const { formData, setFormData, handleChange } = usePurchase();
    
    const { loading, error, dispatch } = useAuth();


    const [stepNumber, setStepNumber] = useState(1);

    const backBtn = () => {
        let step = stepNumber;
        setStepNumber(step - 1);
    };

    const nextBtn = () => {
        let step = stepNumber;
        setStepNumber(step + 1);
    };


    const handleLogin = async (e) => {

        e.preventDefault();

         const credentials = {
           username: formData?.username,
           password: formData?.password,
         };

         dispatch({ type: "LOGIN_START" });

         try {
           const res = await axios.post(
             `https://www.cbs.s1.importautos.com.au/carbarn/auth/v1/api/user/signin`,
             credentials
           );

             dispatch({ type: "LOGIN_SUCCESS", payload: res?.data });
             toast.success("Login Successfull");
             setFormData({})
             
         } catch (err) {
             dispatch({ type: "LOGIN_FAILURE", payload: err?.response?.data });
             toast.error("Username or password is wrong !");
         }

    }



    return (
      <section className="appsAccessPanel">
        <div className="accessPanelWrapper">
          <div className="accessPanelLeftInfo">
            <div className="accessInfoAll">
              <div className="accessInfoWrap">
                <div className="logoWrap" style={{ width: "175px" }}>
                  <Link to="/">
                    <img src={Logo} alt="logo" className="img-fluid" />
                  </Link>
                </div>
                {/* <div className="img mb-30">
                                <img src={LoginImg} alt="logo" className="img-fluid" />
                            </div> */}
                <div>
                  <h3 className="fs-48 text-dark1 fw-normal mb-50">
                    Carbarn <b>benefit</b>
                  </h3>
                  <ul className="feature-point-wrapper">
                    <li className="feature-point mb-20">
                      <img
                        src={FeatureIcon1}
                        className="img-fluid mt-1"
                        alt="icon"
                      />
                      <p className="fs-24 fw-normal text-dark1 lh-24">
                        5-Years <b>Warranty</b> with road side assistance
                      </p>
                    </li>
                    <li className="feature-point mb-20">
                      <img
                        src={FeatureIcon2}
                        className="img-fluid mt-1"
                        alt="icon"
                      />
                      <p className="fs-24 fw-normal text-dark1 lh-24">
                        Faster doorstep <b>delivery</b> all over Australia
                      </p>
                    </li>
                    <li className="feature-point mb-20">
                      <img
                        src={FeatureIcon3}
                        className="img-fluid mt-1"
                        alt="icon"
                      />
                      <p className="fs-24 fw-normal text-dark1 lh-24">
                        <b>Quality</b> Vehicle with best price
                      </p>
                    </li>
                    <li className="feature-point mb-20">
                      <img
                        src={FeatureIcon4}
                        className="img-fluid mt-1"
                        alt="icon"
                      />
                      <p className="fs-24 fw-normal text-dark1 lh-24">
                        Finance <b>Facility</b>
                      </p>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
          <div className="accessPanelRightInfo">
            {/* <form className="accessRightAllInfo">
                        {stepNumber > 1 && (
                            <Link type="button" onClick={backBtn} className="text-decoration-none">
                                <Space>
                                    <div className="mt-4">
                                        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path
                                                d="M9.57 5.92969L3.5 11.9997L9.57 18.0697"
                                                stroke="#525355"
                                                strokeWidth="1.5"
                                                stroke-miterlimit="10"
                                                strokeLinecap="round"
                                                strokeLinejoin="round"
                                            />
                                            <path d="M20.4999 12H3.66992" stroke="#525355" strokeWidth="1.5" stroke-miterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
                                        </svg>
                                    </div>
                                    <p className="fs-16 fw-800 text-dark3">Back</p>
                                </Space>
                            </Link>
                        )}
                        {stepNumber === 1 && <LoginStep1 nextBtn={nextBtn} />}
                        {stepNumber === 2 && <LoginStep2 />}
                    </form> */}

            <div className="accessRightAllInfo">
              <div className="accessTopInfo">
                <div className="accessHeader mb-50">
                  <h2 className="fs-48 text-dark1 lh-58 mb-20">
                    Welcome to <b>carbarn</b>
                  </h2>
                  <p className="fs-16 text-dark3 lh-24">
                    By logging in, you agree to Carbarn's Privacy Policy, Terms
                    & Conditions
                  </p>
                </div>
                <form onSubmit={handleLogin}>
                  <div className="subscribe">
                    <Form.Item
                      rules={[
                        {
                          required: true,
                          message: "Please Enter your username!",
                        },
                      ]}
                    >
                      <Input
                        prefix={<EmailIcon />}
                        type={"text"}
                        placeholder="Enter Your Username"
                        name="username"
                        value={formData.username}
                        onChange={handleChange}
                        required
                      />
                    </Form.Item>

                    <Form.Item
                      rules={[
                        {
                          required: true,
                          message: "Please input your password!",
                        },
                      ]}
                    >
                      <Input.Password
                        prefix={<LockIcon />}
                        placeholder="Enter Your Password"
                        name="password"
                        value={formData?.password}
                        onChange={handleChange}
                        required
                      />
                    </Form.Item>

                    <div className="mt-30">
                      <button
                        type="submit"
                        className="fs-16 bg-clr-blue border-0 w-100 item-center gap-10 fw-800 text-white rounded fill-btn px-30 py-15"
                      >
                        Login
                      </button>
                    </div>
                  </div>
                </form>
              </div>
              <div className="accessBotto text-center">
                <p className="fs-18 fw-normal text-dark1">
                  Don’t have an account?{" "}
                  <Link to="/signup" className="text-decorate-none">
                    {" "}
                    Create account
                  </Link>
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
    );
};

export default Login;