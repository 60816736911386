import React from "react";
import RightTickIcon from "../../assets/images/right-tick-icon.svg";
import ErrorIcon from "../../assets/images/warning-icon-black.svg";
import "./inspectionContent.scss";
import { Image } from "antd";

const InspectionContent = ({ repairedItems, singleDetails }) => {

  const imperfectItems = singleDetails?.inspectionReport?.filter(
    (imperfectItem) => imperfectItem?.repairStatus === "IMPERFECTION"
  );



  return (
    <ul className="inspection-content">
      {/* <li className="inspection-content-item">
        <div className="item-details">
          <h6>Rear Windshield</h6>
          <img src={ErrorIcon} alt="icon" className="img-fluid" width={42} />
        </div>
        <div className="defect-image">
          <Image
            className="img-wrapper"
            src="https://www.cbs.s1.importautos.com.au/files/jimex/zIOwmsOTw1VDJpXO7EaxF-T_mIHpEOe6ID4HrPtBUSA"
          />
          <p>3 cm scratch on the top right corner</p>
        </div>
      </li> */}
      {repairedItems?.map((item, index) => {

        const imperfectInspectionItem = imperfectItems?.filter(
          (imperfectItem) => imperfectItem?.part === item?.inspection_item
        );

        return (
          <li className="inspection-content-item" key={index}>
            <div className="item-details">
              <h6>{item?.inspection_item}</h6>
              <img
                src={
                  imperfectInspectionItem?.length > 0
                    ? ErrorIcon
                    : RightTickIcon
                }
                alt="icon"
                className="img-fluid"
                width={42}
              />
            </div>
            {imperfectInspectionItem?.length > 0 &&
              imperfectInspectionItem?.map((imperfectItem) => (
                <div className="defect-image">
                  <Image
                    className="img-wrapper"
                    src={imperfectItem?.repairPhoto?.[0]}
                  />
                  <p>{imperfectItem?.repairNote}</p>
                </div>
              ))}
          </li>
        );
      })}
    </ul>
  );
};

export default InspectionContent;
