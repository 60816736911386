import { Tabs } from 'antd';
import React from 'react';
import financeCalculatorShape from "../../assets/images/financeCalculatorShapw.png";
import MonthlyPayment from '../monthlyPayment/MonthlyPayment';
import VehiclePrice from '../vehiclePrice/VehiclePrice';
import './financeCalculator.scss';

const FinanceCalculator = () => {
    return (
        <section className="FinanceCalculator pt-100 pb-100  bg-no-repeat" style={{ backgroundImage: `url(${financeCalculatorShape})`, backgroundSize: "auto", backgroundPosition: "0 100px" }}>
            <div className="container">
                <h2 className="fs-48 fw-normal text-dark1 text-center mb-30 w-100">
                    Finance <b>calculator</b>
                </h2>

                <Tabs centered className="full-width-tab">
                    <Tabs.TabPane tab="Monthly payment" key="Monthly payment" className="mt-50">
                        <MonthlyPayment />
                    </Tabs.TabPane>
                    <Tabs.TabPane tab="Vehicle price" key="Vehicle price" className="mt-50">
                        <VehiclePrice />
                    </Tabs.TabPane>
                </Tabs>
            </div>
        </section>
    );
};

export default FinanceCalculator;