import { DownOutlined } from "@ant-design/icons";
import { Col, Collapse, Row } from "antd";
import React, { useState } from "react";
import { Link } from "react-router-dom";
import "./faq.scss";

const { Panel } = Collapse;

const Faq = ({ faqTitle, faqData }) => {
  const [itemsPerPage, setItemsPerPage] = useState(5);

  return (
    <section className="faq  pt-100 pb-100">
      <div className="container">
        <div className="waranty-left hybrid-vehical mb-50">
          <div className="vehical-content">
            <p className="vehical-hints fs-12 fw-800 text-center">FAQ</p>

            <h1
              className="text-dark1 fs-48 mb-15 lh-60 text-center"
              dangerouslySetInnerHTML={{ __html: faqTitle }}
            ></h1>
          </div>
        </div>
        <div className="accordionWrapper">
          <Row gutter={[24, 24]} justify="space-between">
            <Col xs={{ span: 24 }}>
              {faqData?.slice(0, itemsPerPage)?.map((item, index) => {
                return (
                  <Collapse
                    accordion
                    bordered={false}
                    defaultActiveKey={faqData[0]?.id}
                    expandIcon={({ isActive }) => (
                      <DownOutlined rotate={isActive ? 180 : 0} />
                    )}
                    className="faq-accordian"
                    key={index}
                  >
                    <Panel
                      header={item?.question}
                      key={item.id}
                      className="accordian-item"
                    >
                      <div
                        className="fs-16 lh-24 text-dark3"
                        dangerouslySetInnerHTML={{
                          __html: item.answer,
                        }}
                      ></div>
                    </Panel>
                  </Collapse>
                );
              })}

              {faqData?.length > itemsPerPage && (
                <div className="common-fill-btn text-center mt-50">
                  <Link
                    className="fs-16 bg-clr-blue fw-bold text-white rounded fill-btn"
                    onClick={() => setItemsPerPage(itemsPerPage + 4)}
                  >
                    Load More
                  </Link>
                </div>
              )}
            </Col>
          </Row>
        </div>
      </div>
    </section>
  );
};

export default Faq;
