import { Col, Input, Row, Select, Spin } from "antd";
import React, { useState } from "react";
import SearchIcon from "../../assets/images/search-icon.svg";
import SortIcon from "../../assets/images/sort-icon.svg";
import Filter from "../../components/filter/Filter";
import FilterSidebar from "../../components/filterSidebar/FilterSidebar";
import StockListItem from "../../components/stockListItem/StockListItem";
import useFilter from "../../hooks/useFilter";

import "./viewCars.scss";

const ViewCars = () => {
  const {
    setMake,
    setModel,
    setToYear,
    setColour,
    setFuelType,
    setBodyType,
    setDoor,
    setSeats,
    setPrice,
    setMaxPrice,
    setMinPrice,
    setMinKm,
    setMaxKm,
    setSearchText,
    newData,
    loading,
  } = useFilter();

  const [filterOpen, setFilterOpen] = useState(false);

  const handleChange = (value) => {
    console.log(`selected ${value}`);
  };

  const handleReset = () => {
    setSearchText();
    setMake();
    setModel();
    setToYear();
    setColour();
    setFuelType();
    setBodyType();
    setDoor();
    setSeats();
    setPrice();
    setMaxPrice();
    setMinPrice();
    setMinKm();
    setMaxKm();
  };

  return (
    <section className="views-cars">
      <FilterSidebar filterOpen={filterOpen} setFilterOpen={setFilterOpen} />
      <div className="container">
        <div className="mt-50 mb-30">
          <h4 className="fs-30 fw-800 text-dark2">Stock List</h4>
        </div>
        <div className="search-wrapper mb-50">
          <Input
            className="search-box"
            size="large"
            placeholder="Find your dream car with us"
            prefix={<img src={SearchIcon} alt="icon" />}
            onChange={(e) => setSearchText(e.target.value)}
          />

          <button className="clr-btn" onClick={handleReset}>
            Clear all filters
          </button>
        </div>
        <div className="views-cars-wrapper">
          <div className="filter-sidebar">
            <button className="sort-btn mb-30">
              <span>
                <img src={SortIcon} alt="icon" />
              </span>
              <span>Filter</span>
            </button>
            <div>
              <Filter />
            </div>
          </div>
          <div className="all-car">
            <div className="sort-header mb-30">
              <div className="m-filter-btn">
                <button onClick={() => setFilterOpen(!filterOpen)}>
                  <span>
                    <img src={SortIcon} alt="icon" />
                  </span>
                  <span>Filter</span>
                </button>
              </div>

              <div className="sort-box">
                <p className="fw-800">Sort By :</p>
                <Select
                  defaultValue="Newest"
                  style={{
                    width: 120,
                  }}
                  onChange={handleChange}
                  options={[
                    {
                      value: "newest",
                      label: "Newest",
                    },
                    {
                      value: "oldest",
                      label: "Oldest",
                    },
                  ]}
                />
              </div>
            </div>

            <div>
              <Row justify="start" align="middle" gutter={[24, 24]}>
                {newData?.map((car, index) => (
                  <Col
                    xs={{ span: 24 }}
                    md={{ span: 12 }}
                    lg={{ span: 8 }}
                    xl={{ span: 8 }}
                    className="stock-column"
                    key={index}
                  >
                    <StockListItem car={car} />
                  </Col>
                ))}

                {/* {filteredData?.length === 0 && data?.map((car, index) => <StockListItem key={index} car={car} />)} */}

                {loading && (
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      textAlign: "center",
                      height: "50vh",
                      margin: "auto",
                    }}
                  >
                    <Spin size="large" />
                  </div>
                )}
              </Row>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default ViewCars;
