import { Col, Row } from "antd";
import React from "react";
import NotCoverImg from "../../assets/images/not-cover.png";
import { notCover } from "../../data";
import "./notCover.scss";

const NotCover = () => {
  return (
    <section className="not-cover py-80">
      <div className="container">
        <Row align="middle" gutter={[50, 30]}>
          <Col xs={{ span: 24 }} lg={{ span: 12 }}>
            <div className="additional-feature-image">
              <img src={NotCoverImg} className="img-fluid" alt="additional" />
            </div>
          </Col>
          <Col xs={{ span: 24 }} lg={{ span: 12 }}>
            <div className="additional-feature-content">
              <div className="waranty-left hybrid-vehical">
                <div className="vehical-content ">
                  <h1 className="text-dark1 fs-48 mb-15 lh-60 ">
                    What’s not <span className="fw-800"> covered </span>
                  </h1>
                  <p className="text-dark3 fs-18 fw-medium mb-30">
                    The following are not covered by our mechanical breakdown
                    warranty:
                  </p>

                  <ul className="feature-point-wrapper">
                    {notCover.map((item, index) => {
                      return (
                        <li className="feature-point" key={index}>
                          <span className="solid-bullet mt-8"></span>
                          <p className="fs-16 fw-medium text-dark3">
                            {item.title}
                          </p>
                        </li>
                      );
                    })}
                  </ul>
                </div>
              </div>
            </div>
          </Col>
        </Row>
      </div>
    </section>
  );
};

export default NotCover;
