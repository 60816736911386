import { Col, Row, Space } from "antd";
import React from "react";
import { Link } from "react-router-dom";
import HeroImg from '../../assets/images/Carbarn-hero.png';
import BgImg from "../../assets/images/d.png";
import useFilter from "../../hooks/useFilter";
import CommonSelect from "../commonSelect/CommonSelect";
import "./homeBanner.scss";

const HomeBanner = ({ setMake, setModel, filteredData, makeOptions, modelOptions }) => {

    const {make, newData} = useFilter();
 
   
    const modelData = (modelOptions?.filter(options => options.make === make));

    const uniqueModelOptions = modelData?.filter((item, index) => {
        return (
            index ===
            modelData.findIndex((obj) => {
                return obj.value === item.value;
            })
        );
    });


    const uniqueMakeOptions = makeOptions?.filter((item, index) => {
        return (
            index ===
            makeOptions.findIndex((obj) => {
                return obj.value === item.value;
            })
        );
    });



 

    return (
        <section className="banner" style={{ backgroundImage: `url(${BgImg})` }}>
            <div className="container">
                <div className="bannerContent py-80">
                    <Row justify="center" gutter={[24, 24]} className="banner-row">
                        <Col xs={{ span: 24 }} lg={{ span: 12 }}>
                            <div className="bannerInfo" data-aos="fade-right" data-aos-offset="300" data-aos-easing="ease-in-sine" data-aos-duration="1000">
                                <h1 className="text-dark1 fs-56 mb-30 ">
                                    <span className="fw-800">Find</span> your next match.
                                </h1>
                                <div className="bannerIntro ">
                                    <p className="mb-30 text-dark3 fs-18 fw-medium">The Ultimate Blend: Campervans, Vans & Hybrids</p>
                                </div>

                                <Space size="large" className="selectWrapper">
                                    <CommonSelect setOptions={setMake} uniqueOptions={uniqueMakeOptions} defaultValue="Select Make" />

                                    <CommonSelect setOptions={setModel} uniqueOptions={uniqueModelOptions} defaultValue="Select Model" />

                                    <div className="common-fill-btn">
                                        <Link to="/view-cars" className="fs-16 bg-clr-blue fw-bold text-white rounded fill-btn">
                                            Browse {filteredData?.length > 0 ? filteredData?.length : newData?.length} Cars
                                        </Link>
                                    </div>
                                </Space>
                            </div>
                        </Col>
                        <Col xs={{ span: 24 }} lg={{ span: 12 }}>
                            <div className="hero-car" data-aos="fade-left" data-aos-offset="300" data-aos-easing="ease-in-sine" data-aos-duration="1000">
                                <img src={HeroImg} alt="img" className="img-fluid" />
                            </div>
                        </Col>
                    </Row>
                </div>
            </div>
        </section>
    );
};

export default HomeBanner;
