import { Calendar, Col, Row } from 'antd';
import React from 'react';
import { Link } from 'react-router-dom';
import usePurchase from '../../hooks/usePurchase';


const ModalCalendar = () => {

  const { setFormData } = usePurchase();

    
    const handleChange = (value) => {
      setFormData((prevData) => ({
        ...prevData,
        date: value.format("YYYY-MM-DD"),
      }));
    };


  const wrapperStyle = {
      borderRadius: '16px',
      overflow: 'hidden',
      border: '1px solid #dddd'
  };

  return (
    <div className="mb-30">
      <div style={wrapperStyle}>
        <Calendar
        disabledDate= { (date) => date < new Date()}
          onChange={handleChange}
          className="modal-calender"
          fullscreen={false}
          headerRender={({ value, type, onChange, onTypeChange }) => {
            const localeData = value.localeData();
            const year = value.year();
            //   const month = value.month();
            return (
              <div className="calendar-header">
                <Row justify="space-between" gutter={8}>
                  <Col>
                    <Link
                      className="text-decoration-none"
                      onClick={() => {
                        const now = value.clone().subtract(1, "month");
                        onChange(now);
                      }}
                    >
                      <svg
                        width="13"
                        height="8"
                        viewBox="0 0 13 8"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M3.61667 7.19993L4.41667 6.43327L2.51667 4.53327H13V3.43327H2.51667L4.41667 1.53327L3.63333 0.766602L0.4 3.98327L3.61667 7.19993Z"
                          fill="white"
                        />
                      </svg>
                    </Link>
                  </Col>
                  <Col>
                    <h6 className="fs-16 fw-800 text-white">{`${localeData.monthsShort(
                      value
                    )} ${year}`}</h6>
                  </Col>
                  <Col>
                    <Link
                      onClick={() => {
                        const now = value.clone().add(1, "month");
                        onChange(now);
                      }}
                    >
                      <svg
                        width="13"
                        height="8"
                        viewBox="0 0 13 8"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M9.38333 7.19993L8.58333 6.43327L10.4833 4.53327H0V3.43327H10.4833L8.58333 1.53327L9.36667 0.766602L12.6 3.98327L9.38333 7.19993Z"
                          fill="white"
                        />
                      </svg>
                    </Link>
                  </Col>
                </Row>
              </div>
            );
          }}
        />
      </div>
    </div>
  );
};

export default ModalCalendar;