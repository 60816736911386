import React from "react";
import CloseIcon from "../../assets/images/close-icon.svg";
import EditIcon from "../../assets/images/edit-icon.svg";
import usePurchase from "../../hooks/usePurchase";
import UserInfoForm from "../purchaseJourneyItems/UserInfoForm";
import useAuth from "../../hooks/useAuth";

const PurchaseStep1 = ({ stepNo }) => {

  const { formData, setFormData, editUser, setEditUser } = usePurchase();

  const {customer} = useAuth();

  return (
    <>
      <div className="step-wrapper">
        <div className="step-header">
          <h6>Contact details</h6>
          {customer?.email && (
            <div className="step-header-btn-wrapper">
              {!editUser && (
                <button
                  className="step-header-btn"
                  onClick={() => setEditUser(true)}
                >
                  <img src={EditIcon} alt="icon" className="img-fluid" />
                </button>
              )}
              {editUser && (
                <button
                  className="step-header-btn"
                  onClick={() => setEditUser(false)}
                >
                  <img src={CloseIcon} alt="icon" className="img-fluid" />
                </button>
              )}
            </div>
          )}
        </div>
        <UserInfoForm stepNo={stepNo} />
      </div>

      <div className="mt-15">
        {customer?.email && (
          <h6 className="info-text mb-15">
            Please review your information and edit as you wish.
          </h6>
        )}

        <h6 className="info-text">
          Need help? Give us a call at{" "}
          <a href="tel:0423840130" style={{ color: "#061D33" }}>
            0423840130
          </a>
        </h6>
      </div>
    </>
  );
};

export default PurchaseStep1;
