import { Col, Row } from "antd";
import React from "react";
import ThumbsUpIcon from "../../assets/images/Thumbs-up-icon.svg";
import ImperfectionGuideIcon from "../../assets/images/imperfection-guide-icon.svg";
import WarrantyIcon from "../../assets/images/warranty-icon.svg";
import './productDetailsFeature.scss';

const ProductDetailsFeature = () => {
  return (
    <Row className="product-details-feature" gutter={[30,30]}>
      <Col md={8}>
        <div className="feature-card">
          <div className="icon-box">
            <img src={ThumbsUpIcon} alt="icon" />
          </div>
          <div className="text-box">
            <h5>Approved by Experts</h5>
            <p>
              All of our vehicles are inspected in 5 categories and repaired if
              necessary by our team of specialists.
            </p>
          </div>
        </div>
      </Col>
      <Col md={8}>
        <div className="feature-card">
          <div className="icon-box">
            <img src={ImperfectionGuideIcon} alt="icon" />
          </div>
          <div className="text-box">
            <h5>Imperfections Guide</h5>
            <p>
            Comprehensive guide to the process we use to evaluate the state of your car.
            </p>
          </div>
        </div>
      </Col>
      <Col md={8}>
        <div className="feature-card">
          <div className="icon-box">
            <img src={WarrantyIcon} alt="icon" />
          </div>
          <div className="text-box">
            <h5>5-Years Warranty</h5>
            <p>
            Experience peace of mind with a 3 year warranty, with claims up to your vehicle's cost for total coverage
            </p>
          </div>
        </div>
      </Col>
    </Row>
  );
};

export default ProductDetailsFeature;
